import React, { ReactNode, useEffect, useMemo } from 'react';
import styles from './AuthContainer.module.scss';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { User } from '../../../../domain/User';

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  title: string;
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
  currentUser: User | null;
};

export const AuthContainer = ({
  children,
  isAuthenticated,
  onChangeSection,
  section,
  currentUser,
}: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.homepage);
    }
  }, [isAuthenticated]);

  const isSectionRegister = useMemo(() => {
    return section === SignUpSectionType.REGISTER;
  }, [section]);

  return (
    <div className={styles.authContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.leftContainerInner}>
          <div className={styles.container}>
            <div className={styles.header}>
              <h4>{translate(intl, 'LOGIN_FORM.HEADER_TITLE')}</h4>
              <h3>
                {isSectionRegister
                  ? translate(intl, 'LOGIN_FORM.SUBTITLE_REGISTER')
                  : translate(intl, 'LOGIN_FORM.SUBTITLE_LOGIN')}
              </h3>
            </div>
            <div className={styles.links}>
              {isSectionRegister ? (
                <p className={styles.linksTitle}>
                  {translate(intl, 'LOGIN_FORM.ALREADY_REGISTERED')}
                </p>
              ) : (
                <p className={styles.linksTitle}>
                  {translate(intl, 'LOGIN_FORM.NO_ACCOUNT')}
                </p>
              )}
              {isSectionRegister ? (
                <p
                  className={styles.linksSignUp}
                  role="button"
                  onClick={() => onChangeSection(SignUpSectionType.LOGIN)}
                >
                  {translate(intl, 'LOGIN_FORM.BUTTON_LOGIN')}
                </p>
              ) : (
                <p
                  className={styles.linksSignUp}
                  role="button"
                  onClick={() => onChangeSection(SignUpSectionType.REGISTER)}
                >
                  {translate(intl, 'LOGIN_FORM.BUTTON_REGISTER')}
                </p>
              )}
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(AuthContainer);
