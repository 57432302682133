import React, { useState } from 'react';
import styles from './MenuSubItems.module.scss';
import cx from 'classnames';
import { NavigationItem } from '../../Layout/Layout';
import MenuSubItemButton from './MenuSubItemButton/MenuSubItemButton';

type Props = {
  sidebarItems: NavigationItem[];
  label: string;
};

const MenuSubItems = ({ sidebarItems, label }: Props) => {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <div className={styles.menuItems}>
      <h2
        className={styles.menuItemsTitle}
        onClick={() => setIsClosed(!isClosed)}
      >
        {label}
      </h2>
      <div
        className={cx(styles.menuSubItemsContainer, {
          [styles.isClosed]: isClosed,
        })}
      >
        <div className={styles.menuSubItems}>
          {sidebarItems.map((navItem) => (
            <MenuSubItemButton
              navItem={navItem}
              key={navItem.to + navItem.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuSubItems;
