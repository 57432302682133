import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export const fetchUserCurrencyISOCodeFromLocationStart = () => ({
  type: actionTypes.FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_START,
});

export const fetchUserCurrencyISOCodeFromLocationSuccess = (
  userCurrencyISOCodeFromLocation: string,
) => ({
  type: actionTypes.FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_SUCCESS,
  userCurrencyISOCodeFromLocation,
});

export const fetchStripeSupportedCurrenciesStart = () => ({
  type: actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_START,
});

export const fetchStripeSupportedCurrenciesSuccess = (
  stripeSupportedCurrencies: string[],
) => ({
  type: actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_SUCCESS,
  stripeSupportedCurrencies,
});

export const fetchStripeSupportedCurrenciesFail = (
  contentListError: HttpError,
) => ({
  type: actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_FAIL,
  contentListError,
});

export const fetchCurrencyRateStart = () => ({
  type: actionTypes.FETCH_CURRENCY_RATE_START,
});

export const fetchCurrencyRateSuccess = (currencyRate: string[]) => ({
  type: actionTypes.FETCH_CURRENCY_RATE_SUCCESS,
  currencyRate,
});

export const fetchCurrencyRateFail = (currencyRateError: HttpError) => ({
  type: actionTypes.FETCH_CURRENCY_RATE_FAIL,
  currencyRateError,
});
