import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import promotionReducer, {
  PromotionStateType,
} from '../../store/promotion/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import categoryReducer, {
  CategoryStateType,
} from '../../store/category/reducer';
import productReducer, { ProductStateType } from '../../store/product/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import productReviewReducer, {
  ProductReviewStateType,
} from '../../store/product-review/reducer';
import themeReducer, { ThemeStateType } from '../../store/theme/reducer';
import faqReducer, { FaqStateType } from '../../store/faq/reducer';
import faqCategoryReducer, {
  FaqCategoryStateType,
} from '../../store/faq-category/reducer';
import withdrawalReducer, {
  WithdrawalStateType,
} from '../../store/withdrawal/reducer';
import packageReducer, { PackageStateType } from '../../store/package/reducer';
import contentReducer, { ContentStateType } from '../../store/content/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import staticPageReducer, {
  StaticPageStateType,
} from '../../store/static-page/reducer';
import profileReducer, { ProfileStateType } from '../../store/profile/reducer';
import searchReducer, { SearchStateType } from '../../store/search/reducer';
import orderReducer, { OrderStateType } from '../../store/order/reducer';
import userRequestReducer, {
  UserRequestStateType,
} from '../../store/user-request/reducer';
import productPromotionReducer, {
  ProductPromotionStateType,
} from '../../store/product-promotion/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balance-history/reducer';
import invoiceReducer, { InvoiceStateType } from '../../store/invoice/reducer';
import topBarHighlightReducer, {
  TopBarHighlightStateType,
} from '../../store/top-bar-highlight/reducer';
import bigBuyItemReducer, {
  BigBuyItemStateType,
} from '../../store/big-buy-item/reducer';
import userLevelReducer, {
  UserLevelStateType,
} from '../../store/user-level/reducer';
import rewardReducer, { RewardStateType } from '../../store/reward/reducer';
import userRewardReducer, {
  UserRewardStateType,
} from '../../store/user-reward/reducer';
import currencyReducer, {
  CurrencyStateType,
} from '../../store/currency/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  promotion: PromotionStateType;
  language: LanguageStateType;
  category: CategoryStateType;
  product: ProductStateType;
  bigBuyItem: BigBuyItemStateType;
  productReview: ProductReviewStateType;
  payment: PaymentStateType;
  theme: ThemeStateType;
  faq: FaqStateType;
  faqCategory: FaqCategoryStateType;
  withdrawal: WithdrawalStateType;
  package: PackageStateType;
  content: ContentStateType;
  staticPage: StaticPageStateType;
  profile: ProfileStateType;
  search: SearchStateType;
  order: OrderStateType;
  productPromotion: ProductPromotionStateType;
  balanceHistory: BalanceHistoryStateType;
  invoice: InvoiceStateType;
  userRequest: UserRequestStateType;
  topBarHighlight: TopBarHighlightStateType;
  userLevel: UserLevelStateType;
  reward: RewardStateType;
  userReward: UserRewardStateType;
  currency: CurrencyStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  promotion: promotionReducer,
  language: languageReducer,
  category: categoryReducer,
  product: productReducer,
  bigBuyItem: bigBuyItemReducer,
  productReview: productReviewReducer,
  payment: paymentReducer,
  theme: themeReducer,
  faq: faqReducer,
  faqCategory: faqCategoryReducer,
  withdrawal: withdrawalReducer,
  package: packageReducer,
  content: contentReducer,
  staticPage: staticPageReducer,
  profile: profileReducer,
  search: searchReducer,
  order: orderReducer,
  productPromotion: productPromotionReducer,
  balanceHistory: balanceHistoryReducer,
  invoice: invoiceReducer,
  userRequest: userRequestReducer,
  topBarHighlight: topBarHighlightReducer,
  userLevel: userLevelReducer,
  reward: rewardReducer,
  userReward: userRewardReducer,
  currency: currencyReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
