export const FETCH_BIG_BUY_ITEM_LIST_START = 'FETCH_BIG_BUY_ITEM_LIST_START';
export const FETCH_BIG_BUY_ITEM_LIST_SUCCESS =
  'FETCH_BIG_BUY_ITEM_LIST_SUCCESS';
export const FETCH_BIG_BUY_ITEM_LIST_FAIL = 'FETCH_BIG_BUY_ITEM_LIST_FAIL';

export const ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_START =
  'ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_START';
export const ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_SUCCESS =
  'ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_SUCCESS';
export const ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_FAIL =
  'ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_FAIL';

export const SET_ADMIN_BIG_BUY_ITEM_LIST_PARAMS =
  'SET_ADMIN_BIG_BUY_ITEM_LIST_PARAMS';

export const RESET_BIG_BUY_ITEM_STORE = 'RESET_BIG_BUY_ITEM_STORE';

export type BigBuyItemActionTypes =
  | typeof FETCH_BIG_BUY_ITEM_LIST_START
  | typeof FETCH_BIG_BUY_ITEM_LIST_SUCCESS
  | typeof FETCH_BIG_BUY_ITEM_LIST_FAIL
  | typeof ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_START
  | typeof ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_SUCCESS
  | typeof ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_FAIL
  | typeof SET_ADMIN_BIG_BUY_ITEM_LIST_PARAMS
  | typeof RESET_BIG_BUY_ITEM_STORE;
