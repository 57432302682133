import { ChangeEvent, useEffect, useState, useRef, useMemo } from 'react';
import styles from './PublicSearchBar.module.scss';
import { List, ListItem, ListItemText, TextField } from '@mui/material';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as searchService from '../../../store/search/service';
import { connect } from 'react-redux';
import searchIcon from '../../../assets/icons/search-normal.svg';
import _ from 'lodash';
import { SearchPublicResultsRequest } from '../../../store/search/service';
import { PublicSearchResults } from '../../../domain/Search';
import {
  Product,
  ProductType,
  SearchResultType,
} from '../../../domain/Product';
import { User } from '../../../domain/User';
import { Category } from '../../../domain/Category';
import cx from 'classnames';
import { routes } from '../../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../domain/Theme';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { generateParamsUrl } from '../../../utility/url/urlHelper';
import { ProductFilterParams } from '../../../store/product/service';

type Props = {
  onFetchPublicSearchResults: (params: SearchPublicResultsRequest) => void;
  publicSearchResults: PublicSearchResults | null;
  isMobile?: boolean;
  className?: string;
  placeholder?: string;
  onCloseSearchBar?: () => void;
  publicProductListParams: ProductFilterParams;
};

const PublicSearchBar = ({
  onFetchPublicSearchResults,
  publicSearchResults,
  isMobile,
  className,
  placeholder,
  onCloseSearchBar,
  publicProductListParams,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const intl = useIntl();

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;

    setIsSearchOpen(true);
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'searchQuery',
      event_category: 'Search',
      event_action: 'Query',
      event_label: searchTerm,
    });

    onFetchPublicSearchResults({ search: searchTerm });
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const shuffuledSearchResults = useMemo(() => {
    if (!publicSearchResults) {
      return [];
    }

    const results = [
      ...publicSearchResults.categories,
      ...publicSearchResults.products,
      ...publicSearchResults.users,
      ...publicSearchResults.themes,
      ...publicSearchResults.sets.map((item) => ({
        ...item,
        searchType: SearchResultType.SETS,
      })),
      ...publicSearchResults.bigBuySets.map((item) => ({
        ...item,
        searchType: SearchResultType.BIG_BUY_PRODUCT,
      })),
    ];

    return _.shuffle(results);
  }, [publicSearchResults]);

  const highlightSearch = (result: string, middleSearch?: boolean) => {
    const index = _.toLower(result).indexOf(_.toLower(searchTerm));

    if (middleSearch && index !== 0) {
      const begginingText = _.slice(result, 0, index);
      const highlightedText = _.slice(result, index, index + searchTerm.length);
      const restOfText = _.slice(result, index + searchTerm.length);

      return (
        <p className={styles.resultText}>
          {begginingText}
          <span>{highlightedText}</span>
          {restOfText}
        </p>
      );
    }

    if (index !== -1) {
      const highlightedText = _.slice(result, index, index + searchTerm.length);

      const restOfText = _.slice(result, index + searchTerm.length);

      return (
        <p className={styles.resultText}>
          <span>{highlightedText}</span>
          {restOfText}
        </p>
      );
    }

    return <p>{result}</p>;
  };

  const handleResultClick = (url: string) => {
    navigate(url);
    onCloseSearchBar?.();
    setSearchTerm('');
  };

  const getResult = (
    result: Product | User | Category | Theme,
    isLast: boolean,
  ) => {
    if (
      (result as Product)?.title &&
      (result as Product).searchType === SearchResultType.BIG_BUY_PRODUCT
    ) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            navigate(
              routes.publicProducts.product.replace(
                ':id',
                (result as Product).id,
              ),
            )
          }
        >
          <ListItemText
            primary={highlightSearch(
              ` ${(result as Product).legoSetNumber} ${
                (result as Product).title
              }`,
              true,
            )}
          />
          <span className={styles.resultType}>
            {`${translate(intl, 'PUBLIC_SEARCH.SET')}`}
          </span>
        </div>
      );
    }

    if (
      (result as Product)?.title &&
      (result as Product).searchType === SearchResultType.SETS
    ) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            handleResultClick(
              generateParamsUrl(routes.publicProducts.list, {
                ...publicProductListParams,
                sets: [(result as Product).legoSetNumber],
                type: [ProductType.ALTERNATE],
              }),
            )
          }
        >
          <ListItemText
            primary={highlightSearch(
              ` ${(result as Product).legoSetNumber} ${
                (result as Product).title
              }`,
              true,
            )}
          />
          <span className={styles.resultType}>
            {`${translate(intl, 'PUBLIC_SEARCH.SEE_ALL_FROM_SET')}`}
          </span>
        </div>
      );
    }

    if ((result as Product)?.title) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            handleResultClick(
              routes.publicProducts.product.replace(
                ':id',
                (result as Product).id,
              ),
            )
          }
        >
          <ListItemText primary={highlightSearch((result as Product).title)} />
          <span className={styles.resultType}>
            {translate(intl, `PRODUCT_TYPE.${(result as Product).type}`)}
          </span>
        </div>
      );
    }

    if ((result as User)?.username) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            handleResultClick(
              routes.publicProfile.profile.replace(
                ':username',
                (result as User).username,
              ),
            )
          }
        >
          <ListItemText primary={highlightSearch((result as User).username)} />
          <span className={styles.resultType}>
            {translate(intl, 'PUBLIC_SEARCH.USER')}
          </span>
        </div>
      );
    }

    if ((result as Category)?.image) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            handleResultClick(
              `${routes.publicProducts.list}/?category=${
                (result as Category).id
              }`,
            )
          }
        >
          <ListItemText primary={highlightSearch((result as Category).name)} />
          <span className={styles.resultType}>
            {translate(intl, 'PUBLIC_SEARCH.CATEGORY')}
          </span>
        </div>
      );
    }

    if ((result as Theme)?.name) {
      return (
        <div
          className={cx(styles.resultContainer, {
            [styles.isLast]: isLast,
          })}
          onClick={() =>
            handleResultClick(
              `${routes.publicProducts.list}/?themes=${(result as Theme).id}`,
            )
          }
        >
          <ListItemText primary={highlightSearch((result as Theme).name)} />
          <span className={styles.resultType}>
            {translate(intl, 'PUBLIC_SEARCH.THEME')}
          </span>
        </div>
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const firstResult = shuffuledSearchResults?.[0];

    if (event.key === 'Enter' && firstResult) {
      if ((firstResult as Theme)?.name) {
        navigate(
          `${routes.publicProducts.list}/?themes=${(firstResult as Theme).id}`,
        );
      }

      if ((firstResult as Category)?.image) {
        navigate(
          `${routes.publicProducts.list}/?category=${
            (firstResult as Theme).id
          }`,
        );
      }

      if ((firstResult as User)?.username) {
        navigate(
          routes.publicProfile.profile.replace(
            ':username',
            (firstResult as User).username,
          ),
        );
      }

      if ((firstResult as Product)?.title) {
        navigate(
          routes.publicProducts.product.replace(
            ':id',
            (firstResult as Product).id,
          ),
        );
      }

      onCloseSearchBar?.();
      setSearchTerm('');
    }
  };

  const getSearchResultListKey = (
    result: Category | Product | User | Theme,
  ) => {
    if (
      (result as Product)?.title &&
      (result as Product).searchType === SearchResultType.SETS
    )
      return result.id + '_SET';

    return result.id;
  };

  return (
    <div
      ref={searchBarRef}
      className={cx(styles.searchBarContainer, {
        [styles.isMobile]: isMobile,
        [styles.isEmpty]: !!searchTerm,
      })}
      onClick={() => setIsSearchOpen(true)}
    >
      <TextField
        className={cx(styles.searchBar, className)}
        variant="outlined"
        placeholder={placeholder ?? 'Search'}
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: <img src={searchIcon} />,
        }}
      />
      {searchTerm && isSearchOpen && (
        <List className={styles.results}>
          {shuffuledSearchResults.length === 0 ? (
            <ListItem className={styles.result}>
              <ListItemText
                primary={translate(intl, 'PUBLIC_SEARCH.NO_RESULTS_FOUND')}
                className={styles.noResults}
              />
            </ListItem>
          ) : (
            <>
              {shuffuledSearchResults.map((result, index) => (
                <ListItem
                  key={getSearchResultListKey(result)}
                  className={styles.result}
                >
                  {getResult(
                    result,
                    shuffuledSearchResults.length - 1 === index,
                  )}
                </ListItem>
              ))}
            </>
          )}
        </List>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  publicSearchResults: state.search.publicSearchResults,
  publicProductListParams: state.product.publicProductListParams,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchPublicSearchResults: (params: SearchPublicResultsRequest) =>
    dispatch(searchService.fetchPublicSearchResults(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicSearchBar);
