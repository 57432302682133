import { useIntl } from 'react-intl';
import styles from './AffiliateTab.module.scss';
import { translate } from '../../../../utility/messageTranslator/translate';
import AffiliateStatistics from './AffiliateStatistics/AffiliateStatistics';
import { Button as MuiButton, TextField, Tooltip } from '@mui/material';
import Button from '../../../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import infoIcon from '../../../../assets/icons/info.svg';
import downloadIcon from '../../../../assets/icons/download-content.svg';
import { AffiliateList } from './AffiliateList/AffiliateList';
import { User } from '../../../../domain/User';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as userService from '../../../../store/user/service';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import successIcon from '../../../../assets/icons/success.svg';

export type Props = {
  currentUser: User | null;
  invitedUsers: User[];
  onFetchInvitedUsers: () => void;
};

const AffiliateTab = ({
  currentUser,
  invitedUsers,
  onFetchInvitedUsers,
}: Props) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    onFetchInvitedUsers();
  }, []);

  const refCode = useMemo(
    () =>
      `${process.env.PUBLIC_URL || ''}/referral/${
        currentUser?.profile?.refCode || ''
      }`,
    [currentUser],
  );

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(refCode);
    setIsCopied(true);
  };

  return (
    <div className={styles.affiliateTab}>
      <h3 className={styles.affiliateTitle}>
        {translate(intl, 'AFFILIATE.TITLE')}
      </h3>
      <AffiliateStatistics
        invitedUsersCount={invitedUsers.length}
        currentUser={currentUser}
      />
      <div className={styles.affiliateLinkContainer}>
        <div className={styles.affiliateLinkLabel}>
          <p>{translate(intl, 'AFFILIATE.YOUR_AFFILIATE')}</p>
          <Tooltip
            title={translate(intl, 'AFFILIATE_TAB.AFFILIATE_LINK_POPOVER')}
          >
            <img src={infoIcon} />
          </Tooltip>
        </div>
        <div className={styles.inputContainer}>
          <TextField value={refCode} className={styles.textField} />
          <Button
            className={styles.copyButton}
            onClick={() => handleCopyButtonClick()}
          >
            {isCopied ? (
              <ReactSVG src={successIcon} />
            ) : (
              translate(intl, 'AFFILIATE.COPY')
            )}
          </Button>
        </div>
      </div>
      <div className={styles.learnMoreContainer}>
        <div
          className={styles.learnMoreText}
          dangerouslySetInnerHTML={{
            __html: translate(intl, 'AFFILIATE.LEARN_MORE_FORMATTED_TEXT'),
          }}
        />
        <MuiButton className={styles.learnMoreButton} variant="outlined">
          <Link to={routes.public.affiliate} target="_blank" rel="noreferrer">
            {translate(intl, 'AFFILIATE.LEARN_MORE')}
          </Link>
        </MuiButton>
      </div>
      <div className={styles.learnMoreDivider} />
      <div className={styles.contentDownloadContainer}>
        <ReactSVG
          src={downloadIcon}
          className={styles.downloadIcon}
          onClick={() => {}}
        />
        <div className={styles.contentContainer}>
          <h3 className={styles.contentTitle}>
            {translate(intl, 'AFFILIATE.CONTENT_DOWNLOAD')}
          </h3>
          <p className={styles.contentText}>
            {translate(intl, 'AFFILIATE.CONTENT_DOWNLOAD_TEXT')}
          </p>
          <MuiButton
            className={styles.seeContentButton}
            variant="outlined"
            onClick={() => {
              navigate(routes.profile.contentDownload);
            }}
          >
            {translate(intl, 'AFFILIATE.CONTENT_DOWNLOAD_BUTTON')}
          </MuiButton>
        </div>
      </div>
      <div className={styles.contentDownloadDivider} />
      <div className={styles.referralsListContainer}>
        <div className={styles.referralsTitle}>
          <p className={styles.title}>
            {translate(intl, 'AFFILIATE.YOUR_REFERRALS')}
          </p>
          <Tooltip title={translate(intl, 'AFFILIATE_TAB.LIST_POPOVER')}>
            <img src={infoIcon} />
          </Tooltip>
        </div>
      </div>
      <AffiliateList invitedUsersList={invitedUsers} />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  invitedUsers: state.user.invitedUsers,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchInvitedUsers: () => dispatch(userService.fetchInvitedUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateTab);
