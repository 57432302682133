import React, { useState } from 'react';
import styles from './MenuSubItemButton.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { NavigationItem } from '../../../Layout/Layout';
import { Icon } from '@iconify/react';
import SubSubItem from '../SubSubItems/SubSubItem';
import { Button } from '@mui/material';

type Props = {
  navItem: NavigationItem;
};

const ICON_SIZE = '1rem';

const MenuSubItemButton = ({ navItem }: Props) => {
  const [isSubSubMenuOpen, setSubSubMenuOpen] = useState(false);

  const hasItems =
    navItem?.subNavigations && navItem?.subNavigations?.length >= 1;

  const icon = isSubSubMenuOpen
    ? 'solar:alt-arrow-down-linear'
    : 'solar:alt-arrow-right-linear';

  if (hasItems) {
    const isActive = window.location.href.includes(navItem.to);

    return (
      <div className={styles.subItemContainer}>
        <Button
          className={cx(styles.subItem, {
            [styles.activeSubItem]: isActive,
            [styles.withItems]: true,
          })}
          role="button"
          onClick={() => setSubSubMenuOpen(!isSubSubMenuOpen)}
        >
          <div className={styles.detailsContainer}>
            {navItem.icon}
            <div className={styles.navigationText}>{navItem.label}</div>
          </div>
          <Icon
            className={cx(styles.subItemButton, {
              [styles.isOpen]: isSubSubMenuOpen,
            })}
            icon={icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </Button>
        <div
          className={cx(styles.subSubItemContainer, {
            [styles.isActive]: hasItems && isSubSubMenuOpen,
          })}
        >
          {navItem?.subNavigations?.map((subNavigation) => (
            <SubSubItem
              key={subNavigation.label + subNavigation.to}
              subNavigation={subNavigation}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <Button sx={{ padding: 0 }}>
      <div className={styles.subItemContainer}>
        <NavLink
          to={navItem.to}
          className={({ isActive }) =>
            cx(styles.subItem, {
              [styles.activeSubItem]: isActive,
            })
          }
        >
          {navItem.icon}
          <div className={styles.navigationText}>{navItem.label}</div>
        </NavLink>
      </div>
    </Button>
  );
};

export default MenuSubItemButton;
