import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import styles from './TopUpCardSuccess.module.scss';
import successIcon from '../../../../../assets/icons/success-circle.svg';
import bricksIcon from '../../../../../assets/icons/bricks.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';
import { User } from '../../../../../domain/User';
import { getFormattedAmount } from '../../../../../utility/formattedAmount/formattedAmount';

type Props = {
  onClose: () => void;
  currentUser: User | null;
};

const TopUpCardSuccess = ({ onClose, currentUser }: Props) => {
  const intl = useIntl();

  const urlParams = new URLSearchParams(window.location.search);

  const amount = urlParams.get('amount') ?? 0;
  const transactionId = urlParams.get('transaction') ?? '';
  const packageId = urlParams.get('packageId') ?? '';
  const totalPrice = urlParams.get('totalPrice') ?? 0;

  useEffect(() => {
    if (
      !transactionId ||
      !amount ||
      !totalPrice ||
      !currentUser ||
      !packageId
    ) {
      return;
    }

    // @ts-ignore
    window?.dataLayer?.push({
      event: 'completeTopUp',
      event_category: 'Wallet',
      event_action: 'Complete',
      event_label: 'Top Up',
      value: totalPrice,
      currency: 'USD',
      transaction_id: transactionId,
      items: {
        package_id: packageId,
        quantity: 1,
      },
    });
  }, [transactionId, amount, totalPrice, currentUser]);

  return (
    <div className={styles.container}>
      <div>
        <img src={successIcon} />
      </div>
      <h3>{translate(intl, 'TOP_UP_CARD_SUCCESS.TITLE')}</h3>
      <div className={styles.textContainer}>
        <div className={styles.iconContainer}>
          <img src={bricksIcon} />
        </div>
        <span className={styles.amount}>{getFormattedAmount(+amount)}</span>
        <p>{translate(intl, 'TOP_UP_CARD_SUCCESS.SUBTITLE')}</p>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => onClose()}
          className={styles.button}
        >
          {translate(intl, 'TOP_UP_CARD_SUCCESS.BUTTON_CLOSE')}
        </Button>
      </div>
    </div>
  );
};

export default TopUpCardSuccess;
