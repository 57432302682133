import * as actionTypes from './actionTypes';
import { ContentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Content } from '../../domain/Content';

export type ContentStateType = {
  contentList: ListResults<Content> | null;
  contentListLoading: boolean;
  contentListError: HttpError;
  contentListUpdateNeeded: boolean;
  contentCreateLoading: boolean;
  contentCreateError: HttpError;
  contentCreateSuccess: boolean;
  createdContent: Content | null;
  contentUpdateLoading: boolean;
  contentUpdateError: HttpError;
  contentUpdateSuccess: boolean;
  content: Content | null;
  contentLoading: boolean;
  contentError: HttpError;
  contentDeleteLoading: boolean;
  contentDeleteError: HttpError;
  contentDeleteSuccess: boolean;
  publicContentList: ListResults<Content> | null;
  publicContentListLoading: boolean;
  publicContentListError: HttpError;
};

export type ContentActionType = ContentStateType & {
  type: ContentActionTypes;
};

export const initialState: ContentStateType = {
  contentList: null,
  contentListLoading: true,
  contentListError: null,
  contentListUpdateNeeded: false,
  contentCreateLoading: false,
  contentCreateError: null,
  contentCreateSuccess: false,
  createdContent: null,
  contentUpdateLoading: false,
  contentUpdateError: null,
  contentUpdateSuccess: false,
  content: null,
  contentLoading: false,
  contentError: null,
  contentDeleteLoading: false,
  contentDeleteError: null,
  contentDeleteSuccess: false,
  publicContentList: null,
  publicContentListLoading: true,
  publicContentListError: null,
};

const fetchContentListStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentListLoading: true,
  contentCreateSuccess: false,
  contentUpdateSuccess: false,
});

const fetchContentListSuccess = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentListLoading: false,
  contentList: action.contentList,
  contentListUpdateNeeded: false,
});

const fetchContentListFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentListLoading: false,
  contentListError: action.contentListError,
});

const fetchPublicContentListStart = (
  state: ContentStateType,
): ContentStateType => ({
  ...state,
  publicContentListLoading: true,
});

const fetchPublicContentListSuccess = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  publicContentListLoading: false,
  publicContentList: action.publicContentList,
});

const fetchPublicContentListFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  publicContentListLoading: false,
  publicContentListError: action.publicContentListError,
});

const fetchContentStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentLoading: true,
  contentCreateSuccess: false,
  contentUpdateSuccess: false,
});

const fetchContentSuccess = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentError: null,
  contentLoading: false,
  content: action.content,
});

const fetchContentFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentLoading: false,
  contentError: action.contentError,
});

const createContentStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentCreateLoading: true,
});

const createContentSuccess = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentCreateLoading: false,
  contentCreateError: null,
  contentCreateSuccess: true,
  contentListUpdateNeeded: true,
  createdContent: action.createdContent,
});

const createContentFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentCreateLoading: false,
  contentCreateError: action.contentCreateError,
});

const updateContentStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentUpdateLoading: true,
});

const updateContentSuccess = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentUpdateError: null,
  contentUpdateLoading: false,
  contentUpdateSuccess: true,
  contentListUpdateNeeded: true,
});

const updateContentFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentUpdateLoading: false,
  contentUpdateError: action.contentUpdateError,
});

const deleteContentStart = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentDeleteLoading: true,
});

const deleteContentSuccess = (state: ContentStateType): ContentStateType => ({
  ...state,
  contentDeleteLoading: false,
  contentDeleteSuccess: true,
  contentListUpdateNeeded: true,
});

const deleteContentFail = (
  state: ContentStateType,
  action: ContentActionType,
): ContentStateType => ({
  ...state,
  contentDeleteLoading: false,
  contentDeleteError: action.contentDeleteError,
});

const resetContentStore = (): ContentStateType => ({
  ...initialState,
});

const completelyResetContentStore = (): ContentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ContentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENT_LIST_START:
      return fetchContentListStart(state);
    case actionTypes.FETCH_CONTENT_LIST_SUCCESS:
      return fetchContentListSuccess(state, action);
    case actionTypes.FETCH_CONTENT_LIST_FAIL:
      return fetchContentListFail(state, action);
    case actionTypes.FETCH_PUBLIC_CONTENT_LIST_START:
      return fetchPublicContentListStart(state);
    case actionTypes.FETCH_PUBLIC_CONTENT_LIST_SUCCESS:
      return fetchPublicContentListSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CONTENT_LIST_FAIL:
      return fetchPublicContentListFail(state, action);
    case actionTypes.FETCH_CONTENT_START:
      return fetchContentStart(state);
    case actionTypes.FETCH_CONTENT_SUCCESS:
      return fetchContentSuccess(state, action);
    case actionTypes.FETCH_CONTENT_FAIL:
      return fetchContentFail(state, action);
    case actionTypes.CREATE_CONTENT_START:
      return createContentStart(state);
    case actionTypes.CREATE_CONTENT_SUCCESS:
      return createContentSuccess(state, action);
    case actionTypes.CREATE_CONTENT_FAIL:
      return createContentFail(state, action);
    case actionTypes.UPDATE_CONTENT_START:
      return updateContentStart(state);
    case actionTypes.UPDATE_CONTENT_SUCCESS:
      return updateContentSuccess(state);
    case actionTypes.UPDATE_CONTENT_FAIL:
      return updateContentFail(state, action);
    case actionTypes.DELETE_CONTENT_START:
      return deleteContentStart(state);
    case actionTypes.DELETE_CONTENT_SUCCESS:
      return deleteContentSuccess(state);
    case actionTypes.DELETE_CONTENT_FAIL:
      return deleteContentFail(state, action);
    case actionTypes.RESET_CONTENT_STORE:
      return resetContentStore();
    case actionTypes.LOGOUT:
      return completelyResetContentStore();
    default:
      return state;
  }
};

export default reducer;
