export const DEFAULT_LOCALE = 'en';
export const DEFAULT_ADMIN_LOCALE = 'en';
export const NO_INFO = '-';

export const MAXIMUM_ALTS_PER_BUNDLE = 10;
export const MINIMUM_ALTS_PER_BUNDLE = 1;

export const colors = {
  primaryColor: 'rgb(33, 43, 54)',
  buttonGradientTop: '#8C2ADC',
  buttonGradientBottom: '#370065',
  secondaryColor: 'rgb(99, 115, 129)',
  whiteColor: 'rgb(255, 255, 255)',
  inputErrorColor: 'rgb(255, 86, 48)',
  inputBorderColor: 'rgba(145,158,171,0.2)',
  tableRowHoverColor: 'rgba(145, 158, 171, 0.08)',
  tableCellBorderColor: 'rgb(241, 243, 244)',
  containedButtonHoverColor: 'rgb(69, 79, 91)',
  outlinedButtonBorderColor: 'rgba(145, 158, 171, 0.32)',
  outlinedButtonHoverColor: 'rgba(33, 43, 54, 0.08)',
  textFieldTextColor: 'rgba(196, 196, 196, 1)',
  textFieldBorderColor: 'rgba(230, 230, 230, 1)',
  textFieldSelectedColor: 'rgba(140, 42, 220, 1)',
  textButtonHoverBackground: 'rgba(145, 158, 171, 0.12)',
  textButtonColor: 'rgb(99, 115, 129)',
  accentColor: '#8C2ADC',
  primaryPublicColor: '#8C2ADC',
  black: '#000000',
  accordionColor: '#370065',
  accordionDetailsColor: '#3C3C3C',
  accordionBackgroundColor: '#F7F3FA',
  charcoalGray: '#3c3c3c;',
  profileSettingsDivider: '#7171714D',
  outlinedColor: 'rgba(55, 0, 101, 1)',
  paginationSelectedBackground: 'rgba(140, 42, 220, 1)',
  paginationBorder: 'rgba(241, 241, 241, 1)',
  buttonOutlinedColor: 'rgba(55, 0, 101, 1)',
  buttonOutlinedBackgroundColor: 'rgba(243, 234, 251, 1)',
  buttonOutlinedHoveredBackgroundColor: 'rgba(226, 216, 235, 1)',
};

export const fonts = {
  poppins: `'Poppins', sans-serif`,
  montserrat: `'Montserrat', sans-serif`,
};
