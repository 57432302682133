import * as actionTypes from './actionTypes';
import { PromotionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Promotion } from '../../domain/Promotion';

export type PromotionStateType = {
  promotionsList: ListResults<Promotion> | null;
  promotionsListLoading: boolean;
  promotionsListError: HttpError;
  promotionsListUpdateNeeded: boolean;
  promotionCreateLoading: boolean;
  promotionCreateError: HttpError;
  promotionCreateSuccess: boolean;
  createdPromotion: Promotion | null;
  promotionUpdateLoading: boolean;
  promotionUpdateError: HttpError;
  promotionUpdateSuccess: boolean;
  togglePromotionStatusLoading: boolean;
  togglePromotionStatusError: HttpError;
  togglePromotionStatusSuccess: boolean;
  publicPromotions: Promotion[];
  publicPromotionsLoading: boolean;
  publicPromotionsError: HttpError;
};

export type PromotionActionType = PromotionStateType & {
  type: PromotionActionTypes;
};

export const initialState: PromotionStateType = {
  promotionsList: null,
  promotionsListLoading: true,
  promotionsListError: null,
  promotionsListUpdateNeeded: false,
  promotionCreateLoading: false,
  promotionCreateError: null,
  promotionCreateSuccess: false,
  createdPromotion: null,
  promotionUpdateLoading: false,
  promotionUpdateError: null,
  promotionUpdateSuccess: false,
  togglePromotionStatusLoading: false,
  togglePromotionStatusError: null,
  togglePromotionStatusSuccess: false,
  publicPromotions: [],
  publicPromotionsLoading: false,
  publicPromotionsError: null,
};

const fetchPromotionsListStart = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  promotionsListLoading: true,
});

const fetchPromotionsListSuccess = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  promotionsListLoading: false,
  promotionsList: action.promotionsList,
  promotionsListUpdateNeeded: false,
});

const fetchPromotionsListFail = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  promotionsListLoading: false,
  promotionsListError: action.promotionsListError,
});

const createPromotionStart = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  promotionCreateLoading: true,
});

const createPromotionSuccess = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  promotionCreateLoading: false,
  promotionCreateError: null,
  promotionCreateSuccess: true,
  promotionsListUpdateNeeded: true,
});

const createPromotionFail = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  promotionCreateLoading: false,
  promotionCreateError: action.promotionCreateError,
});

const updatePromotionStart = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  promotionUpdateLoading: true,
});

const updatePromotionSuccess = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  promotionUpdateLoading: false,
  promotionUpdateSuccess: true,
  promotionsListUpdateNeeded: true,
  promotionUpdateError: null,
});

const updatePromotionFail = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  promotionUpdateLoading: false,
  promotionUpdateError: action.promotionUpdateError,
});

const togglePromotionStatusStart = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  togglePromotionStatusLoading: true,
});

const togglePromotionStatusSuccess = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  togglePromotionStatusLoading: false,
  togglePromotionStatusSuccess: true,
  promotionsListUpdateNeeded: true,
});

const togglePromotionStatusFail = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  togglePromotionStatusLoading: false,
  togglePromotionStatusError: action.togglePromotionStatusError,
});

const fetchPublicPromotionsStart = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  publicPromotionsLoading: true,
});

const fetchPublicPromotionsSuccess = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  publicPromotionsLoading: false,
  publicPromotions: action.publicPromotions,
});

const fetchPublicPromotionsFail = (
  state: PromotionStateType,
  action: PromotionActionType,
): PromotionStateType => ({
  ...state,
  publicPromotionsLoading: false,
  publicPromotionsError: action.publicPromotionsError,
});

const resetCreatedPromotionStore = (
  state: PromotionStateType,
): PromotionStateType => ({
  ...state,
  createdPromotion: null,
  promotionCreateError: null,
  promotionCreateLoading: false,
  promotionCreateSuccess: false,
});

const resetPromotionStore = (): PromotionStateType => ({
  ...initialState,
});

const completelyResetPromotionStore = (): PromotionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PromotionActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROMOTIONS_LIST_START:
      return fetchPromotionsListStart(state);
    case actionTypes.FETCH_PROMOTIONS_LIST_SUCCESS:
      return fetchPromotionsListSuccess(state, action);
    case actionTypes.FETCH_PROMOTIONS_LIST_FAIL:
      return fetchPromotionsListFail(state, action);
    case actionTypes.FETCH_PUBLIC_PROMOTIONS_START:
      return fetchPublicPromotionsStart(state);
    case actionTypes.FETCH_PUBLIC_PROMOTIONS_SUCCESS:
      return fetchPublicPromotionsSuccess(state, action);
    case actionTypes.FETCH_PROMOTIONS_LIST_FAIL:
      return fetchPublicPromotionsFail(state, action);
    case actionTypes.CREATE_PROMOTION_START:
      return createPromotionStart(state);
    case actionTypes.CREATE_PROMOTION_SUCCESS:
      return createPromotionSuccess(state, action);
    case actionTypes.CREATE_PROMOTION_FAIL:
      return createPromotionFail(state, action);
    case actionTypes.UPDATE_PROMOTION_START:
      return updatePromotionStart(state);
    case actionTypes.UPDATE_PROMOTION_SUCCESS:
      return updatePromotionSuccess(state);
    case actionTypes.UPDATE_PROMOTION_FAIL:
      return updatePromotionFail(state, action);
    case actionTypes.TOGGLE_PROMOTION_STATUS_START:
      return togglePromotionStatusStart(state);
    case actionTypes.TOGGLE_PROMOTION_STATUS_SUCCESS:
      return togglePromotionStatusSuccess(state);
    case actionTypes.TOGGLE_PROMOTION_STATUS_FAIL:
      return togglePromotionStatusFail(state, action);
    case actionTypes.RESET_CREATED_PROMOTION_STORE:
      return resetCreatedPromotionStore(state);
    case actionTypes.RESET_PROMOTION_STORE:
      return resetPromotionStore();
    case actionTypes.LOGOUT:
      return completelyResetPromotionStore();
    default:
      return state;
  }
};

export default reducer;
