import * as actionTypes from './actionTypes';
import { BigBuyItemActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { adminBigBuyItemFilterParams } from './service';
import { BigBuyItem, BigBuyItemAssigned } from '../../domain/BigBuyItem';

export type BigBuyItemStateType = {
  adminBigBuyItemListParams: adminBigBuyItemFilterParams;
  bigBuyItemList: ListResults<BigBuyItem> | null;
  bigBuyItemListLoading: boolean;
  bigBuyItemListError: HttpError;
  bigBuyItemListUpdateNeeded: boolean;
  assignBigBuyItemToProductSuccess: boolean;
  assignBigBuyItemToProductLoading: boolean;
  assignBigBuyItemToProductError: HttpError;
};

export type BigBuyItemActionType = BigBuyItemStateType & {
  type: BigBuyItemActionTypes;
  id: string;
};

export const initialState: BigBuyItemStateType = {
  adminBigBuyItemListParams: {
    sortBy: '',
    search: '',
    sortDirection: 'ASC',
    page: 0,
    limit: 10,
    assigned: BigBuyItemAssigned.ALL,
  },
  bigBuyItemList: null,
  bigBuyItemListLoading: true,
  bigBuyItemListError: null,
  bigBuyItemListUpdateNeeded: false,
  assignBigBuyItemToProductSuccess: false,
  assignBigBuyItemToProductLoading: false,
  assignBigBuyItemToProductError: null,
};

const fetchBigBuyItemListStart = (
  state: BigBuyItemStateType,
): BigBuyItemStateType => ({
  ...state,
  bigBuyItemListLoading: true,
  bigBuyItemListUpdateNeeded: false,
});

const fetchBigBuyItemListSuccess = (
  state: BigBuyItemStateType,
  action: BigBuyItemActionType,
): BigBuyItemStateType => ({
  ...state,
  bigBuyItemListLoading: false,
  bigBuyItemList: action.bigBuyItemList,
  bigBuyItemListUpdateNeeded: false,
});

const fetchBigBuyItemListFail = (
  state: BigBuyItemStateType,
  action: BigBuyItemActionType,
): BigBuyItemStateType => ({
  ...state,
  bigBuyItemListLoading: false,
  bigBuyItemListError: action.bigBuyItemListError,
});

const resetBigBuyItemStore = (
  state: BigBuyItemStateType,
): BigBuyItemStateType => ({
  ...initialState,
  adminBigBuyItemListParams: state.adminBigBuyItemListParams,
});

const assignBigBuyItemToProductStart = (
  state: BigBuyItemStateType,
): BigBuyItemStateType => ({
  ...state,
  assignBigBuyItemToProductLoading: true,
});

const assignBigBuyItemToProductSuccess = (
  state: BigBuyItemStateType,
): BigBuyItemStateType => ({
  ...state,
  assignBigBuyItemToProductLoading: false,
  assignBigBuyItemToProductSuccess: true,
  bigBuyItemListUpdateNeeded: true,
  assignBigBuyItemToProductError: null,
});

const assignBigBuyItemToProductFail = (
  state: BigBuyItemStateType,
  action: BigBuyItemActionType,
): BigBuyItemStateType => ({
  ...state,
  assignBigBuyItemToProductLoading: false,
  assignBigBuyItemToProductError: action.assignBigBuyItemToProductError,
});

const reducer = (state = initialState, action: BigBuyItemActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BIG_BUY_ITEM_LIST_START:
      return fetchBigBuyItemListStart(state);
    case actionTypes.FETCH_BIG_BUY_ITEM_LIST_SUCCESS:
      return fetchBigBuyItemListSuccess(state, action);
    case actionTypes.FETCH_BIG_BUY_ITEM_LIST_FAIL:
      return fetchBigBuyItemListFail(state, action);
    case actionTypes.ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_START:
      return assignBigBuyItemToProductStart(state);
    case actionTypes.ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_SUCCESS:
      return assignBigBuyItemToProductSuccess(state);
    case actionTypes.ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_FAIL:
      return assignBigBuyItemToProductFail(state, action);
    case actionTypes.ASSIGN_BIG_BUY_ITEM_TO_PRODUCT_FAIL:
      return fetchBigBuyItemListFail(state, action);
    case actionTypes.RESET_BIG_BUY_ITEM_STORE:
      return resetBigBuyItemStore(state);
    default:
      return state;
  }
};

export default reducer;
