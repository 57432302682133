import React from 'react';
import { Avatar, Button, Popover } from '@mui/material';
import userPicture from '../../../assets/default_avatar.png';
import styles from './AvatarButton.module.scss';
import AvatarMenu from './AvatarMenu/AvatarMenu';
import cx from 'classnames';

const AvatarButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={cx(styles.avatarButton, {
          [styles.isActive]: !!anchorEl,
        })}
        disableRipple
        onClick={handleClick}
      >
        <Avatar src={userPicture} className={styles.avatar} />
      </Button>
      <Popover
        className={styles.avatarButtonPopover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 50,
          horizontal: 'left',
        }}
      >
        <AvatarMenu />
      </Popover>
    </>
  );
};

export default AvatarButton;
