import React, { useState } from 'react';
import styles from './ActionsPopover.module.scss';
import { IconButton, Popover } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import _ from 'lodash';
import cx from 'classnames';

type PopoverItem = {
  type: string;
  action: () => void;
  label?: string;
  disabled?: boolean;
};

type Props = {
  onClick?: () => void;
  actions: PopoverItem[];
};

const ActionsPopover = ({ actions, onClick }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const renderActions = () => {
    return actions.map((singleAction, index) => (
      <li
        className={cx(styles.popoverListItem, {
          [styles.disabled]: singleAction.disabled,
        })}
        key={singleAction.toString() + index.toString()}
        onClick={() => {
          if (singleAction.disabled) {
            return;
          }
          singleAction.action();
          setIsPopoverOpen(false);
        }}
      >
        <div className={styles.popoverItemText}>
          {singleAction.label || _.capitalize(singleAction.type)}
        </div>
      </li>
    ));
  };

  return (
    <>
      {actions.length > 0 && (
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setIsPopoverOpen(true);
            onClick && onClick();
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

export default ActionsPopover;
