import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';
import Button from '../../common/Button/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import modalCloseIcon from '../../assets/icons/modal-close.svg';

type Props = {
  onConfirm?: () => void;
  onClose: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  explanationText?: string | undefined;
  title?: string;
  children?: ReactNode;
  className?: string;
  isSmall?: boolean;
  isMedium?: boolean;
  isFull?: boolean;
  isFooterHidden?: boolean;
  withClose?: boolean;
  closeClassName?: string;
  infoText?: string;
};

const Modal = ({
  isOpen,
  onConfirm,
  onClose,
  explanationText,
  title,
  isLoading,
  children,
  className,
  isSmall,
  isMedium,
  isFull,
  isFooterHidden,
  withClose,
  closeClassName,
  infoText,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      disableEnforceFocus
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={cx(
        styles.modalContainer,
        {
          [styles.isSmall]: !!onConfirm || isSmall,
          [styles.isMedium]: isMedium,
          [styles.isFull]: isFull,
        },
        className,
      )}
    >
      {title && (
        <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
          {title}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {!title && withClose && (
        <DialogTitle
          id="alert-dialog-title"
          className={cx(styles.modalClose, closeClassName)}
        >
          <IconButton onClick={onClose}>
            <img src={modalCloseIcon} />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>
        {explanationText && (
          <DialogContentText id="alert-dialog-description">
            {explanationText}
          </DialogContentText>
        )}
        {infoText && (
          <DialogContentText id="alert-dialog-description">
            {infoText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {!isFooterHidden && onConfirm && (
        <DialogActions>
          <Button
            buttonVariant="outlined"
            size="small"
            onClick={onClose}
            isLoading={isLoading}
          >
            {translate(intl, 'MODAL.CANCEL')}
          </Button>
          <Button onClick={onConfirm} isLoading={isLoading} size="small">
            {translate(intl, 'MODAL.CONFIRM')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
