import * as actionTypes from './actionTypes';
import { WithdrawalActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Withdrawal } from '../../domain/Withdrawal';

export type WithdrawalStateType = {
  withdrawalList: ListResults<Withdrawal> | null;
  withdrawalListLoading: boolean;
  withdrawalListError: HttpError;
  withdrawalListUpdateNeeded: boolean;
  requestWithdrawalSuccess: boolean;
  requestWithdrawalLoading: boolean;
  requestWithdrawalError: HttpError;
  updateWithdrawalStatusLoading: boolean;
  updateWithdrawalStatusSuccess: boolean;
  updateWithdrawalStatusError: HttpError;
};

export type WithdrawalActionType = WithdrawalStateType & {
  type: WithdrawalActionTypes;
};

export const initialState: WithdrawalStateType = {
  withdrawalList: null,
  withdrawalListLoading: true,
  withdrawalListError: null,
  withdrawalListUpdateNeeded: false,
  requestWithdrawalSuccess: false,
  requestWithdrawalLoading: false,
  requestWithdrawalError: null,
  updateWithdrawalStatusLoading: false,
  updateWithdrawalStatusSuccess: false,
  updateWithdrawalStatusError: null,
};

const fetchWithdrawalListStart = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  withdrawalListLoading: true,
});

const fetchWithdrawalListSuccess = (
  state: WithdrawalStateType,
  action: WithdrawalActionType,
): WithdrawalStateType => ({
  ...state,
  withdrawalListLoading: false,
  withdrawalList: action.withdrawalList,
  withdrawalListUpdateNeeded: false,
  updateWithdrawalStatusSuccess: false,
});

const fetchWithdrawalListFail = (
  state: WithdrawalStateType,
  action: WithdrawalActionType,
): WithdrawalStateType => ({
  ...state,
  withdrawalListLoading: false,
  withdrawalListError: action.withdrawalListError,
});

const requestWithdrawalStart = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  requestWithdrawalLoading: true,
  requestWithdrawalSuccess: false,
});

const requestWithdrawalSuccess = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  requestWithdrawalLoading: false,
  requestWithdrawalSuccess: true,
});

const requestWithdrawalFail = (
  state: WithdrawalStateType,
  action: WithdrawalActionType,
): WithdrawalStateType => ({
  ...state,
  requestWithdrawalLoading: false,
  requestWithdrawalError: action.requestWithdrawalError,
  requestWithdrawalSuccess: false,
});

const updateWithdrawalStatusStart = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  updateWithdrawalStatusLoading: true,
  updateWithdrawalStatusSuccess: false,
});

const updateWithdrawalStatusSuccess = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  updateWithdrawalStatusLoading: false,
  updateWithdrawalStatusSuccess: true,
  withdrawalListUpdateNeeded: true,
});

const updateWithdrawalStatusFail = (
  state: WithdrawalStateType,
  action: WithdrawalActionType,
): WithdrawalStateType => ({
  ...state,
  updateWithdrawalStatusLoading: false,
  updateWithdrawalStatusError: action.updateWithdrawalStatusError,
  updateWithdrawalStatusSuccess: false,
});

const resetWithdrawalRequest = (
  state: WithdrawalStateType,
): WithdrawalStateType => ({
  ...state,
  requestWithdrawalLoading: false,
  requestWithdrawalError: null,
  requestWithdrawalSuccess: false,
});

const resetWithdrawalStore = (): WithdrawalStateType => ({
  ...initialState,
});

const completelyResetWithdrawalStore = (): WithdrawalStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WithdrawalActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WITHDRAWAL_LIST_START:
      return fetchWithdrawalListStart(state);
    case actionTypes.FETCH_WITHDRAWAL_LIST_SUCCESS:
      return fetchWithdrawalListSuccess(state, action);
    case actionTypes.FETCH_WITHDRAWAL_LIST_FAIL:
      return fetchWithdrawalListFail(state, action);
    case actionTypes.REQUEST_WITHDRAWAL_START:
      return requestWithdrawalStart(state);
    case actionTypes.REQUEST_WITHDRAWAL_SUCCESS:
      return requestWithdrawalSuccess(state);
    case actionTypes.REQUEST_WITHDRAWAL_FAIL:
      return requestWithdrawalFail(state, action);
    case actionTypes.UPDATE_WITHDRAWAL_STATUS_START:
      return updateWithdrawalStatusStart(state);
    case actionTypes.UPDATE_WITHDRAWAL_STATUS_SUCCESS:
      return updateWithdrawalStatusSuccess(state);
    case actionTypes.UPDATE_WITHDRAWAL_STATUS_FAIL:
      return updateWithdrawalStatusFail(state, action);
    case actionTypes.RESET_WITHDRAWAL_STORE:
      return resetWithdrawalStore();
    case actionTypes.RESET_WITHDRAWAL_REQUEST:
      return resetWithdrawalRequest(state);
    case actionTypes.LOGOUT:
      return completelyResetWithdrawalStore();
    default:
      return state;
  }
};

export default reducer;
