import { Locale } from '../../domain/Translation';
import { domainToLocaleData } from '../../config/domainToLocaleData';
import { DEFAULT_LOCALE } from '../../config/constants';
import { ProductFilterParams } from '../../store/product/service';

export const getLocaleUrl = (route: string, locale: Locale) =>
  route.replace(':locale', locale);

export const getLocaleFromUrl = (url: string) => {
  const topLevelDomain = '.' + url.split('.').pop();
  return domainToLocaleData[topLevelDomain] || DEFAULT_LOCALE;
};

export const generateParamsUrl = (
  route: string,
  params: ProductFilterParams,
): string => {
  const processedParams: Record<string, string> = {};

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key as keyof ProductFilterParams];

      if (Array.isArray(value)) {
        processedParams[key] = value.join(',');
        continue;
      }

      if (value !== null && value !== undefined) {
        processedParams[key] = String(value);
        continue;
      }

      processedParams[key] = '';
    }
  }
  const urlParams = new URLSearchParams(processedParams);

  return `${route}?${urlParams.toString()}`;
};
