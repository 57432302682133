export const FETCH_PUBLIC_SEARCH_RESULTS_START =
  'FETCH_PUBLIC_SEARCH_RESULTS_START';
export const FETCH_PUBLIC_SEARCH_RESULTS_SUCCESS =
  'FETCH_PUBLIC_SEARCH_RESULTS_SUCCESS';
export const FETCH_PUBLIC_SEARCH_RESULTS_FAIL =
  'FETCH_PUBLIC_SEARCH_RESULTS_FAIL';

export const RESET_SEARCH_STORE = 'RESET_SEARCH_STORE';

export type SearchActionTypes =
  | typeof FETCH_PUBLIC_SEARCH_RESULTS_START
  | typeof FETCH_PUBLIC_SEARCH_RESULTS_SUCCESS
  | typeof FETCH_PUBLIC_SEARCH_RESULTS_FAIL
  | typeof RESET_SEARCH_STORE;
