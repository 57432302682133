import React from 'react';
import styles from './SubSubItem.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { SubNavigationItem } from '../../../Layout/Layout';
import { Icon } from '@iconify/react';
import { Button } from '@mui/material';

type Props = {
  subNavigation: SubNavigationItem;
};

const ICON_SIZE = '0.35rem';
const ICON_COLOR = '#919eab';

const SubSubItem = ({ subNavigation }: Props) => {
  const currentUrlSegments = window.location.href.split('/');

  const targetUrlSegments = subNavigation.to.split('/');

  const isCurrentPageActive =
    currentUrlSegments[currentUrlSegments.length - 1] ===
    targetUrlSegments[targetUrlSegments.length - 1];

  return (
    <Button variant="text" sx={{ height: '1.875rem' }}>
      <NavLink
        to={subNavigation.to}
        className={cx(styles.container, {
          [styles.activeSubItem]: isCurrentPageActive,
        })}
      >
        <Icon
          icon="ph:circle-fill"
          width={ICON_SIZE}
          height={ICON_SIZE}
          style={{ color: ICON_COLOR }}
        />
        <p>{subNavigation.label}</p>
      </NavLink>
    </Button>
  );
};

export default SubSubItem;
