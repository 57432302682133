export const DOWNLOAD_INVOICE_START = 'DOWNLOAD_INVOICE_START';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export const LOGOUT = 'LOGOUT';

export type InvoiceActionTypes =
  | typeof DOWNLOAD_INVOICE_START
  | typeof DOWNLOAD_INVOICE_SUCCESS
  | typeof DOWNLOAD_INVOICE_FAIL
  | typeof LOGOUT;
