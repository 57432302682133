import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import styles from './AvatarMenu.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as authService from '../../../../store/auth/service';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { User } from '../../../../domain/User';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { colors } from '../../../../config/constants';

export type Props = {
  onLogout: () => void;
  currentUser: User | null;
};

const AvatarMenu = ({ onLogout, currentUser }: Props) => {
  const intl = useIntl();

  const options = [
    {
      title: translate(intl, 'AVATAR_MENU.HOME_PAGE'),
      to: routes.homepage,
    },
  ];

  const userFullName =
    currentUser?.profile?.firstName && currentUser?.profile?.lastName
      ? `${currentUser?.profile?.firstName} ${currentUser?.profile?.lastName}`
      : '';

  return (
    <nav className={styles.avatarMenuContainer}>
      <div className={styles.infoContainer}>
        <h2 className={styles.infoName}>{userFullName}</h2>
        <p className={styles.infoEmail}>{currentUser?.email ?? ''}</p>
      </div>
      <ul className={styles.optionContainer}>
        {options.map((option) => (
          <li key={option.title + option.to}>
            <NavLink to={option.to}>
              <Button variant="text" color="primary" sx={{ fontWeight: '400' }}>
                {option.title}
              </Button>
            </NavLink>
          </li>
        ))}
      </ul>
      <div className={styles.logoutContainer}>
        <Button
          onClick={() => onLogout()}
          variant="text"
          color="secondary"
          sx={{ fontWeight: '700', color: colors.accentColor }}
        >
          {translate(intl, 'AVATAR_MENU.LOGOUT')}
        </Button>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvatarMenu);
