import React, { useState } from 'react';
import styles from './MinimizedMenuSubItemButton.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { NavigationItem } from '../../../Layout/Layout';
import { Icon } from '@iconify/react';
import MinimizedSubSubItem from '../MinimizedSubSubItem/MinimizedSubSubItem';
import { Button, Popover } from '@mui/material';

type Props = {
  navItem: NavigationItem;
};

const ICON_SIZE = '1rem';

const MinimizedMenuSubItemButton = ({ navItem }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasItems =
    navItem?.subNavigations && navItem?.subNavigations?.length >= 1;

  if (hasItems) {
    const isActive = window.location.href.includes(navItem.to);

    return (
      <div className={styles.subItemContainer}>
        <Button sx={{ padding: 0 }}>
          <NavLink to={navItem.to} className={styles.navButton}>
            <div
              className={cx(styles.subButton, {
                [styles.activeSubItem]: isActive,
                [styles.withItems]: true,
              })}
              onMouseEnter={handleClick}
            >
              <NavLink to={navItem.to} className={styles.navButton}>
                <div className={styles.detailsContainer}>
                  {navItem.icon}
                  <div className={styles.navigationText}>{navItem.label}</div>
                </div>
                <Icon
                  className={cx(styles.subButtonIcon)}
                  icon={'solar:alt-arrow-right-linear'}
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                />
              </NavLink>
            </div>
            <Popover
              className={styles.popover}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 10,
                horizontal: 60,
              }}
            >
              {navItem?.subNavigations?.map((subNavigation) => (
                <MinimizedSubSubItem
                  key={subNavigation.label + subNavigation.to}
                  subNavigation={subNavigation}
                  onClose={handleClose}
                />
              ))}
            </Popover>
          </NavLink>
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.subItemContainer}>
      <Button sx={{ padding: 0 }}>
        <NavLink
          to={navItem.to}
          className={({ isActive }) =>
            cx(styles.subButton, {
              [styles.activeSubItem]: isActive,
            })
          }
        >
          <div className={styles.detailsContainer}>
            {navItem.icon}
            <div className={styles.navigationText}>{navItem.label}</div>
          </div>
        </NavLink>
      </Button>
    </div>
  );
};

export default MinimizedMenuSubItemButton;
