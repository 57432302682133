export const FETCH_ALL_REWARDS_START = 'FETCH_ALL_REWARDS_START';
export const FETCH_ALL_REWARDS_SUCCESS = 'FETCH_ALL_REWARDS_SUCCESS';
export const FETCH_ALL_REWARDS_FAIL = 'FETCH_ALL_REWARDS_FAIL';

export const RESET_REWARD_STORE = 'RESET_REWARD_STORE';

export const LOGOUT = 'LOGOUT';

export type RewardActionTypes =
  | typeof FETCH_ALL_REWARDS_START
  | typeof FETCH_ALL_REWARDS_SUCCESS
  | typeof FETCH_ALL_REWARDS_FAIL
  | typeof RESET_REWARD_STORE
  | typeof LOGOUT;
