import React from 'react';
import { Button } from '@mui/material';
import styles from './PasswordRemindSuccess.module.scss';
import successIcon from '../../../../../assets/icons/success-circle.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';

type Props = {
  onClose: () => void;
};

const PasswordRemindSuccess = ({ onClose }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div>
        <img src={successIcon} />
      </div>
      <h3>{translate(intl, 'PASSWORD_REMIND_SUCCESS.TITLE')}</h3>
      <p>{translate(intl, 'PASSWORD_REMIND_SUCCESS.SUBTITLE')}</p>
      <div>
        <Button variant="contained" onClick={() => onClose()}>
          {translate(intl, 'PASSWORD_REMIND_SUCCESS.BUTTON_CLOSE')}
        </Button>
      </div>
    </div>
  );
};

export default PasswordRemindSuccess;
