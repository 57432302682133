import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ed58b724782b9b1f2c98863e15aad73f@o1101437.ingest.sentry.io/4506717205364736',
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'system',
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

reportWebVitals();
