import styles from './MobileMenuItem.module.scss';
import arrowIcon from '../../../../assets/icons/mobile-menu-arrow.svg';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';

type Props = {
  label: string;
  isFirst: boolean;
  onClick: () => void;
  icon?: string;
};

const MobileMenuItem = ({ label, isFirst, onClick, icon }: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(styles.mobileMenuItem, {
        [styles.isFirst]: isFirst,
      })}
    >
      <div className={styles.labelContainer}>
        {icon && <ReactSVG src={icon} />}
        <p>{label}</p>
      </div>
      <div>
        <img src={arrowIcon} />
      </div>
    </div>
  );
};

export default MobileMenuItem;
