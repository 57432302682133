export const PURCHASE_PRODUCT_PROMOTION_START =
  'PURCHASE_PRODUCT_PROMOTION_START';
export const PURCHASE_PRODUCT_PROMOTION_SUCCESS =
  'PURCHASE_PRODUCT_PROMOTION_SUCCESS';
export const PURCHASE_PRODUCT_PROMOTION_FAIL =
  'PURCHASE_PRODUCT_PROMOTION_FAIL';
export const RESET_PURCHASE_PRODUCT_PROMOTION =
  'RESET_PURCHASE_PRODUCT_PROMOTION';

export const RESET_PRODUCT_PROMOTION_STORE = 'RESET_PRODUCT_STORE';

export const LOGOUT = 'LOGOUT';

export type ProductPromotionActionTypes =
  | typeof PURCHASE_PRODUCT_PROMOTION_START
  | typeof PURCHASE_PRODUCT_PROMOTION_SUCCESS
  | typeof PURCHASE_PRODUCT_PROMOTION_FAIL
  | typeof RESET_PRODUCT_PROMOTION_STORE
  | typeof RESET_PURCHASE_PRODUCT_PROMOTION
  | typeof LOGOUT;
