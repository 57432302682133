import React, { ReactNode } from 'react';
import styles from './ListInfoWrapper.module.scss';
import cx from 'classnames';

export type Props = {
  children?: ReactNode;
  addSpacing?: boolean;
};

const ListInfoWrapper = ({ children, addSpacing }: Props) => (
  <div
    className={cx(styles.infoContainer, {
      [styles.additionalSpacing]: addSpacing,
    })}
  >
    {children}
  </div>
);

export default ListInfoWrapper;
