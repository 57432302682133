import { Button } from '@mui/material';
import styles from './TopUpCardFail.module.scss';
import failIcon from '../../../../../assets/icons/fail-icon.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';

type Props = {
  onClose: () => void;
};

const TopUpCardFail = ({ onClose }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div>
        <img src={failIcon} />
      </div>
      <h3>{translate(intl, 'TOP_UP_CARD_FAIL.TITLE')}</h3>
      <p>{translate(intl, 'TOP_UP_CARD_FAIL.SUBTITLE')}</p>
      <div>
        <Button
          variant="contained"
          onClick={() => onClose()}
          className={styles.button}
        >
          {translate(intl, 'TOP_UP_CARD_FAIL.BUTTON_CLOSE')}
        </Button>
      </div>
    </div>
  );
};

export default TopUpCardFail;
