import * as actionTypes from './actionTypes';
import { FaqCategoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { FaqCategory } from '../../domain/FaqCategory';

export type FaqCategoryStateType = {
  faqCategoriesList: ListResults<FaqCategory> | null;
  faqCategoriesListLoading: boolean;
  faqCategoriesListError: HttpError;
  faqCategoriesListUpdateNeeded: boolean;
  faqCategoryCreateLoading: boolean;
  faqCategoryCreateError: HttpError;
  faqCategoryCreateSuccess: boolean;
  createdFaqCategory: FaqCategory | null;
  faqCategoryDeleteLoading: boolean;
  faqCategoryDeleteError: HttpError;
  faqCategoryDeleteSuccess: boolean;
  publicFaqCategories: FaqCategory[];
  publicFaqCategoriesLoading: boolean;
  publicFaqCategoriesError: HttpError;
};

export type FaqCategoryActionType = FaqCategoryStateType & {
  type: FaqCategoryActionTypes;
};

export const initialState: FaqCategoryStateType = {
  faqCategoriesList: null,
  faqCategoriesListLoading: true,
  faqCategoriesListError: null,
  faqCategoriesListUpdateNeeded: true,
  faqCategoryCreateLoading: false,
  faqCategoryCreateError: null,
  faqCategoryCreateSuccess: false,
  createdFaqCategory: null,
  faqCategoryDeleteLoading: false,
  faqCategoryDeleteError: null,
  faqCategoryDeleteSuccess: false,
  publicFaqCategories: [],
  publicFaqCategoriesLoading: false,
  publicFaqCategoriesError: null,
};

const fetchCategoriesListStart = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoriesListLoading: true,
});

const fetchCategoriesListSuccess = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoriesListLoading: false,
  faqCategoriesList: action.faqCategoriesList,
  faqCategoriesListUpdateNeeded: false,
});

const fetchCategoriesListFail = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoriesListLoading: false,
  faqCategoriesListError: action.faqCategoriesListError,
});

const createFaqCategoryStart = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryCreateLoading: true,
});

const createFaqCategorySuccess = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  createdFaqCategory: action.createdFaqCategory,
  faqCategoryCreateLoading: false,
  faqCategoryCreateError: null,
  faqCategoryCreateSuccess: true,
  faqCategoriesListUpdateNeeded: true,
});

const createFaqCategoryFail = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryCreateLoading: false,
  faqCategoryCreateError: action.faqCategoryCreateError,
});

const deleteFaqCategoryStart = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryDeleteLoading: true,
});

const deleteFaqCategorySuccess = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryDeleteLoading: false,
  faqCategoryDeleteSuccess: true,
  faqCategoriesListUpdateNeeded: true,
});

const deleteFaqCategoryFail = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryDeleteLoading: false,
  faqCategoryDeleteError: action.faqCategoryDeleteError,
});

const resetCreatedFaqCategoryStore = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  createdFaqCategory: null,
  faqCategoryCreateLoading: false,
  faqCategoryCreateError: null,
});

const resetDeletedFaqCategoryStore = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  faqCategoryDeleteLoading: false,
  faqCategoryDeleteSuccess: false,
  faqCategoryCreateError: null,
});

const fetchPublicCategoriesStart = (
  state: FaqCategoryStateType,
): FaqCategoryStateType => ({
  ...state,
  publicFaqCategoriesLoading: true,
});

const fetchPublicCategoriesSuccess = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  publicFaqCategoriesLoading: false,
  publicFaqCategories: action.publicFaqCategories,
});

const fetchPublicCategoriesFail = (
  state: FaqCategoryStateType,
  action: FaqCategoryActionType,
): FaqCategoryStateType => ({
  ...state,
  publicFaqCategoriesLoading: false,
  publicFaqCategoriesError: action.publicFaqCategoriesError,
});

const resetFaqCategoryStore = (): FaqCategoryStateType => ({
  ...initialState,
});

const completelyResetFaqCategoryStore = (): FaqCategoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: FaqCategoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_FAQ_CATEGORIES_LIST_START:
      return fetchCategoriesListStart(state);
    case actionTypes.FETCH_FAQ_CATEGORIES_LIST_SUCCESS:
      return fetchCategoriesListSuccess(state, action);
    case actionTypes.FETCH_FAQ_CATEGORIES_LIST_FAIL:
      return fetchCategoriesListFail(state, action);
    case actionTypes.CREATE_FAQ_CATEGORY_START:
      return createFaqCategoryStart(state);
    case actionTypes.CREATE_FAQ_CATEGORY_SUCCESS:
      return createFaqCategorySuccess(state, action);
    case actionTypes.CREATE_FAQ_CATEGORY_FAIL:
      return createFaqCategoryFail(state, action);
    case actionTypes.DELETE_FAQ_CATEGORY_START:
      return deleteFaqCategoryStart(state);
    case actionTypes.DELETE_FAQ_CATEGORY_SUCCESS:
      return deleteFaqCategorySuccess(state);
    case actionTypes.DELETE_FAQ_CATEGORY_FAIL:
      return deleteFaqCategoryFail(state, action);
    case actionTypes.FETCH_PUBLIC_FAQ_CATEGORY_START:
      return fetchPublicCategoriesStart(state);
    case actionTypes.FETCH_PUBLIC_FAQ_CATEGORY_SUCCESS:
      return fetchPublicCategoriesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_FAQ_CATEGORY_FAIL:
      return fetchPublicCategoriesFail(state, action);
    case actionTypes.RESET_CREATED_FAQ_CATEGORY_STORE:
      return resetCreatedFaqCategoryStore(state);
    case actionTypes.RESET_DELETED_FAQ_CATEGORY_STORE:
      return resetDeletedFaqCategoryStore(state);
    case actionTypes.RESET_FAQ_CATEGORY_STORE:
      return resetFaqCategoryStore();
    case actionTypes.LOGOUT:
      return completelyResetFaqCategoryStore();
    default:
      return state;
  }
};

export default reducer;
