import { EntityTranslation } from './EntityTranslation';

export interface StaticPage {
  id: number;
  name: string;
  content: string;
  slug: string;
  translations: EntityTranslation[];
}

export enum DefaultStaticPages {
  // eslint-disable-next-line no-unused-vars
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  // eslint-disable-next-line no-unused-vars
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  // eslint-disable-next-line no-unused-vars
  REFUND_POLICY = 'REFUND_POLICY',
  // eslint-disable-next-line no-unused-vars
  AFFILIATE = 'AFFILIATE',
  // eslint-disable-next-line no-unused-vars
  EARN_MONEY = 'EARN_MONEY',
  // eslint-disable-next-line no-unused-vars
  ABOUT_US = 'ABOUT_US',
  // eslint-disable-next-line no-unused-vars
  GENERAL_INFO = 'GENERAL_INFO',
  // eslint-disable-next-line no-unused-vars
  CONTACT_US = 'CONTACT_US',
  // eslint-disable-next-line no-unused-vars
  SHIPPING_POLICY = 'SHIPPING_POLICY',
}
