import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PublicSearchResults } from '../../domain/Search';

export const fetchPublicSearchResultsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_START,
});

export const fetchPublicSearchResultsSuccess = (
  publicSearchResults: PublicSearchResults | null,
) => ({
  type: actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_SUCCESS,
  publicSearchResults,
});

export const fetchPublicSearchResultsFail = (
  publicSearchResultsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_FAIL,
  publicSearchResultsError,
});

export const resetSearchStore = () => ({
  type: actionTypes.RESET_SEARCH_STORE,
});
