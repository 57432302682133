export const FETCH_THEMES_LIST_START = 'FETCH_THEMES_LIST_START';
export const FETCH_THEMES_LIST_SUCCESS = 'FETCH_THEMES_LIST_SUCCESS';
export const FETCH_THEMES_LIST_FAIL = 'FETCH_THEMES_LIST_FAIL';

export const FETCH_ALL_FILTER_THEMES_START = 'FETCH_ALL_FILTER_THEMES_START';
export const FETCH_ALL_FILTER_THEMES_SUCCESS =
  'FETCH_ALL_FILTER_THEMES_SUCCESS';
export const FETCH_ALL_FILTER_THEMES_FAIL = 'FETCH_ALL_FILTER_THEMES_FAIL';

export const INITIATE_THEMES_FETCH_START = 'INITIATE_THEMES_FETCH_START';
export const INITIATE_THEMES_FETCH_SUCCESS = 'INITIATE_THEMES_FETCH_SUCCESS';
export const INITIATE_THEMES_FETCH_FAIL = 'INITIATE_THEMES_FETCH_FAIL';

export const CREATE_THEME_START = 'CREATE_THEME_START';
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_FAIL = 'CREATE_THEME_FAIL';

export const FETCH_ALL_THEMES_START = 'FETCH_ALL_THEMES_START';
export const FETCH_ALL_THEMES_SUCCESS = 'FETCH_ALL_THEMES_SUCCESS';
export const FETCH_ALL_THEMES_FAIL = 'FETCH_ALL_THEMES_FAIL';

export const UPDATE_THEME_START = 'UPDATE_THEME_START';
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS';
export const UPDATE_THEME_FAIL = 'UPDATE_THEME_FAIL';

export const DELETE_THEME_START = 'DELETE_THEME_START';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_FAIL = 'DELETE_THEME_FAIL';

export const RESET_CREATED_THEME_STORE = 'RESET_CREATED_THEME_STORE';
export const RESET_THEME_STORE = 'RESET_THEME_STORE';

export const LOGOUT = 'LOGOUT';

export type ThemeActionTypes =
  | typeof FETCH_THEMES_LIST_START
  | typeof FETCH_THEMES_LIST_SUCCESS
  | typeof FETCH_THEMES_LIST_FAIL
  | typeof FETCH_ALL_FILTER_THEMES_START
  | typeof FETCH_ALL_FILTER_THEMES_SUCCESS
  | typeof FETCH_ALL_FILTER_THEMES_FAIL
  | typeof INITIATE_THEMES_FETCH_START
  | typeof INITIATE_THEMES_FETCH_SUCCESS
  | typeof INITIATE_THEMES_FETCH_FAIL
  | typeof FETCH_ALL_THEMES_START
  | typeof FETCH_ALL_THEMES_SUCCESS
  | typeof FETCH_ALL_THEMES_FAIL
  | typeof CREATE_THEME_START
  | typeof CREATE_THEME_SUCCESS
  | typeof CREATE_THEME_FAIL
  | typeof UPDATE_THEME_START
  | typeof UPDATE_THEME_SUCCESS
  | typeof UPDATE_THEME_FAIL
  | typeof DELETE_THEME_START
  | typeof DELETE_THEME_SUCCESS
  | typeof DELETE_THEME_FAIL
  | typeof RESET_CREATED_THEME_STORE
  | typeof RESET_THEME_STORE
  | typeof LOGOUT;
