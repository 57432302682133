import { Asset } from './Asset';
import { InvitedUserStatus } from './User';

export interface Profile {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  profileImage?: Asset;
  description?: string;
  businessName?: string;
  registrationCode?: string;
  address?: string;
  vatNumber?: string;
  country?: string;
  bricks: number;
  studs: number;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  tiktok?: string;
  youtube?: string;
  instagram?: string;
  refCode?: string;
  status?: InvitedUserStatus;
  refUserEarnings: number;
  affiliateEarnings: number;
  bricksNotification: BricksNotification | null;
}

export enum BricksNotification {
  // eslint-disable-next-line no-unused-vars
  SIGN_UP = 'SIGN_UP',
  // eslint-disable-next-line no-unused-vars
  FIRST_PUBLISH = 'FIRST_PUBLISH',
  // eslint-disable-next-line no-unused-vars
  FIRST_REVIEW = 'FIRST_REVIEW',
  // eslint-disable-next-line no-unused-vars
  FIRST_LIKE = 'FIRST_LIKE',
  // eslint-disable-next-line no-unused-vars
  FIRST_AFFILIATE_REGISTRATION = 'FIRST_AFFILIATE_REGISTRATION',
}
