import * as actionTypes from './actionTypes';
import { ProductReviewActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import {
  ProductReview,
  ProductReviewsStatistics,
} from '../../domain/ProductReview';

export type ProductReviewStateType = {
  productReviewList: ListResults<ProductReview> | null;
  productReviewListLoading: boolean;
  productReviewListError: HttpError;
  productReviewListUpdateNeeded: boolean;
  publicProductReviewList: ListResults<ProductReview> | null;
  publicProductReviewListLoading: boolean;
  publicProductReviewListError: HttpError;
  publicProductReviewListUpdateNeeded: boolean;
  productReviewCreateLoading: boolean;
  productReviewCreateError: HttpError;
  productReviewCreateSuccess: boolean;
  createdProductReview: ProductReview | null;
  productReviewDeleteLoading: boolean;
  productReviewDeleteError: HttpError;
  productReviewDeleteSuccess: boolean;
  publicUserReviewList: ListResults<ProductReview> | null;
  publicUserReviewListLoading: boolean;
  publicUserReviewListError: HttpError;
  productReviewUpdateLoading: boolean;
  productReviewUpdateError: HttpError;
  productReviewUpdateSuccess: boolean;
  adminProductReviewUpdateLoading: boolean;
  adminProductReviewUpdateError: HttpError;
  adminProductReviewUpdateSuccess: boolean;
  publicTopRatedProductReviewsLoading: boolean;
  publicTopRatedProductReviewsError: HttpError;
  publicTopRatedProductReviews: ProductReview[];
  productReviewsStatisticsLoading: boolean;
  productReviewsStatisticsError: HttpError;
  productReviewsStatistics: ProductReviewsStatistics | null;
};

export type ProductReviewActionType = ProductReviewStateType & {
  type: ProductReviewActionTypes;
};

export const initialState: ProductReviewStateType = {
  productReviewList: null,
  productReviewListLoading: true,
  productReviewListError: null,
  productReviewListUpdateNeeded: false,
  publicProductReviewList: null,
  publicProductReviewListLoading: true,
  publicProductReviewListError: null,
  publicProductReviewListUpdateNeeded: false,
  productReviewCreateLoading: false,
  productReviewCreateError: null,
  productReviewCreateSuccess: false,
  createdProductReview: null,
  productReviewDeleteLoading: false,
  productReviewDeleteError: null,
  productReviewDeleteSuccess: false,
  publicUserReviewList: null,
  publicUserReviewListLoading: true,
  publicUserReviewListError: null,
  productReviewUpdateLoading: false,
  productReviewUpdateError: null,
  productReviewUpdateSuccess: false,
  adminProductReviewUpdateLoading: false,
  adminProductReviewUpdateError: null,
  adminProductReviewUpdateSuccess: false,
  publicTopRatedProductReviewsLoading: false,
  publicTopRatedProductReviewsError: null,
  publicTopRatedProductReviews: [],
  productReviewsStatisticsLoading: false,
  productReviewsStatisticsError: null,
  productReviewsStatistics: null,
};

const fetchProductReviewListStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewListLoading: true,
});

const fetchProductReviewListSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewListLoading: false,
  productReviewList: action.productReviewList,
  productReviewListUpdateNeeded: false,
});

const fetchProductReviewListFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewListLoading: false,
  productReviewListError: action.productReviewListError,
});

const fetchPublicProductReviewListStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  publicProductReviewListLoading: true,
});

const fetchPublicProductReviewListSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicProductReviewListLoading: false,
  publicProductReviewList: action.publicProductReviewList,
  publicProductReviewListUpdateNeeded: false,
});

const fetchPublicProductReviewListFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicProductReviewListLoading: false,
  publicProductReviewListError: action.publicProductReviewListError,
});

const fetchPublicUserReviewListStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  publicUserReviewListLoading: true,
});

const fetchPublicUserReviewListSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicUserReviewListLoading: false,
  publicUserReviewList: action.publicUserReviewList,
});

const fetchPublicUserReviewListFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicUserReviewListLoading: false,
  publicUserReviewListError: action.publicUserReviewListError,
});

const createProductReviewStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewCreateLoading: true,
});

const createProductReviewSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewCreateLoading: false,
  productReviewCreateError: null,
  productReviewCreateSuccess: true,
  createdProductReview: action.createdProductReview,
  publicProductReviewListUpdateNeeded: true,
});

const createProductReviewFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewCreateLoading: false,
  productReviewCreateError: action.productReviewCreateError,
});

const deleteProductReviewStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewDeleteLoading: true,
});

const deleteProductReviewSuccess = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewDeleteLoading: false,
  productReviewDeleteSuccess: true,
  productReviewListUpdateNeeded: true,
});

const deleteProductReviewFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewDeleteLoading: false,
  productReviewDeleteError: action.productReviewDeleteError,
});

const updateProductReviewStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewUpdateLoading: true,
});

const updateProductReviewSuccess = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewUpdateLoading: false,
  productReviewUpdateError: null,
  productReviewUpdateSuccess: true,
});

const updateProductReviewFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewUpdateLoading: false,
  productReviewUpdateError: action.productReviewUpdateError,
});

const updateAdminProductReviewStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  adminProductReviewUpdateLoading: true,
});

const updateAdminProductReviewSuccess = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  adminProductReviewUpdateLoading: false,
  adminProductReviewUpdateError: null,
  adminProductReviewUpdateSuccess: true,
  productReviewListUpdateNeeded: true,
});

const updateAdminProductReviewFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  adminProductReviewUpdateLoading: false,
  adminProductReviewUpdateError: action.adminProductReviewUpdateError,
});

const fetchPublicTopRatedProductReviewsStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  publicTopRatedProductReviewsLoading: true,
});

const fetchPublicTopRatedProductReviewsSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicTopRatedProductReviewsLoading: false,
  publicTopRatedProductReviews: action.publicTopRatedProductReviews,
});

const fetchPublicTopRatedProductReviewsFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  publicTopRatedProductReviewsLoading: false,
  publicTopRatedProductReviewsError: action.publicTopRatedProductReviewsError,
});

const fetchPublicReviewStatisticsStart = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewsStatisticsLoading: true,
});

const fetchPublicReviewStatisticsSuccess = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewsStatisticsLoading: false,
  productReviewsStatistics: action.productReviewsStatistics,
});

const fetchPublicReviewStatisticsFail = (
  state: ProductReviewStateType,
  action: ProductReviewActionType,
): ProductReviewStateType => ({
  ...state,
  productReviewsStatisticsLoading: false,
  productReviewsStatisticsError: action.productReviewsStatisticsError,
});

const resetDeleteProductReview = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewDeleteLoading: false,
  productReviewDeleteSuccess: false,
  productReviewListUpdateNeeded: false,
  productReviewDeleteError: null,
});

const resetCreatedOrUpdatedProductReview = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  productReviewCreateLoading: false,
  productReviewCreateError: null,
  productReviewCreateSuccess: false,
  productReviewUpdateLoading: false,
  productReviewUpdateError: null,
  productReviewUpdateSuccess: false,
  createdProductReview: null,
});

const resetAdminUpdateProductReviewStore = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...state,
  adminProductReviewUpdateLoading: false,
  adminProductReviewUpdateError: null,
  adminProductReviewUpdateSuccess: false,
});

const resetProductReviewStore = (): ProductReviewStateType => ({
  ...initialState,
});

const completelyResetProductReviewStore = (
  state: ProductReviewStateType,
): ProductReviewStateType => ({
  ...initialState,
  publicProductReviewList: state.publicProductReviewList,
});

const reducer = (state = initialState, action: ProductReviewActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_REVIEW_LIST_START:
      return fetchProductReviewListStart(state);
    case actionTypes.FETCH_PRODUCT_REVIEW_LIST_SUCCESS:
      return fetchProductReviewListSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_REVIEW_LIST_FAIL:
      return fetchProductReviewListFail(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCT_REVIEW_LIST_START:
      return fetchPublicProductReviewListStart(state);
    case actionTypes.FETCH_PUBLIC_PRODUCT_REVIEW_LIST_SUCCESS:
      return fetchPublicProductReviewListSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCT_REVIEW_LIST_FAIL:
      return fetchPublicProductReviewListFail(state, action);
    case actionTypes.FETCH_PUBLIC_USER_REVIEW_LIST_START:
      return fetchPublicUserReviewListStart(state);
    case actionTypes.FETCH_PUBLIC_USER_REVIEW_LIST_SUCCESS:
      return fetchPublicUserReviewListSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_USER_REVIEW_LIST_FAIL:
      return fetchPublicUserReviewListFail(state, action);
    case actionTypes.CREATE_PRODUCT_REVIEW_START:
      return createProductReviewStart(state);
    case actionTypes.CREATE_PRODUCT_REVIEW_SUCCESS:
      return createProductReviewSuccess(state, action);
    case actionTypes.CREATE_PRODUCT_REVIEW_FAIL:
      return createProductReviewFail(state, action);
    case actionTypes.UPDATE_PRODUCT_REVIEW_START:
      return updateProductReviewStart(state);
    case actionTypes.UPDATE_PRODUCT_REVIEW_SUCCESS:
      return updateProductReviewSuccess(state);
    case actionTypes.UPDATE_PRODUCT_REVIEW_FAIL:
      return updateProductReviewFail(state, action);
    case actionTypes.UPDATE_ADMIN_PRODUCT_REVIEW_START:
      return updateAdminProductReviewStart(state);
    case actionTypes.UPDATE_ADMIN_PRODUCT_REVIEW_SUCCESS:
      return updateAdminProductReviewSuccess(state);
    case actionTypes.UPDATE_ADMIN_PRODUCT_REVIEW_FAIL:
      return updateAdminProductReviewFail(state, action);
    case actionTypes.RESET_CREATED_PRODUCT_REVIEW_STORE:
      return resetCreatedOrUpdatedProductReview(state);
    case actionTypes.RESET_ADMIN_UPDATE_PRODUCT_REVIEW_STORE:
      return resetAdminUpdateProductReviewStore(state);
    case actionTypes.DELETE_PRODUCT_REVIEW_START:
      return deleteProductReviewStart(state);
    case actionTypes.DELETE_PRODUCT_REVIEW_SUCCESS:
      return deleteProductReviewSuccess(state);
    case actionTypes.DELETE_PRODUCT_REVIEW_FAIL:
      return deleteProductReviewFail(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_RATED_REVIEWS_START:
      return fetchPublicTopRatedProductReviewsStart(state);
    case actionTypes.FETCH_PUBLIC_TOP_RATED_REVIEWS_SUCCESS:
      return fetchPublicTopRatedProductReviewsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_RATED_REVIEWS_FAIL:
      return fetchPublicTopRatedProductReviewsFail(state, action);
    case actionTypes.FETCH_PUBLIC_REVIEW_STATISTICS_START:
      return fetchPublicReviewStatisticsStart(state);
    case actionTypes.FETCH_PUBLIC_REVIEW_STATISTICS_SUCCESS:
      return fetchPublicReviewStatisticsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_REVIEW_STATISTICS_FAIL:
      return fetchPublicReviewStatisticsFail(state, action);
    case actionTypes.RESET_DELETE_PRODUCT_REVIEW:
      return resetDeleteProductReview(state);
    case actionTypes.RESET_PRODUCT_REVIEW_STORE:
      return resetProductReviewStore();
    case actionTypes.LOGOUT:
      return completelyResetProductReviewStore(state);
    default:
      return state;
  }
};

export default reducer;
