import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createTopBarHighlightFail,
  createTopBarHighlightStart,
  createTopBarHighlightSuccess,
  deleteTopBarHighlightFail,
  deleteTopBarHighlightStart,
  deleteTopBarHighlightSuccess,
  fetchTopBarHighlightsListFail,
  fetchTopBarHighlightsListStart,
  fetchTopBarHighlightsListSuccess,
  fetchEnabledTopBarHighlightsFail,
  fetchEnabledTopBarHighlightsStart,
  fetchEnabledTopBarHighlightsSuccess,
  updateTopBarHighlightFail,
  updateTopBarHighlightStart,
  updateTopBarHighlightSuccess,
  viewTopBarHighlightStart,
  viewTopBarHighlightSuccess,
  viewTopBarHighlightFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';

const API_URL = '/top-bar-highlights';

export type TopBarHighlightCreateRequest = {
  title: string;
  shortTitle: string;
  promotionName: string;
  ctaText: string;
  ctaLink: string;
  isNewTab: boolean;
  isEnabled: boolean;
  type: string;
  content: string;
  videoUrl: string;
  image: File;
};

export type TopBarHighlightUpdateRequest = {
  id: number;
  title: string;
  shortTitle: string;
  promotionName: string;
  ctaText: string;
  ctaLink: string;
  isNewTab: boolean;
  isEnabled: boolean;
  type: string;
  content: string;
  videoUrl: string;
  image: File;
};

export type TopBarHighlightViewRequest = {
  id: number;
};

export const fetchTopBarHighlightsList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchTopBarHighlightsListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchTopBarHighlightsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchTopBarHighlightsListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchEnabledTopBarHighlights = () => (dispatch: Dispatch) => {
  dispatch(fetchEnabledTopBarHighlightsStart());
  return axios
    .get(`${API_URL}/enabled`)
    .then((response) => {
      dispatch(fetchEnabledTopBarHighlightsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchEnabledTopBarHighlightsFail(err?.response?.data?.message));
    });
};

export const createTopBarHighlight =
  (inputs: TopBarHighlightCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createTopBarHighlightStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createTopBarHighlightSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.TOP_BAR_HIGHLIGHT_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(
          createTopBarHighlightFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
      });
  };

export const viewTopBarHighlight =
  (inputs: TopBarHighlightViewRequest) => (dispatch: Dispatch) => {
    dispatch(viewTopBarHighlightStart());
    return axios
      .post(`${API_URL}/view`, { ...inputs })
      .then((response) => {
        dispatch(viewTopBarHighlightSuccess());
      })
      .catch((err) => {
        dispatch(viewTopBarHighlightFail());
      });
  };

export const updateTopBarHighlight =
  (inputs: TopBarHighlightUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateTopBarHighlightStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateTopBarHighlightSuccess());
        showToast(
          translate(intl, 'SUCCESS.TOP_BAR_HIGHLIGHT_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateTopBarHighlightFail(err?.response?.data?.message));
      });
  };

export const deleteTopBarHighlight =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteTopBarHighlightStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteTopBarHighlightSuccess());
        showToast(
          translate(intl, 'SUCCESS.TOP_BAR_HIGHLIGHT_DELETE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteTopBarHighlightFail(err?.response?.data?.message));
      });
  };
