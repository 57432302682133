import { useIntl } from 'react-intl';
import styles from './AffiliateList.module.scss';
import { translate } from 'src/utility/messageTranslator/translate';
import useWindowSize from 'src/hooks/useWindowSize/useWindowSize';
import { InvitedUserStatus, User } from '../../../../../domain/User';
import Table, { TableHeader } from '../../../../../common/Table/Table';
import moment from 'moment';
import { NO_INFO } from '../../../../../config/constants';
import cx from 'classnames';
import logo from '../../../../../assets/logo-2.svg';
import { ReactSVG } from 'react-svg';
import ListInfoWrapper from '../../../../../common/ListInfoWrapper/ListInfoWrapper';

export type Props = {
  invitedUsersList: User[];
};

const MOBILE_BREAK_POINT = 800;

export const AffiliateList = ({ invitedUsersList }: Props) => {
  const { width } = useWindowSize();
  const intl = useIntl();

  const TABLE_HEADERS: Array<TableHeader> = [
    {
      sortBy: '',
      label: translate(intl, 'AFFILIATE_LIST_HEADER.USERNAME'),
    },
    {
      sortBy: '',
      label: translate(intl, 'AFFILIATE_LIST_HEADER.DATE_REGISTERED'),
    },
    {
      sortBy: '',
      label: translate(intl, 'AFFILIATE_LIST_HEADER.STATUS'),
    },
    {
      sortBy: '',
      label: translate(intl, 'AFFILIATE_LIST_HEADER.EARNINGS'),
    },
  ];

  const getStatus = (user: User) => {
    if (!user.profile || !user.profile.status) {
      return NO_INFO;
    }

    const isGreyLabel = user.profile.status === InvitedUserStatus.REGISTERED;

    return (
      <div
        className={cx(styles.status, { [styles.grey]: isGreyLabel })}
      >{`${translate(
        intl,
        `INVITED_USER.STATUS_${user.profile.status}`,
      )}`}</div>
    );
  };

  const getEarningsLabel = (user: User) => {
    if (!user || !user.profile) {
      return NO_INFO;
    }
    return (
      <div className={styles.earningLabel}>
        <ReactSVG src={logo} /> {user.profile.refUserEarnings}
      </div>
    );
  };

  const mapItems = () => {
    if (invitedUsersList.length === 0) {
      return;
    }

    if (width && width >= MOBILE_BREAK_POINT) {
      return invitedUsersList.map((user: User) => {
        return {
          username: user.username || NO_INFO,
          dateRegistered: moment(user.createdAt).format('YYYY-MM-DD'),
          status: getStatus(user),
          earning: getEarningsLabel(user),
        };
      });
    }

    return invitedUsersList.map((user: User) => {
      return {
        info: (
          <ListInfoWrapper addSpacing>
            <div>{user.username || NO_INFO}</div>
            <div>{moment(user.createdAt).format('YYYY-MM-DD')}</div>
            <div>{getStatus(user)}</div>
            <div>{getEarningsLabel(user)}</div>
          </ListInfoWrapper>
        ),
      };
    });
  };
  return (
    <>
      <Table
        className={styles.affiliateList}
        headers={
          width && width >= MOBILE_BREAK_POINT ? TABLE_HEADERS : undefined
        }
        rows={mapItems()}
      />
      {invitedUsersList.length === 0 && (
        <div className={styles.affiliateListEmptyList}>
          <p>{translate(intl, 'LIST.NO_RESULTS')}</p>
        </div>
      )}
    </>
  );
};
