export const FETCH_PRODUCT_REVIEW_LIST_START =
  'FETCH_PRODUCT_REVIEW_LIST_START';
export const FETCH_PRODUCT_REVIEW_LIST_SUCCESS =
  'FETCH_PRODUCT_REVIEW_LIST_SUCCESS';
export const FETCH_PRODUCT_REVIEW_LIST_FAIL = 'FETCH_PRODUCT_REVIEW_LIST_FAIL';

export const FETCH_PUBLIC_PRODUCT_REVIEW_LIST_START =
  'FETCH_PUBLIC_PRODUCT_REVIEW_LIST_START';
export const FETCH_PUBLIC_PRODUCT_REVIEW_LIST_SUCCESS =
  'FETCH_PUBLIC_PRODUCT_REVIEW_LIST_SUCCESS';
export const FETCH_PUBLIC_PRODUCT_REVIEW_LIST_FAIL =
  'FETCH_PUBLIC_PRODUCT_REVIEW_LIST_FAIL';

export const FETCH_PUBLIC_USER_REVIEW_LIST_START =
  'FETCH_PUBLIC_USER_REVIEW_LIST_START';
export const FETCH_PUBLIC_USER_REVIEW_LIST_SUCCESS =
  'FETCH_PUBLIC_USER_REVIEW_LIST_SUCCESS';
export const FETCH_PUBLIC_USER_REVIEW_LIST_FAIL =
  'FETCH_PUBLIC_USER_REVIEW_LIST_FAIL';

export const CREATE_PRODUCT_REVIEW_START = 'CREATE_PRODUCT_REVIEW_START';
export const CREATE_PRODUCT_REVIEW_SUCCESS = 'CREATE_PRODUCT_REVIEW_SUCCESS';
export const CREATE_PRODUCT_REVIEW_FAIL = 'CREATE_PRODUCT_REVIEW_FAIL';
export const RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS =
  'RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS';

export const UPDATE_PRODUCT_REVIEW_START = 'UPDATE_PRODUCT_REVIEW_START';
export const UPDATE_PRODUCT_REVIEW_SUCCESS = 'UPDATE_PRODUCT_REVIEW_SUCCESS';
export const UPDATE_PRODUCT_REVIEW_FAIL = 'UPDATE_PRODUCT_REVIEW_FAIL';

export const UPDATE_ADMIN_PRODUCT_REVIEW_START =
  'UPDATE_ADMIN_PRODUCT_REVIEW_START';
export const UPDATE_ADMIN_PRODUCT_REVIEW_SUCCESS =
  'UPDATE_ADMIN_PRODUCT_REVIEW_SUCCESS';
export const UPDATE_ADMIN_PRODUCT_REVIEW_FAIL =
  'UPDATE_ADMIN_PRODUCT_REVIEW_FAIL';

export const FETCH_PUBLIC_TOP_RATED_REVIEWS_START =
  'FETCH_PUBLIC_TOP_RATED_REVIEWS_START';
export const FETCH_PUBLIC_TOP_RATED_REVIEWS_SUCCESS =
  'FETCH_PUBLIC_TOP_RATED_REVIEWS_SUCCESS';
export const FETCH_PUBLIC_TOP_RATED_REVIEWS_FAIL =
  'FETCH_PUBLIC_TOP_RATED_REVIEWS_FAIL';

export const DELETE_PRODUCT_REVIEW_START = 'DELETE_PRODUCT_REVIEW_START';
export const DELETE_PRODUCT_REVIEW_SUCCESS = 'DELETE_PRODUCT_REVIEW_SUCCESS';
export const DELETE_PRODUCT_REVIEW_FAIL = 'DELETE_PRODUCT_REVIEW_FAIL';
export const RESET_DELETE_PRODUCT_REVIEW = 'RESET_DELETE_PRODUCT_REVIEW';

export const FETCH_PUBLIC_REVIEW_STATISTICS_START =
  'FETCH_PUBLIC_REVIEW_STATISTICS_START';
export const FETCH_PUBLIC_REVIEW_STATISTICS_SUCCESS =
  'FETCH_PUBLIC_REVIEW_STATISTICS_SUCCESS';
export const FETCH_PUBLIC_REVIEW_STATISTICS_FAIL =
  'FETCH_PUBLIC_REVIEW_STATISTICS_FAIL';

export const RESET_CREATED_PRODUCT_REVIEW_STORE =
  'RESET_CREATED_PRODUCT_REVIEW_STORE';
export const RESET_ADMIN_UPDATE_PRODUCT_REVIEW_STORE =
  'RESET_ADMIN_UPDATE_PRODUCT_REVIEW_STORE';
export const RESET_PRODUCT_REVIEW_STORE = 'RESET_PRODUCT_REVIEW_STORE';

export const LOGOUT = 'LOGOUT';

export type ProductReviewActionTypes =
  | typeof FETCH_PRODUCT_REVIEW_LIST_START
  | typeof FETCH_PRODUCT_REVIEW_LIST_SUCCESS
  | typeof FETCH_PRODUCT_REVIEW_LIST_FAIL
  | typeof FETCH_PUBLIC_TOP_RATED_REVIEWS_START
  | typeof FETCH_PUBLIC_TOP_RATED_REVIEWS_SUCCESS
  | typeof FETCH_PUBLIC_TOP_RATED_REVIEWS_FAIL
  | typeof FETCH_PUBLIC_REVIEW_STATISTICS_START
  | typeof FETCH_PUBLIC_REVIEW_STATISTICS_SUCCESS
  | typeof FETCH_PUBLIC_REVIEW_STATISTICS_FAIL
  | typeof FETCH_PUBLIC_PRODUCT_REVIEW_LIST_START
  | typeof FETCH_PUBLIC_PRODUCT_REVIEW_LIST_SUCCESS
  | typeof FETCH_PUBLIC_PRODUCT_REVIEW_LIST_FAIL
  | typeof FETCH_PUBLIC_USER_REVIEW_LIST_START
  | typeof FETCH_PUBLIC_USER_REVIEW_LIST_SUCCESS
  | typeof FETCH_PUBLIC_USER_REVIEW_LIST_FAIL
  | typeof CREATE_PRODUCT_REVIEW_START
  | typeof CREATE_PRODUCT_REVIEW_SUCCESS
  | typeof CREATE_PRODUCT_REVIEW_FAIL
  | typeof UPDATE_PRODUCT_REVIEW_START
  | typeof UPDATE_PRODUCT_REVIEW_SUCCESS
  | typeof UPDATE_PRODUCT_REVIEW_FAIL
  | typeof UPDATE_ADMIN_PRODUCT_REVIEW_START
  | typeof UPDATE_ADMIN_PRODUCT_REVIEW_SUCCESS
  | typeof UPDATE_ADMIN_PRODUCT_REVIEW_FAIL
  | typeof DELETE_PRODUCT_REVIEW_START
  | typeof DELETE_PRODUCT_REVIEW_SUCCESS
  | typeof DELETE_PRODUCT_REVIEW_FAIL
  | typeof RESET_DELETE_PRODUCT_REVIEW
  | typeof RESET_CREATED_PRODUCT_REVIEW_STORE
  | typeof RESET_ADMIN_UPDATE_PRODUCT_REVIEW_STORE
  | typeof RESET_PRODUCT_REVIEW_STORE
  | typeof LOGOUT;
