import * as actionTypes from './actionTypes';
import { RewardActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Reward } from '../../domain/Reward';

export type RewardStateType = {
  allRewardsLoading: boolean;
  allRewards: Reward[];
  allRewardsError: HttpError;
};

export type RewardActionType = RewardStateType & {
  type: RewardActionTypes;
};

export const initialState: RewardStateType = {
  allRewardsLoading: false,
  allRewards: [],
  allRewardsError: null,
};

const fetchAllRewardsStart = (state: RewardStateType): RewardStateType => ({
  ...state,
  allRewardsLoading: true,
});

const fetchAllRewardsSuccess = (
  state: RewardStateType,
  action: RewardActionType,
): RewardStateType => ({
  ...state,
  allRewardsLoading: false,
  allRewards: action.allRewards,
  allRewardsError: null,
});

const fetchAllRewardsFail = (
  state: RewardStateType,
  action: RewardActionType,
): RewardStateType => ({
  ...state,
  allRewardsLoading: false,
  allRewardsError: action.allRewardsError,
});

const resetRewardStore = (state: RewardStateType): RewardStateType => ({
  ...initialState,
});

const completelyResetRewardStore = (): RewardStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: RewardActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_REWARDS_START:
      return fetchAllRewardsStart(state);
    case actionTypes.FETCH_ALL_REWARDS_SUCCESS:
      return fetchAllRewardsSuccess(state, action);
    case actionTypes.FETCH_ALL_REWARDS_FAIL:
      return fetchAllRewardsFail(state, action);
    case actionTypes.RESET_REWARD_STORE:
      return resetRewardStore(state);
    case actionTypes.LOGOUT:
      return completelyResetRewardStore();
    default:
      return state;
  }
};

export default reducer;
