import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  fetchPublicSearchResultsStart,
  fetchPublicSearchResultsSuccess,
  fetchPublicSearchResultsFail,
} from './actions';

const API_URL = '/search';

export type SearchPublicResultsRequest = {
  search: string;
};

export const fetchPublicSearchResults =
  (params: SearchPublicResultsRequest) => (dispatch: Dispatch) => {
    dispatch(fetchPublicSearchResultsStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPublicSearchResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicSearchResultsFail(err?.response?.data?.message));
      });
  };
