import { useEffect, useMemo, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import logo from '../../assets/logo.svg';
import LogoLink from './LogoLink/LogoLink';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import saveIcon from '../../assets/icons/save.svg';
import savedIcon from '../../assets/icons/saved.svg';
import cartIcon from '../../assets/icons/cart.svg';
import cartActiveIcon from '../../assets/icons/cart-active.svg';
import userIcon from '../../assets/icons/userIcon.svg';
import { Badge, Button, Popover } from '@mui/material';
import MobileMenu from './MobileMenu/MobileMenu';
import { routes } from '../../config/Router/routes';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import bricksIcon from '../../assets/icons/bricks.svg';
import mobileSearchIcon from '../../assets/icons/mobile-search.svg';
import Modal from '../Modal/Modal';
import cx from 'classnames';
import BalanceTopUp from '../../component/Public/BalanceTopUp/BalanceTopUp';
import closeIcon from '../../assets/icons/mobile-close.svg';
import desktopSearchIcon from '../../assets/icons/desktop-search.svg';
import { ReactSVG } from 'react-svg';
import { convertToKNotation } from '../../utility/currencyHelper/currencyHelper';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ProfileSettingsPopover from 'src/component/Public/Profile/ProfileSettingsPopover/ProfileSettingsPopover';
import { UserSettingsSections } from 'src/component/Public/Profile/ProfileSection/ProfileSection';
import PublicSearchBar from './PublicSearchBar/PublicSearchBar';
import { CartItem } from '../../domain/Cart';
import { showToast } from '../../utility/toast/toast';
import { BricksNotification } from '../../domain/Profile';
import * as profileService from '../../store/profile/service';
import * as userService from '../../store/user/service';
import * as topBarHighlightService from '../../store/top-bar-highlight/service';
import { generateParamsUrl } from '../../utility/url/urlHelper';
import { ProductFilterParams } from '../../store/product/service';
import { ProductType } from '../../domain/Product';
import { BalancePopover } from '../BalancePopover/BalancePopover';
import { InfoPopover } from '../InfoPopover/InfoPopover';
import TopBarHighlightView from './TopBarHighlightView/TopBarHighlightView';
import {
  TopBarHighlight,
  TopBarHighlightCustomType,
} from '../../domain/TopBarHighlight';
import Marquee from 'react-fast-marquee';

export const TABLET_BREAK_POINT = 1180;
export const MOBILE_BREAK_POINT = 800;

type Props = {
  currentUser: User | null;
  cartItems: CartItem[];
  onResetBricksStatus: () => void;
  onRefreshCurrentProfileUser: () => void;
  resetSuccess: boolean;
  publicProductListParams: ProductFilterParams;
  onFetchEnabledTopBarHighlights: () => void;
  enabledTopBarHighlights: TopBarHighlight[];
};

const PublicNavigation = ({
  currentUser,
  cartItems,
  onResetBricksStatus,
  onRefreshCurrentProfileUser,
  resetSuccess,
  publicProductListParams,
  onFetchEnabledTopBarHighlights,
  enabledTopBarHighlights,
}: Props) => {
  const intl = useIntl();
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    onFetchEnabledTopBarHighlights();
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const intervalRef = setInterval(() => {
      onRefreshCurrentProfileUser();
    }, 5000);

    return () => clearInterval(intervalRef);
  }, [currentUser]);

  const viableTopBarHighlights = useMemo(() => {
    return enabledTopBarHighlights.filter((topBarHighlight) => {
      if (
        topBarHighlight.customType === TopBarHighlightCustomType.INTRO_VIDEO
      ) {
        return !!currentUser;
      }

      return true;
    });
  }, [currentUser, enabledTopBarHighlights]);

  const getActiveSettings = () => {
    const currentUrl = window.location.href;

    if (currentUrl.includes(`/user/${currentUser?.username}`)) {
      return UserSettingsSections.MY_PROFILE;
    }

    if (currentUrl.includes(routes.profile.generalInformation)) {
      return UserSettingsSections.GENERAL_INFORMATION;
    }

    if (currentUrl.includes(routes.profile.myCollection)) {
      return UserSettingsSections.MY_COLLECTION;
    }

    if (currentUrl.includes(routes.profile.myWallet)) {
      return UserSettingsSections.MY_WALLET;
    }

    if (
      currentUrl.includes(
        routes.profile.myCreations.list ||
          routes.profile.myCreations.create ||
          routes.profile.myCreations.edit,
      )
    ) {
      return UserSettingsSections.MY_CREATIONS;
    }

    if (currentUrl.includes(routes.profile.affiliateTab)) {
      return UserSettingsSections.AFFILIATE;
    }

    if (currentUrl.includes(routes.profile.contentDownload)) {
      return UserSettingsSections.CONTENT_DOWNLOAD;
    }

    if (currentUrl.includes(routes.profile.analyticsTab)) {
      return UserSettingsSections.ANALYTICS;
    }

    return UserSettingsSections.HOMEPAGE;
  };

  const getBricksAmount = (status: BricksNotification) => {
    switch (status) {
      case BricksNotification.SIGN_UP:
        return '2000';
      case BricksNotification.FIRST_PUBLISH:
        return '50';
      case BricksNotification.FIRST_AFFILIATE_REGISTRATION:
        return '0';
      case BricksNotification.FIRST_REVIEW:
        return '50';
      case BricksNotification.FIRST_LIKE:
        return '10';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (resetSuccess) {
      onRefreshCurrentProfileUser();
    }
  }, [resetSuccess]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.profile &&
      currentUser.profile.bricksNotification
    ) {
      showToast(
        translate(intl, 'BRICKS.NOTIFICATION').replace(
          ':amount',
          getBricksAmount(currentUser?.profile?.bricksNotification),
        ),
        'success',
      );

      onResetBricksStatus();
    }
  }, [currentUser]);

  const handleClose = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('success');

    setIsModalOpen(false);

    setTimeout(() => {
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }, 100);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (location.pathname) {
      setIsSearchOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    handleClosePopover();
  }, [window.location.href]);

  useEffect(() => {
    if (width && width > TABLET_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }

    if (width && width <= TABLET_BREAK_POINT) {
      handleClosePopover();
    }
  }, [width]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  useEffect(() => {
    if (
      window.location.href.includes('success=true&amount=') ||
      window.location.href.includes('success=false')
    ) {
      setIsModalOpen(true);
    }
  }, [window.location.href]);

  const isModalSmall = useMemo(() => {
    return (
      window.location.href.includes('success=true&amount=') ||
      window.location.href.includes('success=false')
    );
  }, [window.location.href]);

  const getViableTopBarHighlights = () => {
    if (
      currentUser &&
      currentUser.topBarHighlights.filter(
        (item) => item.customType === TopBarHighlightCustomType.INTRO_VIDEO,
      ).length > 0
    ) {
      const topBarHighlightIds = currentUser.topBarHighlights.map(
        (highlight) => highlight.id,
      );

      return viableTopBarHighlights.filter(
        (viableTopBarHighlight) =>
          !topBarHighlightIds.includes(viableTopBarHighlight.id),
      );
    }

    return viableTopBarHighlights;
  };

  return (
    <>
      {getViableTopBarHighlights().length > 0 && (
        <div className={styles.marqueeContainer}>
          <Marquee pauseOnHover pauseOnClick>
            {getViableTopBarHighlights().map((topBarHighlight) => (
              <TopBarHighlightView
                key={topBarHighlight.id}
                topBarHighlight={topBarHighlight}
              />
            ))}
          </Marquee>
        </div>
      )}
      <header
        className={cx(styles.navigationContainer, {
          [styles.dropShaddow]: isMobileMenuOpen && !isMobileSearchOpen,
        })}
      >
        <div className={styles.innerNavigation}>
          <div className={styles.logoWrapper}>
            <LogoLink logoSrc={logo} />
          </div>
          {width && width > TABLET_BREAK_POINT && (
            <>
              <div className={styles.actionButtons}>
                <nav className={styles.navigation}>
                  <InfoPopover
                    label={translate(intl, 'PUBLIC_NAVIGATION.MOC_INFO')}
                  >
                    <div
                      className={styles.navigationItem}
                      onClick={() =>
                        navigate(
                          generateParamsUrl(routes.publicProducts.list, {
                            ...publicProductListParams,
                            type: [ProductType.MOC],
                          }),
                        )
                      }
                    >
                      <p>{translate(intl, 'PUBLIC_NAVIGATION.MOC_BUILDS')}</p>
                    </div>
                  </InfoPopover>
                  <InfoPopover
                    label={translate(intl, 'PUBLIC_NAVIGATION.ALTS_INFO')}
                  >
                    <div
                      className={styles.navigationItem}
                      onClick={() =>
                        navigate(
                          generateParamsUrl(routes.publicProducts.list, {
                            ...publicProductListParams,
                            type: [ProductType.ALTERNATE],
                          }),
                        )
                      }
                    >
                      <p>
                        {translate(intl, 'PUBLIC_NAVIGATION.ALTERNATE_BUILDS')}
                      </p>
                    </div>
                  </InfoPopover>
                  <InfoPopover
                    label={translate(intl, 'PUBLIC_NAVIGATION.SETS_INFO')}
                  >
                    <div
                      className={styles.navigationItem}
                      onClick={() =>
                        navigate(
                          generateParamsUrl(routes.publicProducts.list, {
                            ...publicProductListParams,
                            type: [ProductType.SET],
                          }),
                        )
                      }
                    >
                      <p>{translate(intl, 'PUBLIC_NAVIGATION.SETS')}</p>
                    </div>
                  </InfoPopover>
                  <InfoPopover
                    label={translate(intl, 'PUBLIC_NAVIGATION.BUNDLES_INFO')}
                  >
                    <div
                      className={styles.navigationItem}
                      onClick={() =>
                        navigate(
                          generateParamsUrl(routes.publicProducts.list, {
                            ...publicProductListParams,
                            type: [ProductType.BUNDLE],
                          }),
                        )
                      }
                    >
                      <p>{translate(intl, 'PUBLIC_NAVIGATION.BUNDLES')}</p>
                    </div>
                  </InfoPopover>
                  <div
                    className={styles.navigationItem}
                    onClick={() =>
                      navigate(
                        generateParamsUrl(
                          routes.publicProducts.list,
                          publicProductListParams,
                        ),
                      )
                    }
                  >
                    <p>{translate(intl, 'PUBLIC_NAVIGATION.ALL_BUILDS')}</p>
                  </div>
                </nav>
                {!isSearchOpen && (
                  <div
                    className={styles.actionIcon}
                    onClick={() => setIsSearchOpen(true)}
                  >
                    <ReactSVG src={desktopSearchIcon} />
                  </div>
                )}
                {currentUser ? (
                  <div
                    className={`${styles.actionIcon} ${
                      currentUser?.likedProducts.length && styles.activeIcon
                    }`}
                    onClick={() =>
                      navigate(
                        routes.publicProfile.profile.replace(
                          ':username',
                          `${currentUser?.username}?tab=3` ?? '',
                        ),
                      )
                    }
                  >
                    <Badge badgeContent={currentUser?.likedProducts.length}>
                      <ReactSVG
                        src={
                          currentUser?.likedProducts.length
                            ? savedIcon
                            : saveIcon
                        }
                      />
                    </Badge>
                  </div>
                ) : (
                  <div
                    className={styles.actionIcon}
                    onClick={() => navigate(routes.login)}
                  >
                    <ReactSVG src={saveIcon} />
                  </div>
                )}
                <div
                  className={`${styles.actionIcon} ${
                    cartItems?.length && styles.activeIcon
                  }`}
                  onClick={() => navigate(routes.cart)}
                >
                  <Badge badgeContent={cartItems.length}>
                    <ReactSVG
                      src={cartItems?.length ? cartActiveIcon : cartIcon}
                    />
                  </Badge>
                </div>
                {currentUser && (
                  <>
                    <BalancePopover
                      amount={currentUser.profile?.bricks ?? 0}
                      isBrick
                    >
                      <Button
                        className={styles.bricksButton}
                        variant="outlined"
                        startIcon={<img src={bricksIcon} />}
                        onClick={() => handleModalOpen()}
                      >
                        {convertToKNotation(currentUser.profile?.bricks ?? 0)}
                      </Button>
                    </BalancePopover>
                  </>
                )}
                {currentUser ? (
                  <>
                    <Button
                      className={styles.profileButton}
                      variant="contained"
                      startIcon={<img src={userIcon} />}
                      onMouseOver={handleOpenPopover}
                    >
                      {translate(intl, 'PUBLIC_NAVIGATION.PROFILE')}
                    </Button>
                    <Popover
                      className={styles.popover}
                      open={isPopoverOpen}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <ProfileSettingsPopover
                        activeSettings={getActiveSettings()}
                        onClosePopover={handleClosePopover}
                      />
                    </Popover>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<img src={userIcon} />}
                    onClick={() => navigate(routes.login)}
                  >
                    {translate(intl, 'PUBLIC_NAVIGATION.LOGIN')}
                  </Button>
                )}
              </div>
            </>
          )}
          {width && width < TABLET_BREAK_POINT && (
            <div>
              <div className={styles.mobileButtons}>
                <div className={styles.actionButtons}>
                  {!isMobileSearchOpen && (
                    <div
                      className={styles.actionButtonContainer}
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setIsMobileSearchOpen(true);
                      }}
                    >
                      <ReactSVG
                        src={mobileSearchIcon}
                        className={styles.mobileIcon}
                      />
                    </div>
                  )}
                  <div
                    className={styles.actionButtonContainer}
                    onClick={() => navigate(routes.cart)}
                  >
                    <Badge badgeContent={cartItems.length}>
                      <ReactSVG src={cartIcon} className={styles.mobileIcon} />
                    </Badge>
                  </div>
                </div>
                <HamburgerButton
                  isMobileMenuOpen={isMobileMenuOpen}
                  clickHandler={() => {
                    setIsMobileSearchOpen(false);
                    setIsMobileMenuOpen((prevState) => !prevState);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </header>
      <Modal
        className={styles.modal}
        onClose={handleClose}
        isOpen={isModalOpen}
        isLoading={false}
        withClose={!isModalSmall}
        isSmall={isModalSmall}
        isFull
      >
        <BalanceTopUp onClose={handleClose} withTitle />
      </Modal>
      {isMobileMenuOpen && width && width < TABLET_BREAK_POINT && (
        <MobileMenu
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          isMobileMenuOpen={isMobileMenuOpen}
          onClick={() => handleModalOpen()}
        />
      )}
      {isMobileSearchOpen && width && width < TABLET_BREAK_POINT && (
        <div className={styles.mobileSearchWrapper}>
          <div className={styles.mobileSearch}>
            <div className={styles.searchInputContainer}>
              <PublicSearchBar
                isMobile
                onCloseSearchBar={() => setIsSearchOpen(false)}
              />
              <div
                className={styles.closeSearchButton}
                onClick={() => setIsMobileSearchOpen(false)}
              >
                <ReactSVG src={closeIcon} />
              </div>
            </div>
          </div>
        </div>
      )}
      {isSearchOpen && width && width > TABLET_BREAK_POINT && (
        <div className={styles.searchWrapper}>
          <div className={styles.searchContainer}>
            <div className={styles.searchInputContainer}>
              <PublicSearchBar
                onCloseSearchBar={() => setIsSearchOpen(false)}
              />
              <div
                className={styles.closeSearchButton}
                onClick={() => setIsSearchOpen(false)}
              >
                <ReactSVG src={closeIcon} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  cartItems: state.user.cartItems,
  resetSuccess: state.profile.resetBricksStatusSuccess,
  publicProductListParams: state.product.publicProductListParams,
  enabledTopBarHighlights: state.topBarHighlight.enabledTopBarHighlights,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onResetBricksStatus: () => dispatch(profileService.resetBricksStatus()),
  onRefreshCurrentProfileUser: () =>
    dispatch(userService.refreshCurrentProfileUser()),
  onFetchEnabledTopBarHighlights: () =>
    dispatch(topBarHighlightService.fetchEnabledTopBarHighlights()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
