import React from 'react';
import styles from './MinimizedMenuSubItems.module.scss';
import { NavigationItem } from '../../Layout/Layout';
import MinimizedMenuSubItemButton from './MinimizedMenuSubItemButton/MinimizedMenuSubItemButton';

type Props = {
  sidebarItems: NavigationItem[];
};

const MinimizedMenuSubItems = ({ sidebarItems }: Props) => {
  return (
    <div className={styles.menuItems}>
      <div className={styles.menuSubItemsContainer}>
        <div className={styles.menuSubItems}>
          {sidebarItems.map((navItem) => (
            <MinimizedMenuSubItemButton
              navItem={navItem}
              key={navItem.to + navItem.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MinimizedMenuSubItems;
