import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { CurrencyActionTypes } from './actionTypes';

export type CurrencyStateType = {
  userCurrencyISOCodeFromLocation: string;
  userCurrencyISOCodeFromLocationLoading: boolean;
  stripeSupportedCurrencies: string[];
  stripeSupportedCurrenciesLoading: boolean;
  stripeSupportedCurrenciesError: HttpError | null;
  currencyRate: number;
  currencyRateLoading: boolean;
  currencyRateError: HttpError | null;
};

export type CurrencyActionType = CurrencyStateType & {
  type: CurrencyActionTypes;
};

export const initialState: CurrencyStateType = {
  userCurrencyISOCodeFromLocation: '',
  userCurrencyISOCodeFromLocationLoading: false,
  stripeSupportedCurrencies: [],
  stripeSupportedCurrenciesLoading: false,
  stripeSupportedCurrenciesError: null,
  currencyRate: 1,
  currencyRateLoading: false,
  currencyRateError: null,
};

const fetchUserCurrencyISOCodeFromLocationStart = (
  state: CurrencyStateType,
): CurrencyStateType => ({
  ...state,
  userCurrencyISOCodeFromLocationLoading: true,
});

const fetchUserCurrencyISOCodeFromLocationSuccess = (
  state: CurrencyStateType,
  action: CurrencyActionType,
): CurrencyStateType => ({
  ...state,
  userCurrencyISOCodeFromLocation: action.userCurrencyISOCodeFromLocation,
  userCurrencyISOCodeFromLocationLoading: false,
});

const fetchStripeSupportedCurrenciesStart = (
  state: CurrencyStateType,
): CurrencyStateType => ({
  ...state,
  stripeSupportedCurrenciesLoading: true,
  stripeSupportedCurrenciesError: null,
});

const fetchStripeSupportedCurrenciesSuccess = (
  state: CurrencyStateType,
  action: CurrencyActionType,
): CurrencyStateType => ({
  ...state,
  stripeSupportedCurrencies: action.stripeSupportedCurrencies,
  stripeSupportedCurrenciesLoading: false,
});

const fetchStripeSupportedCurrenciesFail = (
  state: CurrencyStateType,
  action: CurrencyActionType,
): CurrencyStateType => ({
  ...state,
  stripeSupportedCurrenciesLoading: false,
  stripeSupportedCurrenciesError: action.stripeSupportedCurrenciesError,
});

const fetchCurrencyRateStart = (
  state: CurrencyStateType,
): CurrencyStateType => ({
  ...state,
  currencyRateLoading: true,
  currencyRateError: null,
});

const fetchCurrencyRateSuccess = (
  state: CurrencyStateType,
  action: CurrencyActionType,
): CurrencyStateType => ({
  ...state,
  currencyRate: action.currencyRate,
  currencyRateLoading: false,
});

const fetchCurrencyRateFail = (
  state: CurrencyStateType,
  action: CurrencyActionType,
): CurrencyStateType => ({
  ...state,
  currencyRateLoading: false,
  currencyRateError: action.currencyRateError,
});

const reducer = (state = initialState, action: CurrencyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_START:
      return fetchUserCurrencyISOCodeFromLocationStart(state);
    case actionTypes.FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_SUCCESS:
      return fetchUserCurrencyISOCodeFromLocationSuccess(state, action);
    case actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_START:
      return fetchStripeSupportedCurrenciesStart(state);
    case actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_SUCCESS:
      return fetchStripeSupportedCurrenciesSuccess(state, action);
    case actionTypes.FETCH_STRIPE_SUPPORTED_CURRENCIES_FAIL:
      return fetchStripeSupportedCurrenciesFail(state, action);
    case actionTypes.FETCH_CURRENCY_RATE_START:
      return fetchCurrencyRateStart(state);
    case actionTypes.FETCH_CURRENCY_RATE_SUCCESS:
      return fetchCurrencyRateSuccess(state, action);
    case actionTypes.FETCH_CURRENCY_RATE_FAIL:
      return fetchCurrencyRateFail(state, action);
    default:
      return state;
  }
};

export default reducer;
