import { ReactNode } from 'react';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import styles from './ProfileSection.module.scss';

export enum UserSettingsSections {
  // eslint-disable-next-line no-unused-vars
  MY_PROFILE = 'MY_PROFILE',
  // eslint-disable-next-line no-unused-vars
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  // eslint-disable-next-line no-unused-vars
  MY_WALLET = 'MY_WALLET',
  // eslint-disable-next-line no-unused-vars
  MY_COLLECTION = 'MY_COLLECTION',
  // eslint-disable-next-line no-unused-vars
  MY_LEVEL = 'MY_LEVEL',
  // eslint-disable-next-line no-unused-vars
  MY_CREATIONS = 'MY_CREATIONS',
  // eslint-disable-next-line no-unused-vars
  ANALYTICS = 'ANALYTICS',
  // eslint-disable-next-line no-unused-vars
  AFFILIATE = 'AFFILIATE',
  // eslint-disable-next-line no-unused-vars
  CONTENT_DOWNLOAD = 'CONTENT_DOWNLOAD',
  // eslint-disable-next-line no-unused-vars
  LOGOUT = 'LOGOUT',
  // eslint-disable-next-line no-unused-vars
  HOMEPAGE = 'HOMEPAGE',
}

type Props = {
  activeSettings: UserSettingsSections;
  children: ReactNode;
};

const ProfileSection = ({ activeSettings, children }: Props) => {
  return (
    <div className={styles.profileSection}>
      <div className={styles.profileFirstSection}>
        <ProfileSettings activeSettings={activeSettings} />
      </div>
      <div className={styles.profileSecondSection}>{children}</div>
    </div>
  );
};

export default ProfileSection;
