import * as actionTypes from './actionTypes';
import { PackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Package } from '../../domain/Package';

export type PackageStateType = {
  packageList: ListResults<Package> | null;
  packageListLoading: boolean;
  packageListError: HttpError;
  packageListUpdateNeeded: boolean;
  packageCreateLoading: boolean;
  packageCreateError: HttpError;
  packageCreateSuccess: boolean;
  createdPackage: Package | null;
  packageUpdateLoading: boolean;
  packageUpdateError: HttpError;
  packageUpdateSuccess: boolean;
  creditPackage: Package | null;
  packageLoading: boolean;
  packageError: HttpError;
  packageDeleteLoading: boolean;
  packageDeleteError: HttpError;
  packageDeleteSuccess: boolean;
  allPackagesLoading: boolean;
  allPackages: Package[];
  allPackagesError: HttpError;
};

export type PackageActionType = PackageStateType & {
  type: PackageActionTypes;
};

export const initialState: PackageStateType = {
  packageList: null,
  packageListLoading: true,
  packageListError: null,
  packageListUpdateNeeded: false,
  packageCreateLoading: false,
  packageCreateError: null,
  packageCreateSuccess: false,
  createdPackage: null,
  packageUpdateLoading: false,
  packageUpdateError: null,
  packageUpdateSuccess: false,
  creditPackage: null,
  packageLoading: false,
  packageError: null,
  packageDeleteLoading: false,
  packageDeleteError: null,
  packageDeleteSuccess: false,
  allPackagesLoading: false,
  allPackages: [],
  allPackagesError: null,
};

const fetchPackageListStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageListLoading: true,
  packageCreateSuccess: false,
  packageUpdateSuccess: false,
});

const fetchPackageListSuccess = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageListLoading: false,
  packageList: action.packageList,
  packageListUpdateNeeded: false,
});

const fetchPackageListFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageListLoading: false,
  packageListError: action.packageListError,
});

const fetchPackageStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageLoading: true,
  packageCreateSuccess: false,
  packageUpdateSuccess: false,
});

const fetchPackageSuccess = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageError: null,
  packageLoading: false,
  creditPackage: action.creditPackage,
});

const fetchPackageFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageLoading: false,
  packageError: action.packageError,
});

const createPackageStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageCreateLoading: true,
});

const createPackageSuccess = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageCreateLoading: false,
  packageCreateError: null,
  packageCreateSuccess: true,
  packageListUpdateNeeded: true,
  createdPackage: action.createdPackage,
});

const createPackageFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageCreateLoading: false,
  packageCreateError: action.packageCreateError,
});

const updatePackageStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageUpdateLoading: true,
});

const updatePackageSuccess = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageUpdateLoading: false,
  packageUpdateSuccess: true,
  packageListUpdateNeeded: true,
  packageUpdateError: null,
});

const updatePackageFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageUpdateLoading: false,
  packageUpdateError: action.packageUpdateError,
});

const deletePackageStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageDeleteLoading: true,
});

const deletePackageSuccess = (state: PackageStateType): PackageStateType => ({
  ...state,
  packageDeleteLoading: false,
  packageDeleteSuccess: true,
  packageListUpdateNeeded: true,
});

const deletePackageFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  packageDeleteLoading: false,
  packageDeleteError: action.packageDeleteError,
});

const fetchAllPackagesStart = (state: PackageStateType): PackageStateType => ({
  ...state,
  allPackagesLoading: true,
});

const fetchAllPackagesSuccess = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  allPackagesLoading: false,
  allPackages: action.allPackages,
});

const fetchAllPackagesFail = (
  state: PackageStateType,
  action: PackageActionType,
): PackageStateType => ({
  ...state,
  allPackagesLoading: false,
  allPackagesError: action.allPackagesError,
});

const resetPackageStore = (): PackageStateType => ({
  ...initialState,
});

const completelyResetPackageStore = (): PackageStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_LIST_START:
      return fetchPackageListStart(state);
    case actionTypes.FETCH_PACKAGE_LIST_SUCCESS:
      return fetchPackageListSuccess(state, action);
    case actionTypes.FETCH_PACKAGE_LIST_FAIL:
      return fetchPackageListFail(state, action);
    case actionTypes.FETCH_ALL_PACKAGES_START:
      return fetchAllPackagesStart(state);
    case actionTypes.FETCH_ALL_PACKAGES_SUCCESS:
      return fetchAllPackagesSuccess(state, action);
    case actionTypes.FETCH_ALL_PACKAGES_FAIL:
      return fetchAllPackagesFail(state, action);
    case actionTypes.FETCH_PACKAGE_START:
      return fetchPackageStart(state);
    case actionTypes.FETCH_PACKAGE_SUCCESS:
      return fetchPackageSuccess(state, action);
    case actionTypes.FETCH_PACKAGE_FAIL:
      return fetchPackageFail(state, action);
    case actionTypes.CREATE_PACKAGE_START:
      return createPackageStart(state);
    case actionTypes.CREATE_PACKAGE_SUCCESS:
      return createPackageSuccess(state, action);
    case actionTypes.CREATE_PACKAGE_FAIL:
      return createPackageFail(state, action);
    case actionTypes.UPDATE_PACKAGE_START:
      return updatePackageStart(state);
    case actionTypes.UPDATE_PACKAGE_SUCCESS:
      return updatePackageSuccess(state);
    case actionTypes.UPDATE_PACKAGE_FAIL:
      return updatePackageFail(state, action);
    case actionTypes.DELETE_PACKAGE_START:
      return deletePackageStart(state);
    case actionTypes.DELETE_PACKAGE_SUCCESS:
      return deletePackageSuccess(state);
    case actionTypes.DELETE_PACKAGE_FAIL:
      return deletePackageFail(state, action);
    case actionTypes.RESET_PACKAGE_STORE:
      return resetPackageStore();
    case actionTypes.LOGOUT:
      return completelyResetPackageStore();
    default:
      return state;
  }
};

export default reducer;
