import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './PasswordRemindForm.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { RemindRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { resetRemindPassword } from '../../../../store/auth/actions';
import PasswordRemindSuccess from './PasswordRemindSuccess/PasswordRemindSuccess';

type Props = {
  onPasswordRemind: (inputs: RemindRequest) => void;
  isLoading: boolean;
  error: HttpError;
  isRemindSuccess: boolean;
  onResetRemindPasswordStore: () => void;
  onCloseModal: () => void;
};

type FormInputs = {
  email: string;
};

export const PasswordRemindForm = ({
  onPasswordRemind,
  isLoading,
  error,
  isRemindSuccess,
  onResetRemindPasswordStore,
  onCloseModal,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    return () => onResetRemindPasswordStore();
  }, []);

  const INPUTS = [
    {
      name: 'email',
      label: translate(intl, 'PASSWORD_REMIND_FORM.LABEL_EMAIL'),
      placeholder: translate(intl, 'PASSWORD_REMIND_FORM.PLACEHOLDER_EMAIL'),
      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 60,
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onPasswordRemind({
      email: submitInputs.email,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (isRemindSuccess) {
      setNewInputObject('email', {
        value: '',
      });
    }
  }, [isRemindSuccess]);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  if (isRemindSuccess) {
    return <PasswordRemindSuccess onClose={onCloseModal} />;
  }

  return (
    <div className={styles.remindForm}>
      <Form
        className={styles.form}
        error={error}
        onSubmit={onSubmit}
        scrollIntoView={false}
      >
        {globalError && (
          <Alert variant="danger" capitalize={false}>
            {translate(intl, globalError)}
          </Alert>
        )}
        <h3 className={styles.passwordRemindTitle}>
          {translate(intl, 'PASSWORD_REMIND_FORM.TITLE')}
        </h3>
        <p className={styles.passwordRemindSubTitle}>
          {translate(intl, 'PASSWORD_REMIND_FORM.SUBTITLE')}
        </p>
        {inputs.map((input) => (
          <TextField
            key={input.name}
            onChange={onInputChange}
            value={input.value?.toString() ?? ''}
            label={input.label ?? ''}
            errors={input.validationErrors ?? []}
            onBlur={onLoseInputFocus}
            name={input.name}
            type={input.type}
            placeholder={input.placeholder}
            onInputBlur={onInputBlur}
          />
        ))}
        <div>
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {translate(intl, 'PASSWORD_REMIND_FORM.BUTTON_SUBMIT')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.remindError,
  isLoading: state.auth.remindLoading,
  isRemindSuccess: state.auth.isRemindSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onPasswordRemind: (inputs: RemindRequest) =>
    dispatch(authService.remind(inputs)),
  onResetRemindPasswordStore: () => dispatch(resetRemindPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRemindForm);
