import { createTheme } from '@mui/material';
import { colors, fonts } from './constants';

export const adminTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',
            fontFamily: `"Public Sans", sans-serif`,
            color: colors.secondaryColor,

            '&.MuiInputLabel-shrink': {
              fontSize: '1rem',
              fontWeight: 600,
            },
            '&.Mui-focused': {
              fontWeight: 600,
              fontSize: '1rem',
              color: colors.primaryColor,
            },
            '&.Mui-error': {
              color: colors.inputErrorColor,
            },
          },
          '& .MuiOutlinedInput-root': {
            fontFamily: `"Public Sans", sans-serif`,

            '&:hover fieldset': {
              borderColor: colors.primaryColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.primaryColor,
            },
            '&.Mui-error fieldset': {
              borderColor: colors.inputErrorColor,
            },
            '& fieldset': {
              borderRadius: `0.5rem`,
              borderColor: colors.inputBorderColor,
            },
            '& input': {
              fontSize: '0.875rem',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '1.5',
          letterSpacing: 'normal',
          margin: '0.5rem 0.875rem 0',

          '&.Mui-error': {
            borderColor: colors.inputErrorColor,
            color: colors.inputErrorColor,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.tableRowHoverColor,

          '& .MuiTableCell-root': {
            fontFamily: `"Public Sans", sans-serif`,
            lineHeight: '1.5rem',
            fontSize: '0.875rem',
            borderBottom: 'none',
            color: colors.secondaryColor,
            fontWeight: 600,
          },
          '& .MuiTableCell-root:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '&:hover': {
              backgroundColor: `${colors.tableRowHoverColor} !important`,
            },
          },

          '& .MuiTableCell-root': {
            fontFamily: `"Public Sans", sans-serif`,
            lineHeight: '1.5rem',
            fontSize: '0.875rem',
            color: colors.primaryColor,
            borderBottom: `0.063rem dashed ${colors.tableCellBorderColor}`,
          },

          '& .MuiTableCell-root:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '2.5rem',
        },
        indicator: {
          backgroundColor: colors.primaryColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: `"Public Sans", sans-serif`,
          lineHeight: 1.25,
          fontSize: '0.875rem',
          fontWeight: 600,
          color: colors.secondaryColor,
          textTransform: 'none',

          '&.Mui-selected': {
            color: colors.primaryColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: `"Public Sans", sans-serif`,
          borderRadius: '0.5rem',
          fontWeight: 700,
          textTransform: 'capitalize',
        },
        sizeSmall: {
          height: '2.25rem',
          fontSize: '0.875rem',
          padding: '0.5rem 0.75rem',
        },
        sizeLarge: {
          height: '3rem',
          fontSize: '1rem',
          padding: '0.5rem 1rem',
        },
        contained: {
          backgroundColor: colors.primaryColor,
          color: colors.whiteColor,

          ':hover': {
            backgroundColor: colors.containedButtonHoverColor,
          },
        },
        outlined: {
          color: colors.primaryColor,
          border: `0.063rem solid ${colors.outlinedButtonBorderColor}`,

          ':hover': {
            backgroundColor: colors.outlinedButtonHoverColor,
            border: `0.063rem solid ${colors.outlinedButtonBorderColor}`,
            borderColor: 'currentcolor',
            boxShadow: 'currentcolor 0 0 0 0.031rem',
          },
        },
        text: {
          width: '100%',
          justifyContent: 'normal',
          fontWeight: '600',
          color: colors.textButtonColor,

          ':hover': {
            backgroundColor: colors.textButtonHoverBackground,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
            paddingRight: 10,
          },
          '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon': {
            '.MuiOutlinedInput-root': {
              paddingRight: 10,
            },
          },
          button: {
            width: 'auto',
            height: 'auto',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondaryColor,
          borderRadius: '0.625rem',
          padding: '0.2rem',

          '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: colors.primaryColor,
            borderRadius: '0.625rem',
          },
        },
      },
    },
  },
});

export const publicTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '1.125rem',
            fontFamily: fonts.poppins,
            color: colors.textFieldTextColor,
            borderColor: colors.textFieldBorderColor,

            '&.MuiInputLabel-shrink': {
              fontSize: '1rem',
              fontWeight: 600,
            },
            '&.Mui-focused': {
              fontWeight: 600,
              fontSize: '1rem',
            },
            '&.Mui-error': {
              color: colors.inputErrorColor,
            },
          },
          '& .MuiOutlinedInput-root': {
            fontFamily: fonts.poppins,

            '&:hover fieldset': {
              borderColor: colors.textFieldSelectedColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.textFieldSelectedColor,
            },
            '&.Mui-error fieldset': {
              borderColor: colors.inputErrorColor,
            },
            '& fieldset': {
              color: colors.textFieldTextColor,
              borderRadius: `0.5rem`,
              borderColor: colors.inputBorderColor,
            },
            '& input': {
              fontSize: '0.875rem',
            },
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          style: {
            fontSize: '1rem',
            fontWeight: 400,
            fontFamily: fonts.poppins,
            color: colors.textFieldTextColor,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '1.5',
          letterSpacing: 'normal',
          margin: '0.5rem 0.875rem 0',

          '&.Mui-error': {
            borderColor: colors.inputErrorColor,
            color: colors.inputErrorColor,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: `${colors.tableRowHoverColor} !important`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `0.063rem dashed ${colors.tableCellBorderColor}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '2.5rem',
        },
        flexContainer: {
          borderBottom: `1px solid ${colors.profileSettingsDivider}`,
        },
        indicator: {
          backgroundColor: colors.accentColor,
          height: '0.313rem',
          borderRadius: '0.313rem',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: fonts.poppins,
          lineHeight: '1.875rem',
          fontSize: '1.25rem',
          fontWeight: 400,
          color: colors.charcoalGray,
          textTransform: 'none',

          ':hover': {
            color: colors.accentColor,
          },

          '&.Mui-selected': {
            color: colors.accentColor,
            fontWeight: 600,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fonts.poppins,
          borderRadius: '0.75rem',
          fontWeight: 400,
          textTransform: 'capitalize',
          lineHeight: '27px',
          minHeight: '3.25rem',
          padding: '0.75rem 1.125rem',
          fontSize: '1.125rem',

          '&.Mui-disabled': {
            backgroundImage: 'unset',
          },

          '&.MuiButton-sizeSmall': {
            padding: '.625rem',
          },
        },
        contained: {
          backgroundColor: colors.primaryColor,
          color: colors.whiteColor,

          ':hover': {
            backgroundColor: colors.containedButtonHoverColor,
          },
        },
        sizeSmall: {
          minHeight: '2.5rem',
          minWidth: '2.5rem',
          fontSize: '0.875rem',
          padding: '0',
        },
        containedPrimary: {
          backgroundImage: `linear-gradient(to top, ${colors.buttonGradientBottom}, ${colors.buttonGradientTop})`,
          color: colors.whiteColor,

          ':hover': {
            backgroundColor: `${colors.buttonGradientBottom}`,
            backgroundImage: `linear-gradient(to bottom, ${colors.buttonGradientBottom}, ${colors.buttonGradientTop})`,
          },
        },
        containedSecondary: {
          backgroundImage: colors.outlinedButtonBorderColor,
          color: colors.whiteColor,

          ':hover': {
            backgroundColor: colors.containedButtonHoverColor,
          },
        },
        outlined: {
          color: colors.buttonOutlinedColor,
          border: 'none',
          backgroundColor: colors.buttonOutlinedBackgroundColor,

          ':hover': {
            backgroundColor: colors.buttonOutlinedHoveredBackgroundColor,
            border: 'none',
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          fontFamily: `"Poppins", sans-serif`,
          fontWeight: 500,
          fontSize: '1.375rem',
          color: colors.accordionColor,
          backgroundColor: colors.accordionBackgroundColor,
          borderRadius: '0.75rem !important',
          boxShadow: 'none',
          '&.MuiAccordion-root:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '1rem',
          borderBottomLeftRadius: '0.75rem',
          borderBottomRightRadius: '0.75rem',
          color: colors.accordionDetailsColor,
          backgroundColor: colors.accordionBackgroundColor,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: colors.whiteColor,
          fontFamily: fonts.montserrat,
          fontSize: '1.125rem',
          fontWeight: 600,
          '&.MuiButtonBase-root': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '&:hover': {
              backgroundColor: `transparent !important`,
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '&:hover': {
              backgroundColor: `transparent !important`,
            },
          },
          '& .MuiTableCell-root': {
            fontFamily: `"Public Sans", sans-serif`,
            lineHeight: '1.5rem',
            fontSize: '0.875rem',
            color: colors.primaryColor,
            borderBottom: `0.063rem solid ${colors.profileSettingsDivider}`,
          },

          '& .MuiTableCell-root:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          backgroundColor: colors.whiteColor,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontFamily: fonts.poppins,
          fontWeight: 600,
          backgroundColor: colors.whiteColor,
          border: '0.0625rem solid',
          borderColor: colors.paginationBorder,
          borderRadius: '0.5rem',
          '&:hover': {
            backgroundColor: colors.paginationSelectedBackground,
            color: colors.whiteColor,
          },
          '&.Mui-selected': {
            backgroundColor: colors.paginationSelectedBackground,
            color: colors.whiteColor,
            '&:hover': {
              backgroundColor: colors.paginationSelectedBackground,
              cursor: 'default',
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        rail: {
          border: '.0625rem solid rgba(232, 232, 232, 1);',
          color: 'transparent',
          opacity: '1',
          padding: '.2813rem',
          boxShadow: '0rem 0rem 1.25rem 0rem rgba(0, 0, 0, 0.05)',
        },
        track: {
          border: '0rem solid rgba(232, 232, 232, 1);',
          color: 'transparent',
          opacity: '0',
          padding: '.2813rem',
        },
        thumb: {
          background: 'linear-gradient(180deg, #8C2ADC 0%, #370065 100%)',
          width: '.5625rem',
          height: '5.625rem',
          borderRadius: '.5rem',
          '&:hover': {
            boxShadow: '0rem 0rem 0rem .1875rem rgba(25, 118, 210, 0.16)',
          },
          '&.Mui-active': {
            boxShadow: '0rem 0rem 0rem .25rem rgba(25, 118, 210, 0.16)',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondaryColor,
          borderRadius: '0.625rem',
          padding: '0.2rem',

          '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: colors.primaryPublicColor,
            borderRadius: '0.625rem',
          },
        },
      },
    },
  },
});
