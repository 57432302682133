import { Asset } from './Asset';

export interface Package {
  id: number;
  price: number;
  amount: number;
  bonusAmount: number;
  image: Asset;
  tag?: PackageTag;
}

export enum PackageTag {
  // eslint-disable-next-line no-unused-vars
  MOST_POPULAR = 'MOST_POPULAR',
  // eslint-disable-next-line no-unused-vars
  BEST_VALUE = 'BEST_VALUE',
}
