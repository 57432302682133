import Button from '../../../../common/Button/Button';
import { Package, PackageTag } from '../../../../domain/Package';
import styles from './BalanceTopUpCard.module.scss';
import bricksIcon from '../../../../assets/icons/bricks.svg';
import cx from 'classnames';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { getFormattedAmount } from '../../../../utility/formattedAmount/formattedAmount';

type Props = {
  creditPackage: Package;
  onClick: (selectedPackage: Package) => void;
  isLoading: boolean;
  currency: string;
};

const BalanceTopUp = ({
  creditPackage,
  onClick,
  isLoading,
  currency,
}: Props) => {
  const intl = useIntl();

  return (
    <div
      className={cx(styles.creditPackage, {
        [styles.bestValue]: creditPackage?.tag === PackageTag.BEST_VALUE,
        [styles.mostPopular]: creditPackage?.tag === PackageTag.MOST_POPULAR,
      })}
    >
      {creditPackage?.tag && (
        <p
          className={cx(styles.creditPackageTag, {
            [styles.bestValue]: creditPackage.tag === PackageTag.BEST_VALUE,
            [styles.mostPopular]: creditPackage.tag === PackageTag.MOST_POPULAR,
          })}
        >
          {translate(intl, `PACKAGE_TAG.${creditPackage.tag}`)}
        </p>
      )}
      <div className={styles.imageContainer}>
        <img
          src={
            creditPackage.image.thumbLocation ?? creditPackage.image.location
          }
        />
      </div>
      <p className={styles.amount}>
        {translate(intl, 'BALANCE_CARD_TOP_UP.BRICKS_AMOUNT').replace(
          ':amount',
          getFormattedAmount(creditPackage.amount).toString(),
        )}
      </p>
      <p className={styles.bonusAmount}>
        {translate(intl, 'BALANCE_CARD_TOP_UP.BRICKS_BONUS_AMOUNT').replace(
          ':bonusAmount',
          getFormattedAmount(creditPackage.bonusAmount).toString(),
        )}
      </p>
      <p className={styles.price}>
        {currency === 'eur'
          ? translate(intl, 'BALANCE_CARD_TOP_UP.BRICKS_PRICE').replace(
              ':price',
              getFormattedAmount(creditPackage.price).toString(),
            )
          : translate(intl, 'BALANCE_CARD_TOP_UP.BRICKS_CONVERTED_PRICE')
              .replace(':currencyCode', currency.toUpperCase())
              .replace(
                ':price',
                getFormattedAmount(creditPackage.price).toString(),
              )}
      </p>
      <Button
        className={styles.bricksButton}
        buttonVariant={'outlined'}
        startIcon={!isLoading && <img src={bricksIcon} />}
        onClick={() => onClick(creditPackage)}
        isLoadingButton={isLoading}
        isLoading={isLoading}
      >
        {getFormattedAmount(creditPackage.amount + creditPackage.bonusAmount)}
      </Button>
    </div>
  );
};

export default BalanceTopUp;
