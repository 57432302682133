import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createPackageFail,
  createPackageStart,
  createPackageSuccess,
  deletePackageFail,
  deletePackageStart,
  deletePackageSuccess,
  fetchAllPackagesFail,
  fetchAllPackagesStart,
  fetchAllPackagesSuccess,
  fetchPackageFail,
  fetchPackageListFail,
  fetchPackageListStart,
  fetchPackageListSuccess,
  fetchPackageStart,
  fetchPackageSuccess,
  updatePackageFail,
  updatePackageStart,
  updatePackageSuccess,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';
import { PackageTag } from '../../domain/Package';

const API_URL = '/packages';

export type PackageCreateRequest = {
  price: number;
  amount: number;
  bonusAmount: number;
  tag: PackageTag;
  image: File;
};

export type PackageUpdateRequest = {
  id: number;
  price: number;
  amount: number;
  bonusAmount: number;
  tag: PackageTag;
  image: File;
};

export const fetchPackageList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPackageListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPackageListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPackageListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchPackage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPackageStart());
    return axios
      .get(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(fetchPackageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPackageFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createPackage =
  (inputs: PackageCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createPackageStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createPackageSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.PACKAGE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          createPackageFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const updatePackage =
  (inputs: PackageUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updatePackageStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updatePackageSuccess());
        showToast(translate(intl, 'SUCCESS.PACKAGE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updatePackageFail(err?.response?.data?.message));
      });
  };

export const deletePackage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deletePackageStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deletePackageSuccess());
        showToast(translate(intl, 'SUCCESS.PACKAGE_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deletePackageFail(err?.response?.data?.message));
      });
  };

export const fetchAllPackages = () => (dispatch: Dispatch) => {
  dispatch(fetchAllPackagesStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchAllPackagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllPackagesFail(err?.response?.data?.message));
    });
};
