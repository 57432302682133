import styles from './ProfileSettingsPopover.module.scss';
import walletIcon from '../../../../assets/icons/wallet.svg';
import categoryIcon from '../../../../assets/icons/category.svg';
import creationIcon from '../../../../assets/icons/creation.svg';
import analyticsIcon from '../../../../assets/icons/chart.svg';
import affiliateIcon from '../../../../assets/icons/ticket.svg';
import downloadIcon from '../../../../assets/icons/download.svg';
import logoutIcon from '../../../../assets/icons/logout.svg';
import profileIcon from '../../../../assets/icons/profile.svg';
import publicProfileIcon from '../../../../assets/icons/public-profile.svg';
import cupIcon from '../../../../assets/icons/cup.svg';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import * as authService from '../../../../store/auth/service';
import { User } from '../../../../domain/User';
import { UserSettingsSections } from '../ProfileSection/ProfileSection';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';

type Props = {
  currentUser: User | null;
  onLogout: () => void;
  activeSettings: UserSettingsSections;
  onClosePopover: () => void;
};

type SettingsItem = {
  label: string;
  icon: string;
  type: UserSettingsSections;
  onClick: () => void;
};

type SettingsGroup = {
  label: string;
  settings: Array<SettingsItem>;
};

const ProfileSettingsPopover = ({
  currentUser,
  onLogout,
  activeSettings,
  onClosePopover,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleClose = () => {
    setTimeout(() => {
      onClosePopover;
    }, 10);
  };

  const settingsGroups: Array<SettingsGroup> = [
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_MY_STUFF'),
      settings: [
        {
          type: UserSettingsSections.MY_PROFILE,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_PROFILE'),
          icon: publicProfileIcon,
          onClick: () => {
            navigate(`user/${currentUser?.username}`);
          },
        },
        {
          type: UserSettingsSections.GENERAL_INFORMATION,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_GENERAL_INFORMATION'),
          icon: profileIcon,
          onClick: () => {
            navigate(routes.profile.generalInformation);
          },
        },
        {
          type: UserSettingsSections.MY_WALLET,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_WALLET'),
          icon: walletIcon,
          onClick: () => {
            navigate(routes.profile.myWallet);
          },
        },
        {
          type: UserSettingsSections.MY_COLLECTION,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_COLLECTION'),
          icon: categoryIcon,
          onClick: () => {
            navigate(routes.profile.myCollection);
          },
        },
        {
          type: UserSettingsSections.MY_LEVEL,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_LEVEL'),
          icon: cupIcon,
          onClick: () => navigate(routes.profile.myLevel),
        },
      ],
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_DESIGNER_STUFF'),
      settings: [
        {
          type: UserSettingsSections.MY_CREATIONS,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_CREATIONS'),
          icon: creationIcon,
          onClick: () => {
            navigate(routes.profile.myCreations.list);
          },
        },
        {
          type: UserSettingsSections.ANALYTICS,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_ANALYTICS'),
          icon: analyticsIcon,
          onClick: () => navigate(routes.profile.analyticsTab),
        },
      ],
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_AFFILIATE_STUFF'),
      settings: [
        {
          type: UserSettingsSections.AFFILIATE,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_AFFILIATE'),
          icon: affiliateIcon,
          onClick: () => {
            navigate(routes.profile.affiliateTab);
          },
        },
        {
          type: UserSettingsSections.CONTENT_DOWNLOAD,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_CONTENT_DOWNLOAD'),
          icon: downloadIcon,
          onClick: () => {
            navigate(routes.profile.contentDownload);
          },
        },
        {
          type: UserSettingsSections.LOGOUT,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_LOG_OUT'),
          icon: logoutIcon,
          onClick: () => {
            onLogout();
          },
        },
      ],
    },
  ];

  return (
    <div className={styles.profileSettings} onMouseLeave={onClosePopover}>
      <nav className={styles.profileNavigation}>
        {settingsGroups.map(({ label, settings }, index) => (
          <div
            key={label}
            className={(cx(styles.profileNavigationItem), styles.activeItem)}
          >
            <p className={styles.settingsGroupLabel}>{label}</p>
            {settings.map(({ label, icon, onClick, type }) => (
              <div
                key={label}
                className={styles.settingsGroup}
                role="button"
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <div
                  className={cx(styles.settingsIcon, {
                    [styles.isActive]: type === activeSettings,
                  })}
                >
                  <ReactSVG src={icon} />
                </div>
                <p
                  className={cx(styles.settingsGroupText, {
                    [styles.isActive]: type === activeSettings,
                  })}
                >
                  {label}
                </p>
              </div>
            ))}
            {settingsGroups.length - 1 !== index && (
              <hr className={styles.settingsGroupDivider} />
            )}
          </div>
        ))}
      </nav>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSettingsPopover);
