import React, { ReactNode, useState } from 'react';
import styles from './Sidebar.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-2.svg';
import ababaTechLogo from '../../assets/ababa-tech-logo.svg';
import { routes } from '../../config/Router/routes';
import { NavigationGroup } from '../Layout/Layout';
import MenuSubItems from './MenuSubItems/MenuSubItems';
import { Icon } from '@iconify/react';
import MinimizedMenuSubItems from './MinimizedMenuSubItems/MinimizedMenuSubItems';
import cx from 'classnames';

type Props = {
  navigationGroups: NavigationGroup[];
  currentAccount?: ReactNode;
};

const ICON_SIZE = '1rem';

const Sidebar = ({ navigationGroups, currentAccount }: Props) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const icon = isMinimized
    ? 'solar:alt-arrow-right-linear'
    : 'solar:alt-arrow-left-linear';

  return (
    <div
      className={cx(styles.sidebarContainer, {
        [styles.isMinimized]: isMinimized,
      })}
    >
      <div
        className={cx(styles.sidebarButton, {
          [styles.isMinimized]: isMinimized,
        })}
        role="button"
        onClick={() => setIsMinimized(!isMinimized)}
      >
        <Icon icon={icon} width={ICON_SIZE} height={ICON_SIZE} />
      </div>
      <div className={styles.sidebarContent}>
        <div className={styles.mainContent}>
          <div
            className={cx(styles.logoContainer, {
              [styles.isMinimized]: isMinimized,
            })}
          >
            <div
              className={cx(styles.logo, {
                [styles.isMinimized]: isMinimized,
              })}
            >
              <Link to={routes.login}>
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          {currentAccount}
          <div className={styles.menuItemsContainer}>
            {navigationGroups.map((sidebarItem) =>
              isMinimized ? (
                <MinimizedMenuSubItems
                  key={sidebarItem.label}
                  sidebarItems={sidebarItem.items}
                />
              ) : (
                <MenuSubItems
                  key={sidebarItem.label}
                  sidebarItems={sidebarItem.items}
                  label={sidebarItem.label}
                />
              ),
            )}
          </div>
          <div className={styles.bottomLogoWrapper}>
            <hr className={styles.bottomLogoDivider} />
            <div className={styles.logoContainer}>
              <div
                className={cx(styles.bottomLogo, {
                  [styles.isMinimized]: isMinimized,
                })}
              >
                <a
                  href="https://ababa.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ababaTechLogo} alt="ababa.tech logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomScrollContainer}>
          <div className={styles.bottomScroll} />
        </div>
        <div className={styles.rightScrollContainer}>
          <div className={styles.rightScroll} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
