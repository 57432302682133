import React, { useState } from 'react';
import styles from './SignUpSection.module.scss';
import SocialLogin from '../SocialLogin/SocialLogin';
import LoginForm from '../LoginForm/LoginForm';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import RegisterForm from '../RegisterForm/RegisterForm';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';

type Props = {
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
};

export const SignUpSection = ({ section, onChangeSection }: Props) => {
  const intl = useIntl();
  const [isAgreedCheckbox, setIsAgreedCheckbox] = useState<boolean>(false);
  const [showNotAgreedError, setShowNotAgreedError] = useState<boolean>(false);
  const [isAgreedMarketing, setIsAgreedMarketing] = useState<
    boolean | undefined
  >(undefined);

  const getSection = () => {
    if (section === SignUpSectionType.REGISTER) {
      return (
        <RegisterForm
          onChangeSection={onChangeSection}
          setIsAgreedCheckbox={setIsAgreedCheckbox}
          showNotAgreedError={showNotAgreedError}
          setShowNotAgreedError={setShowNotAgreedError}
          setIsAgreedMarketing={setIsAgreedMarketing}
        />
      );
    }

    return <LoginForm onChangeSection={onChangeSection} />;
  };

  return (
    <div className={styles.loginSectionContainer}>
      {getSection()}
      <div className={styles.separator}>
        <span className={styles.word}>
          {translate(intl, 'SIGN_UP_SECTION.SEPARATOR')}
        </span>
      </div>
      <SocialLogin
        isRegistration={section === SignUpSectionType.REGISTER}
        setShowNotAgreedError={setShowNotAgreedError}
        isAgreedCheckbox={
          isAgreedCheckbox && section === SignUpSectionType.REGISTER
        }
        isAgreedMarketing={
          section === SignUpSectionType.REGISTER &&
          isAgreedMarketing !== undefined
            ? isAgreedMarketing
            : undefined
        }
      />
    </div>
  );
};

export default SignUpSection;
