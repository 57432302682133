/* eslint-disable no-unused-vars */

export enum ActionType {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  STATUS = 'STATUS',
  PUBLISH = 'PUBLISH',
  DEPUBLISH = 'DEPUBLISH',
  CONFIRM_WITHDRAWAL = 'CONFIRM_WITHDRAWAL',
  REJECT_WITHDRAWAL = 'REJECT_WITHDRAWAL',
  ASSIGN = 'ASSIGN',
  ADD = 'ADD',
}
