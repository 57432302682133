import axios from '../../config/Axios/axios-instance';

import {
  fetchUserCurrencyISOCodeFromLocationStart,
  fetchUserCurrencyISOCodeFromLocationSuccess,
  fetchStripeSupportedCurrenciesStart,
  fetchStripeSupportedCurrenciesSuccess,
  fetchStripeSupportedCurrenciesFail,
  fetchCurrencyRateStart,
  fetchCurrencyRateSuccess,
  fetchCurrencyRateFail,
} from './actions';
import { Dispatch } from 'redux';

const API_URL = '/currency';
const FALLBACK_CURRENCY_ISO_CODE = 'eur';

export const fetchUserCurrencyISOCodeFromLocation =
  () => (dispatch: Dispatch) => {
    dispatch(fetchUserCurrencyISOCodeFromLocationStart());
    return axios
      .get(`${API_URL}/user-currency-iso-code`)
      .then((response) => {
        dispatch(
          fetchUserCurrencyISOCodeFromLocationSuccess(
            response.data || FALLBACK_CURRENCY_ISO_CODE,
          ),
        );
      })
      .catch((err) => {
        dispatch(
          fetchUserCurrencyISOCodeFromLocationSuccess(
            FALLBACK_CURRENCY_ISO_CODE,
          ),
        );
      });
  };

export const fetchStripeSupportedCurrencies = () => (dispatch: Dispatch) => {
  dispatch(fetchStripeSupportedCurrenciesStart());
  return axios
    .get(`${API_URL}/stripe-supported-currencies`)
    .then((response) => {
      dispatch(fetchStripeSupportedCurrenciesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(
        fetchStripeSupportedCurrenciesFail(err?.response?.data?.message),
      );
    });
};

export const fetchCurrencyRate =
  (currencyCode: string) => (dispatch: Dispatch) => {
    dispatch(fetchCurrencyRateStart());
    return axios
      .get(`${API_URL}/rate/${currencyCode}`)
      .then((response) => {
        dispatch(fetchCurrencyRateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCurrencyRateFail(err?.response?.data?.message));
      });
  };
