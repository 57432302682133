import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginGoogleRequest } from '../../../../store/auth/service';
import { connect } from 'react-redux';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../../../store/auth/service';
import Alert from '../../../../common/Alert/Alert';
import { HttpError } from '../../../../config/Axios/axios-instance';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import styles from './SocialLogin.module.scss';
import { Button } from '@mui/material';
import googleIcon from '../../../../assets/icons/google.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';

type GoogleError = {
  details?: string;
  error?: string;
};

type Props = {
  onLoginGoogleSubmit: (inputs: LoginGoogleRequest) => void;
  isLoading: boolean;
  error: HttpError;
  isRegistration?: boolean;
  isAgreedCheckbox?: boolean;
  refCode?: string;
  setShowNotAgreedError: (state: boolean) => void;
  isAgreedMarketing?: boolean;
};

const SocialLogin = ({
  onLoginGoogleSubmit,
  error,
  isLoading,
  isRegistration,
  isAgreedCheckbox,
  refCode,
  setShowNotAgreedError,
  isAgreedMarketing,
}: Props) => {
  const [googleError, setGoogleError] = useState<string | GoogleError | null>(
    null,
  );
  const intl = useIntl();

  const handleSuccessGoogle = (response: any) => {
    handleSubmitGoogle(response);
  };

  const handleSubmitGoogle = (response: any) => {
    if (isRegistration) {
      // @ts-ignore
      window?.dataLayer?.push({
        event: 'registrationComplete',
        event_category: 'Registration',
        event_action: 'Complete',
        event_label: 'Google Sign-up',
        registration_method: 'Google',
      });
    } else {
      // @ts-ignore
      window?.dataLayer?.push({
        event: 'login',
        event_category: 'Login',
        event_action: 'Complete',
        event_label: 'Google Login',
        registration_method: 'Google',
      });
    }

    onLoginGoogleSubmit({
      token: response.access_token,
      email: '',
      firstName: '',
      lastName: '',
      imageUrl: '',
      refCode: refCode,
      agreedMarketing: isAgreedMarketing,
    });
  };

  const handleFailureGoogle = () => {
    setGoogleError('');
  };

  const handleClick = () => {
    if (isRegistration && !isAgreedCheckbox) {
      setShowNotAgreedError(true);
      return;
    }

    handleGoogleLogin();
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleSuccessGoogle,
    onError: handleFailureGoogle,
  });

  return (
    <div className={styles.socialLogin}>
      {error && (
        <Alert className={styles.loginError} variant="danger">
          {error.toString()}
        </Alert>
      )}
      {!!googleError && (
        <Alert className={styles.loginError} variant="danger">
          {typeof googleError !== 'string'
            ? googleError?.details
              ? googleError?.details
              : googleError.error ?? ''
            : null}
        </Alert>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={() => handleClick()}
          startIcon={<img src={googleIcon} />}
          className={styles.button}
        >
          <span>
            {isRegistration
              ? translate(intl, 'SIGN_UP.REGISTER_GOOGLE')
              : translate(intl, 'LOGIN.GOOGLE')}
          </span>
        </Button>
      </div>
      <div className={styles.agreementMessage}>
        {translate(intl, 'SOCIAL_LOGIN.AGREEMENT_MESSAGE_FIRST_HALF')}{' '}
        <Link
          to={routes.public.termsOfService}
          target="_blank"
          className={styles.link}
        >
          {translate(intl, 'SOCIAL_LOGIN.AGREEMENT_MESSAGE_TERMS_OF_SERVICE')}
        </Link>{' '}
        {translate(intl, 'SOCIAL_LOGIN.AGREEMENT_MESSAGE_AND')}{' '}
        <Link to={routes.privacyPolicy} target="_blank" className={styles.link}>
          {translate(intl, 'SOCIAL_LOGIN.AGREEMENT_MESSAGE_PRIVACY_POLICY')}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.loginGoogleError,
  isLoading: state.auth.loginGoogleLoading,
  refCode: state.auth.refCode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLoginGoogleSubmit: (inputs: LoginGoogleRequest) =>
    dispatch(authService.loginWithGoogle(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
