import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './FileInput.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Asset } from '../../domain/Asset';
import { HttpError } from '../../config/Axios/axios-instance';
import Dropzone from 'react-dropzone';
import { Clear } from '@mui/icons-material';
import { TablePriorityUpdate } from '../Table/Table';
import documentUploadIcon from '../../assets/icons/document-upload.svg';
import documentUploadedIcon from '../../assets/icons/document-uploaded.svg';
import documentBadIcon from '../../assets/icons/document-bad.svg';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { Tooltip } from '@mui/material';
import infoIcon from '../../assets/icons/info.svg';
import { v4 as uuidv4 } from 'uuid';
import { ReactSVG } from 'react-svg';
import deleteIcon from '../../assets/icons/close.svg';

type Props = {
  name: string;
  multiple?: boolean;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onFileDropped?: (name: string, files: File[]) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  maxFileCount?: number;
  errors?: Array<string>;
  label?: string | ReactNode;
  helperText?: string;
  asset?: Asset | Asset[] | null;
  onDragDrop?: (updatedPriorities: TablePriorityUpdate[]) => void;
  firstImageLabelTitle?: string;
  headerTitle?: string;
  allowedExtensionsLabel?: string;
  fileSizeLabel?: string;
  isNotImage?: boolean;
  explanationLabel?: string | ReactNode;
  required?: boolean;
  clearField?: boolean;
  onClearField?: (event: React.MouseEvent) => void;
};

const FileInput = ({
  onFileChange,
  value,
  name,
  maxFileCount,
  multiple,
  errors,
  label,
  helperText,
  asset,
  onImageDelete,
  onFileDropped,
  onSetValidationErrors,
  onDragDrop,
  firstImageLabelTitle,
  headerTitle,
  allowedExtensionsLabel,
  fileSizeLabel,
  isNotImage,
  explanationLabel,
  required,
  clearField,
  onClearField,
}: Props) => {
  const [reorderedItems, setReorderedItems] = useState<(File | Asset)[]>();

  const intl = useIntl();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const newFiles = useMemo(
    () =>
      reorderedItems
        ? (
            (value as File[]).filter((val) => val instanceof File) as File[]
          ).filter(
            (file) =>
              !reorderedItems.some(
                (item) =>
                  item instanceof File &&
                  item.name === file.name &&
                  item.size === file.size,
              ),
          )
        : [],
    [value],
  );

  useEffect(() => {
    if (newFiles && reorderedItems) {
      setReorderedItems([...reorderedItems, ...newFiles] as (File | Asset)[]);
    }
  }, [newFiles]);

  useEffect(() => {
    if (reorderedItems && reorderedItems.length > 0) {
      const mappedReorderedItems = reorderedItems?.map((item) =>
        item instanceof File ? item : item.location,
      ) as string[];

      setItems(mappedReorderedItems);

      onDragDrop &&
        onDragDrop(
          reorderedItems.map((item: Asset | File, index: number) => {
            return {
              id: item instanceof File ? item.name : item.id,
              priority: index,
            };
          }),
        );
    }
  }, [reorderedItems]);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const getIcon = () => {
    if (errors && errors.length > 0) {
      return documentBadIcon;
    }

    if (value && (!errors || errors.length === 0)) {
      return documentUploadedIcon;
    }

    return documentUploadIcon;
  };

  const renderAddFileButton = () => {
    const hasValue = Array.isArray(value) ? !!value.length : !!value;
    const uploadedCount = Array.isArray(value) && value.length;

    return (
      <div className={styles.innerFileSelectContainer}>
        <img src={getIcon()} />
        <span className={styles.innerFileSelectLabel}>
          {hasValue &&
          (!errors || errors.length === 0) &&
          (!multiple || (maxFileCount && uploadedCount === maxFileCount))
            ? translate(intl, 'INPUTS.READY_TO_UPLOAD')
            : translate(
                intl,
                multiple
                  ? isNotImage
                    ? 'INPUTS.UPLOAD_FILES'
                    : 'INPUTS.UPLOAD_IMAGES'
                  : isNotImage
                  ? 'INPUTS.UPLOAD_FILE'
                  : 'INPUTS.UPLOAD_IMAGE',
              )}
        </span>
      </div>
    );
  };

  const renderImage = (value: File | string, index: number) => {
    return (
      <div key={`image-${index}`} className={styles.imageContainer}>
        {multiple && (
          <>
            <div className={styles.delete}>
              <Clear
                color="inherit"
                onClick={(e) => {
                  onImageDelete?.(name, value);

                  if (reorderedItems) {
                    const newReorderedItems: (File | Asset)[] =
                      reorderedItems.filter(
                        (_: File | Asset, i: number) => i !== index,
                      );

                    setReorderedItems(newReorderedItems);
                  }

                  if (onDragDrop) {
                    setItems((prev) => prev?.filter((_, i) => i !== index));

                    const indexToDelete = (asset as Asset[]).findIndex(
                      (item) => item.location === value,
                    );

                    (asset as Asset[]).splice(indexToDelete, 1);
                  }

                  if (uploadInputRef?.current) {
                    uploadInputRef.current.value = '';
                  }
                }}
              />
            </div>
            {firstImageLabelTitle && index === 0 && (
              <div className={styles.firstImageLabelTitle}>
                {firstImageLabelTitle}
              </div>
            )}
          </>
        )}
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt={value.name} />
        ) : (
          <img src={value.toString()} alt={value?.toString() || ''} />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      multiple &&
      maxFileCount &&
      Array.isArray(value) &&
      maxFileCount < value.length &&
      onSetValidationErrors
    ) {
      onSetValidationErrors([
        {
          field: name,
          message: translate(intl, 'VALIDATION.TOO_MANY_FILES'),
        },
      ]);
    }
  }, [value, multiple, maxFileCount]);

  const [items, setItems] = useState<string[]>(value as string[]);

  useEffect(() => setItems(value as string[]), [value]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item === active.id);
        const newIndex = items.findIndex((item) => item === over!.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    if (
      over?.data?.current?.sortable.index ===
      active?.data?.current?.sortable.index
    ) {
      return;
    }

    const files = (value as File[]).filter(
      (val) => val instanceof File,
    ) as File[];

    const prioritizedRows = reorderedItems
      ? reorderedItems
      : ([...(asset as Asset[]), ...files] as (File | Asset)[]);

    const src = active?.data?.current?.sortable.index;
    const dest = over?.data?.current?.sortable.index;

    prioritizedRows.splice(
      dest < src ? dest : dest + 1,
      0,
      // @ts-ignore
      prioritizedRows[src],
    );
    prioritizedRows.splice(dest < src ? src + 1 : src, 1);

    setReorderedItems(prioritizedRows);

    onDragDrop &&
      onDragDrop(
        prioritizedRows.map((item: Asset | File, index: number) => {
          return {
            id: item instanceof File ? item.name : item.id,
            priority: index,
          };
        }),
      );
  };

  return (
    <>
      {headerTitle && (
        <div className={styles.headerContainer}>{headerTitle}</div>
      )}

      <Dropzone
        multiple
        onDrop={(acceptedFiles) => onFileDropped?.(name, acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={styles.fileFieldContainer} {...getRootProps()}>
            <div className={styles.fileField}>
              <input
                color="primary"
                type="file"
                onChange={onFileChange}
                ref={uploadInputRef}
                name={name}
                multiple={multiple}
                accept=".jpeg,.jpg,.png,.webp"
                {...getInputProps()}
              />
              <div className={styles.fileContainer}>
                <div
                  className={styles.fileSelectedContainer}
                  onClick={handleUploadCLick}
                >
                  {value && !multiple && !isNotImage
                    ? renderImage(value as File | string, 0)
                    : renderAddFileButton()}
                </div>
              </div>
              <div className={styles.label}>
                <h5>
                  {label}
                  {required && <span>*</span>}
                  {explanationLabel && (
                    <Tooltip title={explanationLabel}>
                      <img src={infoIcon} />
                    </Tooltip>
                  )}
                </h5>
                <p>
                  {fileSizeLabel || translate(intl, 'VALIDATION.MAX_FILE_SIZE')}
                </p>
                <p>
                  {allowedExtensionsLabel ||
                    translate(intl, 'VALIDATION.ALLOWED_FILE_EXTENSIONS')}
                </p>
                {multiple && maxFileCount && (
                  <p>
                    {translate(intl, 'MAX_FILES_ALLOWED').replace(
                      ':max',
                      maxFileCount.toString(),
                    )}
                  </p>
                )}
                {!multiple && !Array.isArray(asset) && (
                  <p className={styles.selectedFileLabel}>
                    {value && value instanceof File && (
                      <FontAwesomeIcon
                        icon={faFileImport as IconProp}
                        fixedWidth
                        size="sm"
                        className={styles.selectedFileIcon}
                      />
                    )}
                    <span>
                      {value && value instanceof File
                        ? value.name
                        : asset?.fileName ??
                          translate(intl, 'VALIDATION.NO_FILE_SELECTED')}
                      {clearField &&
                        ((value && value instanceof File && value.name) ||
                          asset?.fileName) && (
                          <ReactSVG src={deleteIcon} onClick={onClearField} />
                        )}
                    </span>
                  </p>
                )}
                {errors &&
                  errors.map((error, index) => (
                    <div
                      className={styles.fileError}
                      key={`file-error-${index}`}
                    >
                      {error}
                    </div>
                  ))}
                {helperText && <p>{helperText}</p>}
              </div>
            </div>
          </div>
        )}
      </Dropzone>

      {onDragDrop && Array.isArray(value) && !!value.length && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={rectSortingStrategy}>
            <div className={styles.multipleImageContainer}>
              {items.map((image: string, index: number) => (
                <SortableItem
                  key={uuidv4()}
                  id={image}
                  file={image}
                  index={index}
                  renderImage={renderImage}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}

      {!onDragDrop && multiple && Array.isArray(value) && !!value.length && (
        <div className={styles.multipleImageContainer}>
          {Array.isArray(value) &&
            value.map((image, index) =>
              renderImage(image as File | string, index),
            )}
        </div>
      )}
    </>
  );
};

export default FileInput;
