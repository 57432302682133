import AffiliateTab from '../../../../component/Public/Profile/AffiliateTab/AffiliateTab';
import ProfileSection, {
  UserSettingsSections,
} from '../../../../component/Public/Profile/ProfileSection/ProfileSection';

const AffiliatePage = () => {
  return (
    <ProfileSection activeSettings={UserSettingsSections.AFFILIATE}>
      <AffiliateTab />
    </ProfileSection>
  );
};

export default AffiliatePage;
