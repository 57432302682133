import * as actionTypes from './actionTypes';
import { UserRequestActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type UserRequestStateType = {
  accountDeletionRequestLoading: boolean;
  accountDeletionRequestSuccess: boolean;
  accountDeletionRequestError: HttpError;
};

export type UserRequestActionType = UserRequestStateType & {
  type: UserRequestActionTypes;
};

export const initialState: UserRequestStateType = {
  accountDeletionRequestLoading: false,
  accountDeletionRequestSuccess: false,
  accountDeletionRequestError: null,
};

const fetchCurrentUserStart = (
  state: UserRequestStateType,
): UserRequestStateType => ({
  ...state,
  accountDeletionRequestLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserRequestStateType,
): UserRequestStateType => ({
  ...state,
  accountDeletionRequestSuccess: true,
  accountDeletionRequestLoading: false,
});

const fetchCurrentUserFail = (
  state: UserRequestStateType,
  action: UserRequestActionType,
): UserRequestStateType => ({
  ...state,
  accountDeletionRequestLoading: false,
  accountDeletionRequestError: action.accountDeletionRequestError,
});

const resetUserRequestStore = (
  state: UserRequestStateType,
): UserRequestStateType => ({
  ...initialState,
});

const completelyResetUserRequestStore = (): UserRequestStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserRequestActionType) => {
  switch (action.type) {
    case actionTypes.REQUEST_USER_ACCOUNT_DELETION_START:
      return fetchCurrentUserStart(state);
    case actionTypes.REQUEST_USER_ACCOUNT_DELETION_SUCCESS:
      return fetchCurrentUserSuccess(state);
    case actionTypes.REQUEST_USER_ACCOUNT_DELETION_FAIL:
      return fetchCurrentUserFail(state, action);
    case actionTypes.RESET_USER_REQUEST_STORE:
      return resetUserRequestStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserRequestStore();
    default:
      return state;
  }
};

export default reducer;
