import { Button } from '@mui/material';
import userIcon from '../../../assets/icons/userIcon.svg';
import styles from './MobileMenu.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import { User } from '../../../domain/User';
import bricksIcon from '../../../assets/icons/bricks.svg';
import MobileMenuItem from './MobileMenuItem/MobileMenuItem';
import logoutIcon from '../../../assets/icons/logout.svg';
import { ReactSVG } from 'react-svg';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from 'src/config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as authService from '../../../store/auth/service';
import { useState } from 'react';
import arrowIcon from '../../../assets/icons/mobile-menu-arrow.svg';
import walletIcon from '../../../assets/icons/wallet.svg';
import categoryIcon from '../../../assets/icons/category.svg';
import creationIcon from '../../../assets/icons/creation.svg';
import analyticsIcon from '../../../assets/icons/chart.svg';
import affiliateIcon from '../../../assets/icons/ticket.svg';
import downloadIcon from '../../../assets/icons/download.svg';
import profileIcon from '../../../assets/icons/profile.svg';
import { convertToKNotation } from '../../../utility/currencyHelper/currencyHelper';
import { generateParamsUrl } from '../../../utility/url/urlHelper';
import { ProductFilterParams } from '../../../store/product/service';
import { ProductType } from '../../../domain/Product';
import cupIcon from '../../../assets/icons/cup.svg';

type Props = {
  setIsMobileMenuOpen: (isOpen: boolean) => void;
  currentUser: User | null;
  onClick: () => void;
  onLogout: () => void;
  isMobileMenuOpen: boolean;
  publicProductListParams: ProductFilterParams;
};

export enum MobileSubMenuType {
  // eslint-disable-next-line no-unused-vars
  MY_STUFF = 'MY_STUFF',
  // eslint-disable-next-line no-unused-vars
  DESIGNER_STUFF = 'DESIGNER_STUFF',
  // eslint-disable-next-line no-unused-vars
  AFFILIATE_STUFF = 'AFFILIATE_STUFF',
}

export type MobileMenuItemType = {
  label: string;
  onClick: () => void;
  isPublic?: boolean;
  icon?: string;
};

const MobileMenu = ({
  setIsMobileMenuOpen,
  isMobileMenuOpen,
  currentUser,
  onClick,
  onLogout,
  publicProductListParams,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [subMenu, setSubMenu] = useState<MobileSubMenuType | null>(null);

  const MOBILE_MENU_ITEMS: MobileMenuItemType[] = [
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.MOC_BUILDS'),
      onClick: () =>
        navigate(
          generateParamsUrl(routes.publicProducts.list, {
            ...publicProductListParams,
            type: [ProductType.MOC],
          }),
        ),
      isPublic: true,
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.ALTERNATE_BUILDS'),
      onClick: () =>
        navigate(
          generateParamsUrl(routes.publicProducts.list, {
            ...publicProductListParams,
            type: [ProductType.ALTERNATE],
          }),
        ),
      isPublic: true,
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.SETS'),
      onClick: () =>
        navigate(
          generateParamsUrl(routes.publicProducts.list, {
            ...publicProductListParams,
            type: [ProductType.SET],
          }),
        ),
      isPublic: true,
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.BUNDLES'),
      onClick: () =>
        navigate(
          generateParamsUrl(routes.publicProducts.list, {
            ...publicProductListParams,
            type: [ProductType.BUNDLE],
          }),
        ),
      isPublic: true,
    },
    {
      label: translate(intl, 'PUBLIC_NAVIGATION.ALL_BUILDS'),
      onClick: () =>
        navigate(
          generateParamsUrl(
            routes.publicProducts.list,
            publicProductListParams,
          ),
        ),
      isPublic: true,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_MY_LIKES'),
      onClick: () => {
        currentUser
          ? navigate(
              routes.publicProfile.profile.replace(
                ':username',
                `${currentUser?.username}?tab=3` ?? '',
              ),
            )
          : navigate(routes.login);
      },
      isPublic: true,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_MY_STUFF'),
      onClick: () => setSubMenu(MobileSubMenuType.MY_STUFF),
      isPublic: false,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_DESIGNER_STUFF'),
      onClick: () => setSubMenu(MobileSubMenuType.DESIGNER_STUFF),
      isPublic: false,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_AFFILIATE_STUFF'),
      onClick: () => setSubMenu(MobileSubMenuType.AFFILIATE_STUFF),
      isPublic: false,
    },
  ];

  const MY_STUFF_ITEMS: MobileMenuItemType[] = [
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_GENERAL_INFORMATION'),
      onClick: () => navigate(routes.profile.generalInformation),
      icon: profileIcon,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_WALLET'),
      onClick: () => navigate(routes.profile.myWallet),
      icon: walletIcon,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_COLLECTION'),
      onClick: () => navigate(routes.profile.myCollection),
      icon: categoryIcon,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_LEVELS_AND_REWARDS'),
      onClick: () => navigate(routes.profile.myLevel),
      icon: cupIcon,
    },
  ];

  const DESIGNER_STUFF_ITEMS: MobileMenuItemType[] = [
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_CREATIONS'),
      onClick: () => navigate(routes.profile.myCreations.list),
      icon: creationIcon,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_ANALYTICS'),
      onClick: () => navigate(routes.profile.analyticsTab),
      icon: analyticsIcon,
    },
  ];

  const AFFILIATE_STUFF = [
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_AFFILIATE'),
      onClick: () => navigate(routes.profile.affiliateTab),
      icon: affiliateIcon,
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS.LABEL_CONTENT_DOWNLOAD'),
      onClick: () => navigate(routes.profile.contentDownload),
      icon: downloadIcon,
    },
  ];

  const getFilteredMenuItems = () => {
    if (subMenu === MobileSubMenuType.MY_STUFF) {
      return MY_STUFF_ITEMS;
    }

    if (subMenu === MobileSubMenuType.DESIGNER_STUFF) {
      return DESIGNER_STUFF_ITEMS;
    }

    if (subMenu === MobileSubMenuType.AFFILIATE_STUFF) {
      return AFFILIATE_STUFF;
    }

    if (currentUser) {
      return MOBILE_MENU_ITEMS;
    }

    return MOBILE_MENU_ITEMS.filter((menuItem) => menuItem.isPublic);
  };

  return (
    <div className={styles.mobileMenu}>
      {currentUser && (
        <div className={styles.creditContainer}>
          <Button
            variant="outlined"
            className={styles.bricksButton}
            startIcon={<img src={bricksIcon} />}
            onClick={() => {
              setIsMobileMenuOpen(false);
              onClick();
            }}
          >
            {convertToKNotation(currentUser?.profile?.bricks ?? 0)}
          </Button>
        </div>
      )}
      <nav className={styles.mobileMenuItems}>
        {subMenu && (
          <div
            onClick={() => setSubMenu(null)}
            role="button"
            className={styles.buttonBack}
          >
            <ReactSVG src={arrowIcon} className={styles.buttonBackIcon} />
            <p>{translate(intl, 'PROFILE_SETTINGS.BACK')}</p>
          </div>
        )}
        {getFilteredMenuItems().map((menuItem, index) => (
          <MobileMenuItem
            key={menuItem.label}
            label={menuItem.label}
            isFirst={!index}
            icon={menuItem?.icon}
            onClick={menuItem.onClick}
          />
        ))}
        {currentUser && (
          <div
            className={styles.mobileLogout}
            onClick={() => onLogout()}
            role="button"
          >
            <div className={styles.mobileLogoutIcon}>
              <ReactSVG src={logoutIcon} />
            </div>
            <p>{translate(intl, 'PROFILE_SETTINGS.LABEL_LOG_OUT')}</p>
          </div>
        )}
      </nav>
      {!currentUser && (
        <Button
          variant="contained"
          startIcon={<img src={userIcon} />}
          onClick={() => navigate(routes.login)}
          className={styles.loginButton}
        >
          {translate(intl, 'PUBLIC_NAVIGATION.LOGIN')}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  publicProductListParams: state.product.publicProductListParams,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
