import * as actionTypes from './actionTypes';
import { ProductPromotionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProductPromotion } from '../../domain/ProductPromotion';

export type ProductPromotionStateType = {
  purchasedProductPromotion: ProductPromotion | null;
  purchasedProductPromotionLoading: boolean;
  purchasedProductPromotionError: HttpError;
  purchasedProductPromotionSuccess: boolean;
};

export type ProductPromotionActionType = ProductPromotionStateType & {
  type: ProductPromotionActionTypes;
};

export const initialState: ProductPromotionStateType = {
  purchasedProductPromotion: null,
  purchasedProductPromotionLoading: false,
  purchasedProductPromotionError: null,
  purchasedProductPromotionSuccess: false,
};

const purchaseProductPromotionStart = (
  state: ProductPromotionStateType,
): ProductPromotionStateType => ({
  ...state,
  purchasedProductPromotionSuccess: false,
  purchasedProductPromotionLoading: true,
});

const purchaseProductPromotionSuccess = (
  state: ProductPromotionStateType,
  action: ProductPromotionActionType,
): ProductPromotionStateType => ({
  ...state,
  purchasedProductPromotionLoading: false,
  purchasedProductPromotionSuccess: true,
  purchasedProductPromotion: action.purchasedProductPromotion,
});

const purchaseProductPromotionFail = (
  state: ProductPromotionStateType,
  action: ProductPromotionActionType,
): ProductPromotionStateType => ({
  ...state,
  purchasedProductPromotionLoading: false,
  purchasedProductPromotionError: action.purchasedProductPromotionError,
});

const resetPurchaseProductPromotion = (
  state: ProductPromotionStateType,
): ProductPromotionStateType => ({
  ...state,
  purchasedProductPromotionLoading: false,
  purchasedProductPromotionSuccess: false,
  purchasedProductPromotion: null,
});

const resetProductPromotionStore = (): ProductPromotionStateType => ({
  ...initialState,
});

const completelyResetProductPromotionStore = (): ProductPromotionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProductPromotionActionType) => {
  switch (action.type) {
    case actionTypes.PURCHASE_PRODUCT_PROMOTION_START:
      return purchaseProductPromotionStart(state);
    case actionTypes.PURCHASE_PRODUCT_PROMOTION_SUCCESS:
      return purchaseProductPromotionSuccess(state, action);
    case actionTypes.PURCHASE_PRODUCT_PROMOTION_FAIL:
      return purchaseProductPromotionFail(state, action);
    case actionTypes.RESET_PURCHASE_PRODUCT_PROMOTION:
      return resetPurchaseProductPromotion(state);
    case actionTypes.RESET_PRODUCT_PROMOTION_STORE:
      return resetProductPromotionStore();
    case actionTypes.LOGOUT:
      return completelyResetProductPromotionStore();
    default:
      return state;
  }
};

export default reducer;
