import openIcon from '../../../assets/icons/mobile-menu-open.svg';
import closeIcon from '../../../assets/icons/mobile-menu-close.svg';
import styles from './HamburgerButton.module.scss';
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';

type Props = {
  isMobileMenuOpen: boolean;
  clickHandler: () => void;
};

const HamburgerButton = ({ isMobileMenuOpen, clickHandler }: Props) => {
  const body = document.querySelector('body');

  useEffect(() => {
    if (body && isMobileMenuOpen) {
      body.style.overflow = 'hidden';
    }

    if (body && !isMobileMenuOpen) {
      body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  return (
    <div
      onClick={clickHandler}
      role="button"
      className={styles.hamburgerButton}
    >
      <ReactSVG
        src={isMobileMenuOpen ? closeIcon : openIcon}
        className={styles.icon}
      />
    </div>
  );
};

export default HamburgerButton;
