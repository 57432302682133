export const FETCH_PROMOTIONS_LIST_START = 'FETCH_PROMOTIONS_LIST_START';
export const FETCH_PROMOTIONS_LIST_SUCCESS = 'FETCH_PROMOTIONS_LIST_SUCCESS';
export const FETCH_PROMOTIONS_LIST_FAIL = 'FETCH_PROMOTIONS_LIST_FAIL';

export const FETCH_PUBLIC_PROMOTIONS_START = 'FETCH_PUBLIC_PROMOTIONS_START';
export const FETCH_PUBLIC_PROMOTIONS_SUCCESS =
  'FETCH_PUBLIC_PROMOTIONS_SUCCESS';
export const FETCH_PUBLIC_PROMOTIONS_FAIL = 'FETCH_PUBLIC_PROMOTIONS_FAIL';

export const CREATE_PROMOTION_START = 'CREATE_PROMOTION_START';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAIL = 'CREATE_PROMOTION_FAIL';

export const UPDATE_PROMOTION_START = 'UPDATE_PROMOTION_START';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAIL = 'UPDATE_PROMOTION_FAIL';

export const TOGGLE_PROMOTION_STATUS_START = 'TOGGLE_PROMOTION_STATUS_START';
export const TOGGLE_PROMOTION_STATUS_SUCCESS =
  'TOGGLE_PROMOTION_STATUS_SUCCESS';
export const TOGGLE_PROMOTION_STATUS_FAIL = 'TOGGLE_PROMOTION_STATUS_FAIL';

export const RESET_CREATED_PROMOTION_STORE = 'RESET_CREATED_PROMOTION_STORE';
export const RESET_PROMOTION_STORE = 'RESET_PROMOTION_STORE';

export const LOGOUT = 'LOGOUT';

export type PromotionActionTypes =
  | typeof FETCH_PROMOTIONS_LIST_START
  | typeof FETCH_PROMOTIONS_LIST_SUCCESS
  | typeof FETCH_PROMOTIONS_LIST_FAIL
  | typeof FETCH_PUBLIC_PROMOTIONS_START
  | typeof FETCH_PUBLIC_PROMOTIONS_SUCCESS
  | typeof FETCH_PUBLIC_PROMOTIONS_FAIL
  | typeof CREATE_PROMOTION_START
  | typeof CREATE_PROMOTION_SUCCESS
  | typeof CREATE_PROMOTION_FAIL
  | typeof UPDATE_PROMOTION_START
  | typeof UPDATE_PROMOTION_SUCCESS
  | typeof UPDATE_PROMOTION_FAIL
  | typeof TOGGLE_PROMOTION_STATUS_START
  | typeof TOGGLE_PROMOTION_STATUS_SUCCESS
  | typeof TOGGLE_PROMOTION_STATUS_FAIL
  | typeof RESET_CREATED_PROMOTION_STORE
  | typeof RESET_PROMOTION_STORE
  | typeof LOGOUT;
