import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { TopBarHighlight } from '../../domain/TopBarHighlight';

export const fetchTopBarHighlightsListStart = () => ({
  type: actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_START,
});

export const fetchTopBarHighlightsListSuccess = (
  topBarHighlightsList: ListResults<TopBarHighlight>,
) => ({
  type: actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_SUCCESS,
  topBarHighlightsList,
});

export const fetchTopBarHighlightsListFail = (
  topBarHighlightsListError: HttpError,
) => ({
  type: actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL,
  topBarHighlightsListError,
});

export const fetchEnabledTopBarHighlightsStart = () => ({
  type: actionTypes.FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_START,
});

export const fetchEnabledTopBarHighlightsSuccess = (
  enabledTopBarHighlights: TopBarHighlight[],
) => ({
  type: actionTypes.FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_SUCCESS,
  enabledTopBarHighlights,
});

export const fetchEnabledTopBarHighlightsFail = (
  enabledTopBarHighlightsError: HttpError,
) => ({
  type: actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL,
  enabledTopBarHighlightsError,
});

export const createTopBarHighlightStart = () => ({
  type: actionTypes.CREATE_TOP_BAR_HIGHLIGHT_START,
});

export const createTopBarHighlightSuccess = (
  createdTopBarHighlight: TopBarHighlight,
) => ({
  type: actionTypes.CREATE_TOP_BAR_HIGHLIGHT_SUCCESS,
  createdTopBarHighlight,
});

export const createTopBarHighlightFail = (
  topBarHighlightCreateError: HttpError,
) => ({
  type: actionTypes.CREATE_TOP_BAR_HIGHLIGHT_FAIL,
  topBarHighlightCreateError,
});

export const viewTopBarHighlightStart = () => ({
  type: actionTypes.VIEW_TOP_BAR_HIGHLIGHT_START,
});

export const viewTopBarHighlightSuccess = () => ({
  type: actionTypes.VIEW_TOP_BAR_HIGHLIGHT_SUCCESS,
});

export const viewTopBarHighlightFail = () => ({
  type: actionTypes.VIEW_TOP_BAR_HIGHLIGHT_FAIL,
});

export const updateTopBarHighlightStart = () => ({
  type: actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_START,
});

export const updateTopBarHighlightSuccess = () => ({
  type: actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_SUCCESS,
});

export const updateTopBarHighlightFail = (
  topBarHighlightUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_FAIL,
  topBarHighlightUpdateError,
});

export const deleteTopBarHighlightStart = () => ({
  type: actionTypes.DELETE_TOP_BAR_HIGHLIGHT_START,
});

export const deleteTopBarHighlightSuccess = () => ({
  type: actionTypes.DELETE_TOP_BAR_HIGHLIGHT_SUCCESS,
});

export const deleteTopBarHighlightFail = (
  topBarHighlightDeleteError: HttpError,
) => ({
  type: actionTypes.DELETE_TOP_BAR_HIGHLIGHT_FAIL,
  topBarHighlightDeleteError,
});

export const resetCreatedTopBarHighlightStore = () => ({
  type: actionTypes.RESET_CREATED_TOP_BAR_HIGHLIGHT_STORE,
});

export const resetTopBarHighlightStore = () => ({
  type: actionTypes.RESET_TOP_BAR_HIGHLIGHT_STORE,
});

export const completelyResetTopBarHighlightStore = () => ({
  type: actionTypes.LOGOUT,
});
