import { Asset } from './Asset';

export interface TopBarHighlight {
  id: number;
  title: string;
  shortTitle: string;
  promotionName: string;
  ctaText: string;
  ctaLink: string;
  isNewTab: boolean;
  isEnabled: boolean;
  type: TopBarHighlightType;
  customType: TopBarHighlightCustomType;
  content: string;
  videoUrl: string;
  image: Asset;
}

export enum TopBarHighlightType {
  // eslint-disable-next-line no-unused-vars
  VIDEO = 'VIDEO',
  // eslint-disable-next-line no-unused-vars
  IMAGE = 'IMAGE',
  // eslint-disable-next-line no-unused-vars
  TEXT = 'TEXT',
}

export enum TopBarHighlightCustomType {
  // eslint-disable-next-line no-unused-vars
  INTRO_VIDEO = 'INTRO_VIDEO',
}
