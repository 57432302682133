import React, { ReactElement, ReactNode } from 'react';
import styles from './BalancePopover.module.scss';
import { Tooltip } from '@mui/material';
import bricksIcon from '../../assets/icons/bricks.svg';
import { ReactSVG } from 'react-svg';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { getFormattedAmount } from '../../utility/formattedAmount/formattedAmount';

export type Props = {
  label?: string;
  icon?: ReactElement;
  children?: ReactNode;
  isOpen?: boolean;
  isBrick?: boolean;
  amount: number;
};

export const BalancePopover = ({
  label,
  icon,
  children,
  isOpen,
  isBrick,
  amount,
}: Props) => {
  const intl = useIntl();

  const getLabelWithIcon = () => {
    if (isBrick) {
      return (
        <div className={cx(styles.labelWithIconContainer, styles.bricksColor)}>
          <div className={styles.iconContainer}>
            <ReactSVG src={bricksIcon} />
          </div>
          {getFormattedAmount(amount)}{' '}
          {translate(intl, 'BALANCE_POPOVER.BRICKS')}
        </div>
      );
    }

    return (
      <div className={styles.labelWithIconContainer}>
        <div className={styles.iconContainer}>{icon}</div> {label}
      </div>
    );
  };

  const getBorderColor = () => {
    if (isBrick) {
      return '#8C2ADC';
    }

    return '#DADADA';
  };

  return (
    <Tooltip
      title={isBrick ? getLabelWithIcon() : label}
      open={isOpen}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            color: '#4F868E',
            padding: '0.62rem',
            fontSize: '1.125rem',
            border: `.0625rem solid ${getBorderColor()}`,
            borderRadius: '0.375rem',
            fontFamily: 'Poppins',
            fontWeight: '500',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              border: `.0625rem solid ${getBorderColor()}`,
              backgroundColor: 'white',
            },
          },
        },
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
