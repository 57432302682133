import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  SORT_ASC,
  SORT_DESC,
  SortBy,
  SortDirection,
} from '../../hooks/useList/useList';
import {
  TableContainer,
  Table as MuiTable,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  IconButton,
  Collapse,
  TableHead,
} from '@mui/material';
import Modal from '../Modal/Modal';
import ActionsPopover from '../ActionsPopover/ActionsPopover';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import Loader from '../Loader/Loader';
import {
  Reorder as ReorderIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { ActionType } from '../../domain/Common';

export type TableHeader = {
  sortBy: string | null;
  label: string | null;
};

export type TableRow = {
  [key: string]: ReactNode;
};

export type TablePriorityUpdate = {
  id: number | string;
  priority: number;
};

export type TableProps = {
  className: string;
  headers?: Array<TableHeader>;
  rows: Array<TableRow>;
  onSort: (sortBy: SortBy, sortDirection: SortDirection) => void;
  sortDirection: SortDirection;
  sortBy: string | null;
  confirmationMessage?: string;
  isDraggable?: boolean;
  onDragDrop?: (updatedPriorities: TablePriorityUpdate[]) => void;
  isLoading?: boolean;
  isUpdateNeeded?: boolean;
};

const Table = ({
  className,
  headers,
  rows,
  onSort,
  sortDirection,
  sortBy,
  confirmationMessage,
  isDraggable,
  onDragDrop,
  isLoading,
  isUpdateNeeded,
}: TableProps): ReactElement => {
  const intl = useIntl();

  const expandedRowsInit = rows
    .filter((row) => !!row.subTable && !!row.expanded)
    .map((x, index) => index.toString());
  const [expandedRows, setExpandedRows] = useState<string[]>(expandedRowsInit);
  const [selectedSingleAction, setSelectedSingleAction] = useState<any | null>(
    null,
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  useEffect(() => {
    if (isUpdateNeeded) {
      setIsConfirmationOpen(false);
    }
  }, [isUpdateNeeded]);

  const handleHeaderClick = (clickedSortBy: string) => {
    if (clickedSortBy !== sortBy) {
      onSort(clickedSortBy, SORT_ASC);
      return;
    }

    if (sortDirection === SORT_ASC) {
      onSort(clickedSortBy, SORT_DESC);
      return;
    }

    if (sortDirection === SORT_DESC) {
      onSort(clickedSortBy, SORT_ASC);
    }
  };

  const handleExpandClick = (id: string) =>
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((row) => row !== id) : [...prev, id],
    );

  const handlePopoverListItemClick = (singleAction: any) => {
    if (
      (singleAction.type === ActionType.DELETE ||
        singleAction.type === ActionType.STATUS) &&
      !singleAction.actionOutside
    ) {
      setSelectedSingleAction(singleAction);
      setIsConfirmationOpen(true);
      return;
    }

    singleAction?.onClick();
  };

  const renderActions = (actions: any) => {
    if (!Array.isArray(actions)) {
      return [];
    }

    return actions.map((singleAction) => ({
      type: singleAction.type,
      data: singleAction.data,
      hidden: singleAction.hidden,
      action: () => handlePopoverListItemClick(singleAction),
      label: singleAction.actionLabel,
      actionIcon: singleAction.actionIcon,
      disabled: singleAction.disabled,
    }));
  };

  const handleDragDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const prioritizedRows = [...rows];

    const src = result.source.index;
    const dest = result.destination.index;

    prioritizedRows.splice(
      dest < src ? dest : dest + 1,
      0,
      prioritizedRows[src],
    );
    prioritizedRows.splice(dest < src ? src + 1 : src, 1);

    onDragDrop &&
      onDragDrop(
        prioritizedRows.map((item, index) => ({
          id: item.uuid ? parseInt(item.uuid.toString()) : 0,
          priority: index,
        })),
      );
  };

  const hasSubTable = !!rows.find((row) => !!row.subTable);

  if (isLoading) {
    return <Loader isLoading={isLoading} height={'300'} />;
  }

  return (
    <TableContainer>
      <MuiTable className={className}>
        {headers && (
          <TableHead>
            <TableRow>
              {hasSubTable && !!headers.length && <TableCell />}
              {headers.map((header, index) => (
                <TableCell key={header.toString() + index}>
                  <TableSortLabel
                    direction={sortDirection === SORT_ASC ? 'asc' : 'desc'}
                    active={!!header.sortBy && header.sortBy === sortBy}
                    disabled={!header.sortBy}
                    onClick={() =>
                      header.sortBy && handleHeaderClick(header.sortBy)
                    }
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <DragDropContext onDragEnd={handleDragDrop}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <TableBody
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {rows.map((row, index) => (
                  <Draggable
                    key={`draggable-item-row-${row.toString() + index}`}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!isDraggable}
                  >
                    {(
                      draggableProvided: DraggableProvided,
                      snapshot: DraggableStateSnapshot,
                    ) => (
                      <Fragment>
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            background: snapshot.isDragging
                              ? 'rgba(245,245,245, 0.75)'
                              : 'none',
                            display: 'table-row',
                          }}
                        >
                          <>
                            {isDraggable && (
                              <TableCell>
                                <div {...draggableProvided.dragHandleProps}>
                                  <ReorderIcon />
                                </div>
                              </TableCell>
                            )}
                            {hasSubTable && (
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    handleExpandClick(index.toString())
                                  }
                                >
                                  {expandedRows.includes(index.toString()) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            )}
                            {Object.keys(row)
                              .filter(
                                (cell) =>
                                  cell !== 'subTable' &&
                                  cell !== 'hidden' &&
                                  cell !== 'expanded' &&
                                  cell !== 'uuid',
                              )
                              .map((cell, cellIndex) => (
                                <TableCell
                                  key={
                                    'table-cell' +
                                    cell.toString() +
                                    cellIndex.toString()
                                  }
                                >
                                  {cell === 'action' ? (
                                    <ActionsPopover
                                      actions={renderActions(row[cell])}
                                    />
                                  ) : (
                                    row[cell]
                                  )}
                                </TableCell>
                              ))}
                          </>
                        </TableRow>
                        {row.subTable && (
                          <TableRow key={`subTable-${row.toString() + index}`}>
                            <TableCell
                              colSpan={Object.keys(row).length}
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                            >
                              <Collapse
                                in={expandedRows.includes(
                                  index.toString() ?? '',
                                )}
                                timeout="auto"
                                unmountOnExit
                              >
                                {row.subTable}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </MuiTable>
      <Modal
        onConfirm={selectedSingleAction?.onClick}
        onClose={() => setIsConfirmationOpen(false)}
        isLoading={false}
        isOpen={isConfirmationOpen}
        explanationText={confirmationMessage}
        title={
          selectedSingleAction?.title
            ? `${selectedSingleAction.title} ${
                selectedSingleAction.field ?? ''
              }`
            : `${translate(intl, 'TABLE.DELETE')} "${
                selectedSingleAction?.field
              }"`
        }
      />
    </TableContainer>
  );
};

Table.defaultProps = {
  className: '',
  headers: [],
  onSort: () => {},
  rows: [],
  sortDirection: SORT_ASC,
  sortBy: null,
};

export default Table;
