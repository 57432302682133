import { IntlShape } from 'react-intl';
import axios from '../../config/Axios/axios-instance';
import {
  changeLanguageStatusFail,
  changeLanguageStatusStart,
  changeLanguageStatusSuccess,
  fetchLanguageFail,
  fetchLanguagesFail,
  fetchLanguagesListFail,
  fetchLanguagesListStart,
  fetchLanguagesListSuccess,
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguageStart,
  fetchLanguageSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { ListParams } from '../../hooks/useList/useList';

const API_URL = '/languages';

export const fetchLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchLanguageStart());
  return axios
    .get(`${API_URL}/${locale}`)
    .then((response) => {
      dispatch(fetchLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguageFail(err?.response?.data?.error));
    });
};

export const fetchLanguages = () => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesFail(err?.response?.data?.error));
    });
};

export const changeLanguageStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(changeLanguageStatusStart());
    return axios
      .patch(`${API_URL}/${id}/toggle-status`)
      .then(() => {
        dispatch(changeLanguageStatusSuccess());
        showToast(translate(intl, 'SUCCESS.LANGUAGE_STATUS_CHANGE'), 'success');
      })
      .catch((err) => {
        dispatch(changeLanguageStatusFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchLanguagesList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchLanguagesListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchLanguagesListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLanguagesListFail(err?.response?.data?.error));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
