import React from 'react';
import styles from './MinimizedSubSubItem.module.scss';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { SubNavigationItem } from '../../../Layout/Layout';
import { Button } from '@mui/material';

type Props = {
  subNavigation: SubNavigationItem;
  onClose: () => void;
};

const MinimizedSubSubItem = ({ subNavigation, onClose }: Props) => {
  const currentUrlSegments = window.location.href.split('/');

  const targetUrlSegments = subNavigation.to.split('/');

  const isCurrentPageActive =
    currentUrlSegments[currentUrlSegments.length - 1] ===
    targetUrlSegments[targetUrlSegments.length - 1];

  return (
    <Button sx={{ padding: 0 }}>
      <NavLink
        onClick={() => onClose()}
        to={subNavigation.to}
        className={cx(styles.container, {
          [styles.activeSubItem]: isCurrentPageActive,
        })}
      >
        <p className={styles.label}>{subNavigation.label}</p>
      </NavLink>
    </Button>
  );
};

export default MinimizedSubSubItem;
