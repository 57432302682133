import { Category } from './Category';
import { User } from './User';
import { Asset } from './Asset';
import { ProductReview } from './ProductReview';
import { Theme } from './Theme';
import { ProductPromotion } from './ProductPromotion';
import { BigBuyItem } from './BigBuyItem';

export interface Product {
  id: string;
  title: string;
  description: string;
  specification: string;
  bricks: number;
  numberOfParts: number;
  legoSetNumber: string;
  creationTime: number;
  createdAt: Date;
  category: Category;
  categoryId: number;
  themeId: number;
  theme: Theme;
  type: ProductType;
  tier: ProductTier;
  user: User;
  userId: number;
  images: Asset[];
  externalId: string | null;
  status: ProductStatus;
  studioIo?: Asset;
  instructions?: Asset;
  additionalInstructions?: Asset;
  parts?: Asset;
  ldr?: Asset;
  reviews: ProductReview[];
  productPromotions: ProductPromotion[];
  relatedProducts: Product[];
  provider: ProductProvider;
  stock: number;
  searchType?: SearchResultType;
  deletedAt?: Date;
  isDynamic: boolean;
  bigBuyItem?: BigBuyItem;
  bigBuyItemId?: string;
}

export interface SetProduct {
  id: string;
  title: string;
}

export enum ProductProvider {
  // eslint-disable-next-line no-unused-vars
  BIG_BUY = 'BIG_BUY',
}

export enum SearchResultType {
  // eslint-disable-next-line no-unused-vars
  SETS = 'SETS',
  // eslint-disable-next-line no-unused-vars
  BIG_BUY_PRODUCT = 'BIG_BUY_PRODUCT',
}

export enum ProductType {
  // eslint-disable-next-line no-unused-vars
  MOC = 'MOC',
  // eslint-disable-next-line no-unused-vars
  ALTERNATE = 'ALTERNATE',
  // eslint-disable-next-line no-unused-vars
  MOC_WITH_PARTS = 'MOC_WITH_PARTS',
  // eslint-disable-next-line no-unused-vars
  SET = 'SET',
  // eslint-disable-next-line no-unused-vars
  BUNDLE = 'BUNDLE',
}

export enum ProductStatus {
  // eslint-disable-next-line no-unused-vars
  DRAFT = 'DRAFT',
  // eslint-disable-next-line no-unused-vars
  PUBLISHED = 'PUBLISHED',
}

export enum ProductTier {
  // eslint-disable-next-line no-unused-vars
  CLASSIC = 'CLASSIC',
  // eslint-disable-next-line no-unused-vars
  BRONZE = 'BRONZE',
  // eslint-disable-next-line no-unused-vars
  SILVER = 'SILVER',
  // eslint-disable-next-line no-unused-vars
  GOLD = 'GOLD',
  // eslint-disable-next-line no-unused-vars
  PLATINUM = 'PLATINUM',
  // eslint-disable-next-line no-unused-vars
  DIAMOND = 'DIAMOND',
  // eslint-disable-next-line no-unused-vars
  LEGENDARY = 'LEGENDARY',
}

export enum ProductPriceRange {
  // eslint-disable-next-line no-unused-vars
  BELOW_5K = 'BELOW_5K',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_5K_AND_10K = 'BETWEEN_5K_AND_10K',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_10K_AND_20K = 'BETWEEN_10K_AND_20K',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_20K_AND_40K = 'BETWEEN_20K_AND_40K',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_40K_AND_80K = 'BETWEEN_40K_AND_80K',
  // eslint-disable-next-line no-unused-vars
  ABOVE_80K = 'ABOVE_80K',
}

export enum ProductPartsRange {
  // eslint-disable-next-line no-unused-vars
  BETWEEN_1_AND_100 = 'BETWEEN_1_AND_100',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_100_AND_200 = 'BETWEEN_100_AND_200',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_200_AND_300 = 'BETWEEN_200_AND_300',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_300_AND_400 = 'BETWEEN_300_AND_400',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_400_AND_500 = 'BETWEEN_400_AND_500',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_500_AND_750 = 'BETWEEN_500_AND_750',
  // eslint-disable-next-line no-unused-vars
  BETWEEN_750_AND_1K = 'BETWEEN_750_AND_1K',
  // eslint-disable-next-line no-unused-vars
  ABOVE_1K = 'ABOVE_1K',
}
