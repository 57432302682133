export const FETCH_PAYMENT_LIST_START = 'FETCH_PAYMENT_LIST_START';
export const FETCH_PAYMENT_LIST_SUCCESS = 'FETCH_PAYMENT_LIST_SUCCESS';
export const FETCH_PAYMENT_LIST_FAIL = 'FETCH_PAYMENT_LIST_FAIL';

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';
export const RESET_CREATE_PAYMENT = 'RESET_CREATE_PAYMENT';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENT_LIST_START
  | typeof FETCH_PAYMENT_LIST_SUCCESS
  | typeof FETCH_PAYMENT_LIST_FAIL
  | typeof RESET_CREATE_PAYMENT
  | typeof CREATE_PAYMENT_START
  | typeof CREATE_PAYMENT_SUCCESS
  | typeof CREATE_PAYMENT_FAIL
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
