export const FETCH_PACKAGE_LIST_START = 'FETCH_PACKAGE_LIST_START';
export const FETCH_PACKAGE_LIST_SUCCESS = 'FETCH_PACKAGE_LIST_SUCCESS';
export const FETCH_PACKAGE_LIST_FAIL = 'FETCH_PACKAGE_LIST_FAIL';

export const FETCH_PACKAGE_START = 'FETCH_PACKAGE_START';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAIL = 'FETCH_PACKAGE_FAIL';

export const FETCH_ALL_PACKAGES_START = 'FETCH_ALL_PACKAGES_START';
export const FETCH_ALL_PACKAGES_SUCCESS = 'FETCH_ALL_PACKAGES_SUCCESS';
export const FETCH_ALL_PACKAGES_FAIL = 'FETCH_ALL_PACKAGES_FAIL';

export const CREATE_PACKAGE_START = 'CREATE_PACKAGE_START';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAIL = 'CREATE_PACKAGE_FAIL';

export const UPDATE_PACKAGE_START = 'UPDATE_PACKAGE_START';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';

export const DELETE_PACKAGE_START = 'DELETE_PACKAGE_START';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAIL = 'DELETE_PACKAGE_FAIL';

export const RESET_CREATED_PACKAGE_STORE = 'RESET_CREATED_PACKAGE_STORE';
export const RESET_PACKAGE_STORE = 'RESET_PACKAGE_STORE';

export const LOGOUT = 'LOGOUT';

export type PackageActionTypes =
  | typeof FETCH_PACKAGE_LIST_START
  | typeof FETCH_PACKAGE_LIST_SUCCESS
  | typeof FETCH_PACKAGE_LIST_FAIL
  | typeof FETCH_PACKAGE_START
  | typeof FETCH_PACKAGE_SUCCESS
  | typeof FETCH_PACKAGE_FAIL
  | typeof FETCH_ALL_PACKAGES_START
  | typeof FETCH_ALL_PACKAGES_SUCCESS
  | typeof FETCH_ALL_PACKAGES_FAIL
  | typeof CREATE_PACKAGE_START
  | typeof CREATE_PACKAGE_SUCCESS
  | typeof CREATE_PACKAGE_FAIL
  | typeof UPDATE_PACKAGE_START
  | typeof UPDATE_PACKAGE_SUCCESS
  | typeof UPDATE_PACKAGE_FAIL
  | typeof DELETE_PACKAGE_START
  | typeof DELETE_PACKAGE_SUCCESS
  | typeof DELETE_PACKAGE_FAIL
  | typeof RESET_CREATED_PACKAGE_STORE
  | typeof RESET_PACKAGE_STORE
  | typeof LOGOUT;
