export const FETCH_CONTENT_LIST_START = 'FETCH_CONTENT_LIST_START';
export const FETCH_CONTENT_LIST_SUCCESS = 'FETCH_CONTENT_LIST_SUCCESS';
export const FETCH_CONTENT_LIST_FAIL = 'FETCH_CONTENT_LIST_FAIL';

export const FETCH_PUBLIC_CONTENT_LIST_START =
  'FETCH_PUBLIC_CONTENT_LIST_START';
export const FETCH_PUBLIC_CONTENT_LIST_SUCCESS =
  'FETCH_PUBLIC_CONTENT_LIST_SUCCESS';
export const FETCH_PUBLIC_CONTENT_LIST_FAIL = 'FETCH_PUBLIC_CONTENT_LIST_FAIL';

export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAIL = 'FETCH_CONTENT_FAIL';

export const CREATE_CONTENT_START = 'CREATE_CONTENT_START';
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const CREATE_CONTENT_FAIL = 'CREATE_CONTENT_FAIL';

export const UPDATE_CONTENT_START = 'UPDATE_CONTENT_START';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAIL = 'UPDATE_CONTENT_FAIL';

export const DELETE_CONTENT_START = 'DELETE_CONTENT_START';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';

export const RESET_CREATED_CONTENT_STORE = 'RESET_CREATED_CONTENT_STORE';
export const RESET_CONTENT_STORE = 'RESET_CONTENT_STORE';

export const LOGOUT = 'LOGOUT';

export type ContentActionTypes =
  | typeof FETCH_CONTENT_LIST_START
  | typeof FETCH_CONTENT_LIST_SUCCESS
  | typeof FETCH_CONTENT_LIST_FAIL
  | typeof FETCH_PUBLIC_CONTENT_LIST_START
  | typeof FETCH_PUBLIC_CONTENT_LIST_SUCCESS
  | typeof FETCH_PUBLIC_CONTENT_LIST_FAIL
  | typeof FETCH_CONTENT_START
  | typeof FETCH_CONTENT_SUCCESS
  | typeof FETCH_CONTENT_FAIL
  | typeof CREATE_CONTENT_START
  | typeof CREATE_CONTENT_SUCCESS
  | typeof CREATE_CONTENT_FAIL
  | typeof UPDATE_CONTENT_START
  | typeof UPDATE_CONTENT_SUCCESS
  | typeof UPDATE_CONTENT_FAIL
  | typeof DELETE_CONTENT_START
  | typeof DELETE_CONTENT_SUCCESS
  | typeof DELETE_CONTENT_FAIL
  | typeof RESET_CREATED_CONTENT_STORE
  | typeof RESET_CONTENT_STORE
  | typeof LOGOUT;
