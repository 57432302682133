import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { InvoiceActionTypes } from './actionTypes';

export type InvoiceStateType = {
  downloadInvoiceLoading: boolean;
  downloadInvoiceError: HttpError;
};

export type InvoiceActionType = InvoiceStateType & {
  type: InvoiceActionTypes;
};

export const initialState: InvoiceStateType = {
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
};

const downloadInvoiceStart = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  downloadInvoiceLoading: true,
});

const downloadInvoiceSuccess = (state: InvoiceStateType): InvoiceStateType => ({
  ...state,
  downloadInvoiceLoading: false,
  downloadInvoiceError: null,
});

const downloadInvoiceFail = (
  state: InvoiceStateType,
  action: InvoiceActionType,
): InvoiceStateType => ({
  ...state,
  downloadInvoiceError: action.downloadInvoiceError,
  downloadInvoiceLoading: false,
});

const completelyResetInvoiceStore = (): InvoiceStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: InvoiceActionType) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_INVOICE_START:
      return downloadInvoiceStart(state);
    case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
      return downloadInvoiceSuccess(state);
    case actionTypes.DOWNLOAD_INVOICE_FAIL:
      return downloadInvoiceFail(state, action);
    case actionTypes.LOGOUT:
      return completelyResetInvoiceStore();
    default:
      return state;
  }
};

export default reducer;
