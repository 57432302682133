import * as actionTypes from './actionTypes';
import { PaymentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export type PaymentStateType = {
  paymentList: ListResults<Payment> | null;
  paymentListLoading: boolean;
  paymentListError: HttpError;
  paymentListUpdateNeeded: boolean;
  paymentCreateLoading: boolean;
  paymentCreateError: HttpError;
  redirectUrl: string | null;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentList: null,
  paymentListLoading: true,
  paymentListError: null,
  paymentListUpdateNeeded: false,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: null,
};

const fetchPaymentListStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentListLoading: true,
});

const fetchPaymentListSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentListLoading: false,
  paymentList: action.paymentList,
  paymentListUpdateNeeded: false,
});

const fetchPaymentListFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentListLoading: false,
  paymentListError: action.paymentListError,
});

const createPaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCreateLoading: true,
});

const createPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: action.redirectUrl,
});

const createPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: action.paymentCreateError,
});

const resetCreatePayment = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  redirectUrl: null,
  paymentCreateError: null,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const completelyResetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_LIST_START:
      return fetchPaymentListStart(state);
    case actionTypes.FETCH_PAYMENT_LIST_SUCCESS:
      return fetchPaymentListSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_LIST_FAIL:
      return fetchPaymentListFail(state, action);
    case actionTypes.CREATE_PAYMENT_START:
      return createPaymentStart(state);
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return createPaymentSuccess(state, action);
    case actionTypes.CREATE_PAYMENT_FAIL:
      return createPaymentFail(state, action);
    case actionTypes.RESET_CREATE_PAYMENT:
      return resetCreatePayment(state);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return completelyResetPaymentStore();
    default:
      return state;
  }
};

export default reducer;
