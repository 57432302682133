export const REQUEST_USER_ACCOUNT_DELETION_START =
  'REQUEST_USER_ACCOUNT_DELETION_START';
export const REQUEST_USER_ACCOUNT_DELETION_SUCCESS =
  'REQUEST_USER_ACCOUNT_DELETION_SUCCESS';
export const REQUEST_USER_ACCOUNT_DELETION_FAIL =
  'REQUEST_USER_ACCOUNT_DELETION_FAIL';

export const RESET_USER_REQUEST_STORE = 'RESET_USER_REQUEST_STORE';

export const LOGOUT = 'LOGOUT';

export type UserRequestActionTypes =
  | typeof REQUEST_USER_ACCOUNT_DELETION_START
  | typeof REQUEST_USER_ACCOUNT_DELETION_SUCCESS
  | typeof REQUEST_USER_ACCOUNT_DELETION_FAIL
  | typeof RESET_USER_REQUEST_STORE
  | typeof LOGOUT;
