import * as actionTypes from './actionTypes';
import { CategoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Category } from '../../domain/Category';

export type CategoryStateType = {
  categoriesList: ListResults<Category> | null;
  categoriesListLoading: boolean;
  categoriesListError: HttpError;
  publicTopCategories: Category[];
  publicTopCategoriesLoading: boolean;
  publicTopCategoriesError: HttpError;
  categoriesListUpdateNeeded: boolean;
  allCategories: Category[];
  allCategoriesLoading: boolean;
  allCategoriesError: HttpError;
  allFilterCategories: Category[];
  allFilterCategoriesLoading: boolean;
  allFilterCategoriesError: HttpError;
  categoryCreateLoading: boolean;
  categoryCreateError: HttpError;
  categoryCreateSuccess: boolean;
  createdCategory: Category | null;
  categoryUpdateLoading: boolean;
  categoryUpdateError: HttpError;
  categoryUpdateSuccess: boolean;
  categoryDeleteLoading: boolean;
  categoryDeleteError: HttpError;
  categoryDeleteSuccess: boolean;
};

export type CategoryActionType = CategoryStateType & {
  type: CategoryActionTypes;
};

export const initialState: CategoryStateType = {
  categoriesList: null,
  categoriesListLoading: true,
  categoriesListError: null,
  publicTopCategories: [],
  publicTopCategoriesLoading: true,
  publicTopCategoriesError: null,
  categoriesListUpdateNeeded: false,
  allCategories: [],
  allCategoriesLoading: true,
  allCategoriesError: null,
  allFilterCategories: [],
  allFilterCategoriesLoading: true,
  allFilterCategoriesError: null,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: false,
  createdCategory: null,
  categoryUpdateLoading: false,
  categoryUpdateError: null,
  categoryUpdateSuccess: false,
  categoryDeleteLoading: false,
  categoryDeleteError: null,
  categoryDeleteSuccess: false,
};

const fetchCategoriesListStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: true,
});

const fetchCategoriesListSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: false,
  categoriesList: action.categoriesList,
  categoriesListUpdateNeeded: false,
});

const fetchCategoriesListFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoriesListLoading: false,
  categoriesListError: action.categoriesListError,
});

const fetchPublicTopCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  publicTopCategoriesLoading: true,
});

const fetchPublicTopCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicTopCategoriesLoading: false,
  publicTopCategories: action.publicTopCategories,
});

const fetchPublicTopCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  publicTopCategoriesLoading: false,
  publicTopCategoriesError: action.publicTopCategoriesError,
});

const fetchAllCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: true,
});

const fetchAllCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: false,
  allCategories: action.allCategories,
});

const fetchAllCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allCategoriesLoading: false,
  allCategoriesError: action.allCategoriesError,
});

const fetchAllFilterCategoriesStart = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  allFilterCategoriesLoading: true,
});

const fetchAllFilterCategoriesSuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allFilterCategoriesLoading: false,
  allFilterCategories: action.allFilterCategories,
});

const fetchAllFilterCategoriesFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  allFilterCategoriesLoading: false,
  allFilterCategoriesError: action.allFilterCategoriesError,
});

const createCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryCreateLoading: true,
});

const createCategorySuccess = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: null,
  categoryCreateSuccess: true,
  categoriesListUpdateNeeded: true,
});

const createCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryCreateLoading: false,
  categoryCreateError: action.categoryCreateError,
});

const updateCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: true,
});

const updateCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryUpdateError: null,
  categoryUpdateLoading: false,
  categoryUpdateSuccess: true,
  categoriesListUpdateNeeded: true,
});

const updateCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryUpdateLoading: false,
  categoryUpdateError: action.categoryUpdateError,
});

const deleteCategoryStart = (state: CategoryStateType): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: true,
});

const deleteCategorySuccess = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteSuccess: true,
  categoriesListUpdateNeeded: true,
});

const deleteCategoryFail = (
  state: CategoryStateType,
  action: CategoryActionType,
): CategoryStateType => ({
  ...state,
  categoryDeleteLoading: false,
  categoryDeleteError: action.categoryDeleteError,
});

const resetCreatedCategoryStore = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...state,
  createdCategory: null,
  categoryCreateLoading: false,
  categoryCreateError: null,
});

const resetCategoryStore = (): CategoryStateType => ({
  ...initialState,
});

const completelyResetCategoryStore = (
  state: CategoryStateType,
): CategoryStateType => ({
  ...initialState,
  publicTopCategories: state.publicTopCategories,
  publicTopCategoriesLoading: state.publicTopCategoriesLoading,
});

const reducer = (state = initialState, action: CategoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_LIST_START:
      return fetchCategoriesListStart(state);
    case actionTypes.FETCH_CATEGORIES_LIST_SUCCESS:
      return fetchCategoriesListSuccess(state, action);
    case actionTypes.FETCH_CATEGORIES_LIST_FAIL:
      return fetchCategoriesListFail(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_CATEGORIES_START:
      return fetchPublicTopCategoriesStart(state);
    case actionTypes.FETCH_PUBLIC_TOP_CATEGORIES_SUCCESS:
      return fetchPublicTopCategoriesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_CATEGORIES_FAIL:
      return fetchPublicTopCategoriesFail(state, action);
    case actionTypes.FETCH_ALL_CATEGORIES_START:
      return fetchAllCategoriesStart(state);
    case actionTypes.FETCH_ALL_CATEGORIES_SUCCESS:
      return fetchAllCategoriesSuccess(state, action);
    case actionTypes.FETCH_ALL_CATEGORIES_FAIL:
      return fetchAllCategoriesFail(state, action);
    case actionTypes.FETCH_ALL_FILTER_CATEGORIES_START:
      return fetchAllFilterCategoriesStart(state);
    case actionTypes.FETCH_ALL_FILTER_CATEGORIES_SUCCESS:
      return fetchAllFilterCategoriesSuccess(state, action);
    case actionTypes.FETCH_ALL_FILTER_CATEGORIES_FAIL:
      return fetchAllFilterCategoriesFail(state, action);
    case actionTypes.CREATE_CATEGORY_START:
      return createCategoryStart(state);
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return createCategorySuccess(state, action);
    case actionTypes.CREATE_CATEGORY_FAIL:
      return createCategoryFail(state, action);
    case actionTypes.UPDATE_CATEGORY_START:
      return updateCategoryStart(state);
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return updateCategorySuccess(state);
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return updateCategoryFail(state, action);
    case actionTypes.DELETE_CATEGORY_START:
      return deleteCategoryStart(state);
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return deleteCategorySuccess(state);
    case actionTypes.DELETE_CATEGORY_FAIL:
      return deleteCategoryFail(state, action);
    case actionTypes.RESET_CREATED_CATEGORY_STORE:
      return resetCreatedCategoryStore(state);
    case actionTypes.RESET_CATEGORY_STORE:
      return resetCategoryStore();
    case actionTypes.LOGOUT:
      return completelyResetCategoryStore(state);
    default:
      return state;
  }
};

export default reducer;
