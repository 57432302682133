import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export const resetProfileStore = () => ({
  type: actionTypes.RESET_PROFILE_STORE,
});

export const completelyResetProfileStore = () => ({
  type: actionTypes.LOGOUT,
});

export const resetBricksStatusStart = () => ({
  type: actionTypes.RESET_BRICKS_STATUS_START,
});

export const resetBricksStatusSuccess = () => ({
  type: actionTypes.RESET_BRICKS_STATUS_SUCCESS,
});

export const resetBricksStatusFail = (resetBricksStatusError: HttpError) => ({
  type: actionTypes.RESET_BRICKS_STATUS_FAIL,
  resetBricksStatusError,
});
