import { Asset } from './Asset';
import { Product } from './Product';

export interface BigBuyItem {
  id: string;
  title: string;
  description: string;
  externalId: string | null;
  externalModifiedAt: string;
  stock: number;
  sku: string;
  url: string;
  manufacturer: number;
  images: Asset[];
  createdAt: Date;
  product: Product | null;
}

export enum BigBuyItemAssigned {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  ASSIGNED = 'ASSIGNED',
  // eslint-disable-next-line no-unused-vars
  NOT_ASSIGNED = 'NOT_ASSIGNED',
}
