export const FETCH_FAQ_CATEGORIES_LIST_START =
  'FETCH_FAQ_CATEGORIES_LIST_START';
export const FETCH_FAQ_CATEGORIES_LIST_SUCCESS =
  'FETCH_FAQ_CATEGORIES_LIST_SUCCESS';
export const FETCH_FAQ_CATEGORIES_LIST_FAIL = 'FETCH_FAQ_CATEGORIES_LIST_FAIL';

export const CREATE_FAQ_CATEGORY_START = 'CREATE_FAQ_CATEGORY_START';
export const CREATE_FAQ_CATEGORY_SUCCESS = 'CREATE_FAQ_CATEGORY_SUCCESS';
export const CREATE_FAQ_CATEGORY_FAIL = 'CREATE_FAQ_CATEGORY_FAIL';

export const DELETE_FAQ_CATEGORY_START = 'DELETE_FAQ_CATEGORY_START';
export const DELETE_FAQ_CATEGORY_SUCCESS = 'DELETE_FAQ_CATEGORY_SUCCESS';
export const DELETE_FAQ_CATEGORY_FAIL = 'DELETE_FAQ_CATEGORY_FAIL';

export const FETCH_PUBLIC_FAQ_CATEGORY_START =
  'FETCH_PUBLIC_FAQ_CATEGORY_START';
export const FETCH_PUBLIC_FAQ_CATEGORY_SUCCESS =
  'FETCH_PUBLIC_FAQ_CATEGORY_SUCCESS';
export const FETCH_PUBLIC_FAQ_CATEGORY_FAIL = 'FETCH_PUBLIC_FAQ_CATEGORY_FAIL';

export const RESET_CREATED_FAQ_CATEGORY_STORE =
  'RESET_CREATED_FAQ_CATEGORY_STORE';
export const RESET_DELETED_FAQ_CATEGORY_STORE =
  'RESET_DELETED_FAQ_CATEGORY_STORE';
export const RESET_FAQ_CATEGORY_STORE = 'RESET_FAQ_CATEGORY_STORE';

export const LOGOUT = 'LOGOUT';

export type FaqCategoryActionTypes =
  | typeof FETCH_FAQ_CATEGORIES_LIST_START
  | typeof FETCH_FAQ_CATEGORIES_LIST_SUCCESS
  | typeof FETCH_FAQ_CATEGORIES_LIST_FAIL
  | typeof CREATE_FAQ_CATEGORY_START
  | typeof CREATE_FAQ_CATEGORY_SUCCESS
  | typeof CREATE_FAQ_CATEGORY_FAIL
  | typeof DELETE_FAQ_CATEGORY_START
  | typeof DELETE_FAQ_CATEGORY_SUCCESS
  | typeof DELETE_FAQ_CATEGORY_FAIL
  | typeof FETCH_PUBLIC_FAQ_CATEGORY_START
  | typeof FETCH_PUBLIC_FAQ_CATEGORY_SUCCESS
  | typeof FETCH_PUBLIC_FAQ_CATEGORY_FAIL
  | typeof RESET_CREATED_FAQ_CATEGORY_STORE
  | typeof RESET_DELETED_FAQ_CATEGORY_STORE
  | typeof RESET_FAQ_CATEGORY_STORE
  | typeof LOGOUT;
