import * as actionTypes from './actionTypes';
import { TopBarHighlightActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { TopBarHighlight } from '../../domain/TopBarHighlight';

export type TopBarHighlightStateType = {
  topBarHighlightsList: ListResults<TopBarHighlight> | null;
  topBarHighlightsListLoading: boolean;
  topBarHighlightsListError: HttpError;
  enabledTopBarHighlights: TopBarHighlight[];
  enabledTopBarHighlightsLoading: boolean;
  enabledTopBarHighlightsError: HttpError;
  topBarHighlightsListUpdateNeeded: boolean;
  topBarHighlightCreateLoading: boolean;
  topBarHighlightCreateError: HttpError;
  topBarHighlightCreateSuccess: boolean;
  createdTopBarHighlight: TopBarHighlight | null;
  topBarHighlightUpdateLoading: boolean;
  topBarHighlightUpdateError: HttpError;
  topBarHighlightUpdateSuccess: boolean;
  topBarHighlightDeleteLoading: boolean;
  topBarHighlightDeleteError: HttpError;
  topBarHighlightDeleteSuccess: boolean;
};

export type TopBarHighlightActionType = TopBarHighlightStateType & {
  type: TopBarHighlightActionTypes;
};

export const initialState: TopBarHighlightStateType = {
  topBarHighlightsList: null,
  topBarHighlightsListLoading: true,
  topBarHighlightsListError: null,
  enabledTopBarHighlights: [],
  enabledTopBarHighlightsLoading: true,
  enabledTopBarHighlightsError: null,
  topBarHighlightsListUpdateNeeded: false,
  topBarHighlightCreateLoading: false,
  topBarHighlightCreateError: null,
  topBarHighlightCreateSuccess: false,
  createdTopBarHighlight: null,
  topBarHighlightUpdateLoading: false,
  topBarHighlightUpdateError: null,
  topBarHighlightUpdateSuccess: false,
  topBarHighlightDeleteLoading: false,
  topBarHighlightDeleteError: null,
  topBarHighlightDeleteSuccess: false,
};

const fetchTopBarHighlightsListStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightsListLoading: true,
});

const fetchTopBarHighlightsListSuccess = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightsListLoading: false,
  topBarHighlightsList: action.topBarHighlightsList,
  topBarHighlightsListUpdateNeeded: false,
});

const fetchTopBarHighlightsListFail = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightsListLoading: false,
  topBarHighlightsListError: action.topBarHighlightsListError,
});

const fetchEnabledTopBarHighlightsStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  enabledTopBarHighlightsLoading: true,
});

const fetchEnabledTopBarHighlightsSuccess = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  enabledTopBarHighlightsLoading: false,
  enabledTopBarHighlights: action.enabledTopBarHighlights,
});

const fetchEnabledTopBarHighlightsFail = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  enabledTopBarHighlightsLoading: false,
  enabledTopBarHighlightsError: action.enabledTopBarHighlightsError,
});

const createTopBarHighlightStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightCreateLoading: true,
});

const createTopBarHighlightSuccess = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightCreateLoading: false,
  topBarHighlightCreateError: null,
  topBarHighlightCreateSuccess: true,
  topBarHighlightsListUpdateNeeded: true,
});

const createTopBarHighlightFail = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightCreateLoading: false,
  topBarHighlightCreateError: action.topBarHighlightCreateError,
});

const viewTopBarHighlightStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
});

const viewTopBarHighlightSuccess = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
});

const viewTopBarHighlightFail = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
});

const updateTopBarHighlightStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightUpdateLoading: true,
});

const updateTopBarHighlightSuccess = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightUpdateError: null,
  topBarHighlightUpdateLoading: false,
  topBarHighlightUpdateSuccess: true,
  topBarHighlightsListUpdateNeeded: true,
});

const updateTopBarHighlightFail = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightUpdateLoading: false,
  topBarHighlightUpdateError: action.topBarHighlightUpdateError,
});

const deleteTopBarHighlightStart = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightDeleteLoading: true,
});

const deleteTopBarHighlightSuccess = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightDeleteLoading: false,
  topBarHighlightDeleteSuccess: true,
  topBarHighlightsListUpdateNeeded: true,
});

const deleteTopBarHighlightFail = (
  state: TopBarHighlightStateType,
  action: TopBarHighlightActionType,
): TopBarHighlightStateType => ({
  ...state,
  topBarHighlightDeleteLoading: false,
  topBarHighlightDeleteError: action.topBarHighlightDeleteError,
});

const resetCreatedTopBarHighlightStore = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...state,
  createdTopBarHighlight: null,
  topBarHighlightCreateLoading: false,
  topBarHighlightCreateError: null,
});

const resetTopBarHighlightStore = (): TopBarHighlightStateType => ({
  ...initialState,
});

const completelyResetTopBarHighlightStore = (
  state: TopBarHighlightStateType,
): TopBarHighlightStateType => ({
  ...initialState,
  enabledTopBarHighlights: state.enabledTopBarHighlights,
  enabledTopBarHighlightsLoading: state.enabledTopBarHighlightsLoading,
});

const reducer = (state = initialState, action: TopBarHighlightActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_START:
      return fetchTopBarHighlightsListStart(state);
    case actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_SUCCESS:
      return fetchTopBarHighlightsListSuccess(state, action);
    case actionTypes.FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL:
      return fetchTopBarHighlightsListFail(state, action);
    case actionTypes.FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_START:
      return fetchEnabledTopBarHighlightsStart(state);
    case actionTypes.FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_SUCCESS:
      return fetchEnabledTopBarHighlightsSuccess(state, action);
    case actionTypes.FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_FAIL:
      return fetchEnabledTopBarHighlightsFail(state, action);
    case actionTypes.CREATE_TOP_BAR_HIGHLIGHT_START:
      return createTopBarHighlightStart(state);
    case actionTypes.CREATE_TOP_BAR_HIGHLIGHT_SUCCESS:
      return createTopBarHighlightSuccess(state, action);
    case actionTypes.CREATE_TOP_BAR_HIGHLIGHT_FAIL:
      return createTopBarHighlightFail(state, action);
    case actionTypes.VIEW_TOP_BAR_HIGHLIGHT_START:
      return viewTopBarHighlightStart(state);
    case actionTypes.VIEW_TOP_BAR_HIGHLIGHT_SUCCESS:
      return viewTopBarHighlightSuccess(state);
    case actionTypes.VIEW_TOP_BAR_HIGHLIGHT_FAIL:
      return viewTopBarHighlightFail(state);
    case actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_START:
      return updateTopBarHighlightStart(state);
    case actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_SUCCESS:
      return updateTopBarHighlightSuccess(state);
    case actionTypes.UPDATE_TOP_BAR_HIGHLIGHT_FAIL:
      return updateTopBarHighlightFail(state, action);
    case actionTypes.DELETE_TOP_BAR_HIGHLIGHT_START:
      return deleteTopBarHighlightStart(state);
    case actionTypes.DELETE_TOP_BAR_HIGHLIGHT_SUCCESS:
      return deleteTopBarHighlightSuccess(state);
    case actionTypes.DELETE_TOP_BAR_HIGHLIGHT_FAIL:
      return deleteTopBarHighlightFail(state, action);
    case actionTypes.RESET_CREATED_TOP_BAR_HIGHLIGHT_STORE:
      return resetCreatedTopBarHighlightStore(state);
    case actionTypes.RESET_TOP_BAR_HIGHLIGHT_STORE:
      return resetTopBarHighlightStore();
    case actionTypes.LOGOUT:
      return completelyResetTopBarHighlightStore(state);
    default:
      return state;
  }
};

export default reducer;
