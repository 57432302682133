export const FETCH_ALL_USER_LEVELS_START = 'FETCH_ALL_USER_LEVELS_START';
export const FETCH_ALL_USER_LEVELS_SUCCESS = 'FETCH_ALL_USER_LEVELS_SUCCESS';
export const FETCH_ALL_USER_LEVELS_FAIL = 'FETCH_ALL_USER_LEVELS_FAIL';

export const RESET_USER_LEVEL_STORE = 'RESET_USER_REQUEST_STORE';

export const LOGOUT = 'LOGOUT';

export type UserLevelActionTypes =
  | typeof FETCH_ALL_USER_LEVELS_START
  | typeof FETCH_ALL_USER_LEVELS_SUCCESS
  | typeof FETCH_ALL_USER_LEVELS_FAIL
  | typeof RESET_USER_LEVEL_STORE
  | typeof LOGOUT;
