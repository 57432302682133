import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { routes } from '../../..//config/Router/routes';
import { StoreState } from '../../..//config/StoreProvider/StoreProvider';
import { getLocaleUrl } from '../../../utility/url/urlHelper';
import { Locale } from '../../../domain/Translation';
import cx from 'classnames';
import styles from './LogoLink.module.scss';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import { TABLET_BREAK_POINT } from '../PublicNavigation';

type Props = {
  logoSrc: string;
  selectedLocale: Locale;
};

const LogoLink = ({ logoSrc, selectedLocale }: Props) => {
  const { width } = useWindowSize();

  return (
    <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
      <div
        className={cx(styles.logo, {
          [styles.isTablet]: width && width > TABLET_BREAK_POINT,
        })}
      >
        <ReactSVG src={logoSrc} />
      </div>
    </Link>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(LogoLink);
