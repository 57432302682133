export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAIL = 'FETCH_ALL_USERS_FAIL';

export const FETCH_USER_STATS_START = 'FETCH_USER_STATS_START';
export const FETCH_USER_STATS_SUCCESS = 'FETCH_USER_STATS_SUCCESS';
export const FETCH_USER_STATS_FAIL = 'FETCH_USER_STATS_FAIL';

export const UPDATE_USER_EMAIL_START = 'UPDATE_USER_EMAIL_START';
export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const UPDATE_USER_EMAIL_FAIL = 'UPDATE_USER_EMAIL_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_USER_BALANCE_START = 'UPDATE_USER_BALANCE_START';
export const UPDATE_USER_BALANCE_SUCCESS = 'UPDATE_USER_BALANCE_SUCCESS';
export const UPDATE_USER_BALANCE_FAIL = 'UPDATE_USER_BALANCE_FAIL';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';

export const UPDATE_USER_SOCIAL_MEDIA_START = 'UPDATE_USER_SOCIAL_MEDIA_START';
export const UPDATE_USER_SOCIAL_MEDIA_SUCCESS =
  'UPDATE_USER_SOCIAL_MEDIA_SUCCESS';
export const UPDATE_USER_SOCIAL_MEDIA_FAIL = 'UPDATE_USER_SOCIAL_MEDIA_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const USER_SELF_DELETE_START = 'USER_SELF_DELETE_START';
export const USER_SELF_DELETE_SUCCESS = 'USER_SELF_DELETE_SUCCESS';
export const USER_SELF_DELETE_FAIL = 'USER_SELF_DELETE_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const FETCH_HAS_PASSWORD_START = 'FETCH_HAS_PASSWORD_START';
export const FETCH_HAS_PASSWORD_SUCCESS = 'FETCH_HAS_PASSWORD_SUCCESS';
export const FETCH_HAS_PASSWORD_FAIL = 'FETCH_HAS_PASSWORD_FAIL';

export const REFRESH_CURRENT_USER_PROFILE_START =
  'REFRESH_CURRENT_USER_PROFILE_START';
export const REFRESH_CURRENT_USER_PROFILE_SUCCESS =
  'REFRESH_CURRENT_USER_PROFILE_SUCCESS';
export const REFRESH_CURRENT_USER_PROFILE_FAIL =
  'REFRESH_CURRENT_USER_PROFILE_FAIL';

export const CHECK_IS_USERNAME_TAKEN_START = 'CHECK_IS_USERNAME_TAKEN_START';
export const CHECK_IS_USERNAME_TAKEN_SUCCESS =
  'CHECK_IS_USERNAME_TAKEN_SUCCESS';
export const CHECK_IS_USERNAME_TAKEN_FAIL = 'CHECK_IS_USERNAME_TAKEN_FAIL';

export const FETCH_PUBLIC_USER_PROFILE_START =
  'FETCH_PUBLIC_USER_PROFILE_START';
export const FETCH_PUBLIC_USER_PROFILE_SUCCESS =
  'FETCH_PUBLIC_USER_PROFILE_SUCCESS';
export const FETCH_PUBLIC_USER_PROFILE_FAIL = 'FETCH_PUBLIC_USER_PROFILE_FAIL';

export const FETCH_PUBLIC_USER_SOCIAL_STATS_START =
  'FETCH_PUBLIC_USER_SOCIAL_STATS_START';
export const FETCH_PUBLIC_USER_SOCIAL_STATS_SUCCESS =
  'FETCH_PUBLIC_USER_SOCIAL_STATS_SUCCESS';
export const FETCH_PUBLIC_USER_SOCIAL_STATS_FAIL =
  'FETCH_PUBLIC_USER_SOCIAL_STATS_FAIL';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const RESET_UPDATE_USER_INFO = 'RESET_UPDATE_USER_INFO';

export const RESET_USER_INFO_STORE = 'RESET_USER_INFO_STORE';

export const TOGGLE_USER_LIKED_PRODUCT_START =
  'TOGGLE_USER_LIKED_PRODUCT_START';
export const TOGGLE_USER_LIKED_PRODUCT_SUCCESS =
  'TOGGLE_USER_LIKED_PRODUCT_SUCCESS';
export const TOGGLE_USER_LIKED_PRODUCT_FAIL = 'TOGGLE_USER_LIKED_PRODUCT_FAIL';

export const FETCH_INVITED_USERS_START = 'FETCH_INVITED_USERS_START';
export const FETCH_INVITED_USERS_SUCCESS = 'FETCH_INVITED_USERS_SUCCESS';
export const FETCH_INVITED_USERS_FAIL = 'FETCH_INVITED_USERS_FAIL';

export const RESET_PUBLIC_USER_PROFILE_STORE =
  'RESET_PUBLIC_USER_PROFILE_STORE';

export const FETCH_INVITED_USERS_SALES_COUNT_START =
  'FETCH_INVITED_USERS_SALES_COUNT_START';
export const FETCH_INVITED_USERS_SALES_COUNT_SUCCESS =
  'FETCH_INVITED_USERS_SALES_COUNT_SUCCESS';
export const FETCH_INVITED_USERS_SALES_COUNT_FAIL =
  'FETCH_INVITED_USERS_SALES_COUNT_FAIL';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const RESET_CART_STORE = 'RESET_CART_STORE';

export const ADD_SET_TO_USER_COLLECTION_START =
  'ADD_SET_TO_USER_COLLECTION_START';
export const ADD_SET_TO_USER_COLLECTION_SUCCESS =
  'ADD_SET_TO_USER_COLLECTION_SUCCESS';
export const ADD_SET_TO_USER_COLLECTION_FAIL =
  'ADD_SET_TO_USER_COLLECTION_FAIL';

export const REMOVE_SELF_ADDED_SET_START = 'REMOVE_SELF_ADDED_SET_START';
export const REMOVE_SELF_ADDED_SET_SUCCESS = 'REMOVE_SELF_ADDED_SET_SUCCESS';
export const REMOVE_SELF_ADDED_SET_FAIL = 'REMOVE_SELF_ADDED_SET_FAIL';

export const FETCH_USER_COLLECTION_PARTS_START =
  'FETCH_USER_COLLECTION_PARTS_START';
export const FETCH_USER_COLLECTION_PARTS_SUCCESS =
  'FETCH_USER_COLLECTION_PARTS_SUCCESS';
export const FETCH_USER_COLLECTION_PARTS_FAIL =
  'FETCH_USER_COLLECTION_PARTS_FAIL';

export const FETCH_CAN_USER_WITHDRAW_START = 'FETCH_CAN_USER_WITHDRAW_START';
export const FETCH_CAN_USER_WITHDRAW_SUCCESS =
  'FETCH_CAN_USER_WITHDRAW_SUCCESS';
export const FETCH_CAN_USER_WITHDRAW_FAIL = 'FETCH_CAN_USER_WITHDRAW_FAIL';

export const RESET_ADD_SET_TO_USER_COLLECTION_STORE =
  'RESET_ADD_SET_TO_USER_COLLECTION_STORE';

export const RESET_USER_STORE = 'RESET_USER_STORE';

export const LOGOUT = 'LOGOUT';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_CAN_USER_WITHDRAW_START
  | typeof FETCH_CAN_USER_WITHDRAW_SUCCESS
  | typeof FETCH_CAN_USER_WITHDRAW_FAIL
  | typeof FETCH_ALL_USERS_START
  | typeof FETCH_ALL_USERS_SUCCESS
  | typeof FETCH_ALL_USERS_FAIL
  | typeof USER_SELF_DELETE_START
  | typeof USER_SELF_DELETE_SUCCESS
  | typeof USER_SELF_DELETE_FAIL
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof FETCH_USER_STATS_START
  | typeof FETCH_USER_STATS_SUCCESS
  | typeof FETCH_USER_STATS_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof UPDATE_USER_EMAIL_START
  | typeof UPDATE_USER_EMAIL_SUCCESS
  | typeof UPDATE_USER_EMAIL_FAIL
  | typeof UPDATE_USER_BALANCE_START
  | typeof UPDATE_USER_BALANCE_SUCCESS
  | typeof UPDATE_USER_BALANCE_FAIL
  | typeof UPDATE_USER_PASSWORD_START
  | typeof UPDATE_USER_PASSWORD_SUCCESS
  | typeof UPDATE_USER_PASSWORD_FAIL
  | typeof DELETE_USER_START
  | typeof DELETE_USER_SUCCESS
  | typeof DELETE_USER_FAIL
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof FETCH_HAS_PASSWORD_START
  | typeof FETCH_HAS_PASSWORD_SUCCESS
  | typeof FETCH_HAS_PASSWORD_FAIL
  | typeof UPDATE_USER_INFO_START
  | typeof UPDATE_USER_INFO_SUCCESS
  | typeof UPDATE_USER_INFO_FAIL
  | typeof RESET_UPDATE_USER_INFO
  | typeof UPDATE_USER_SOCIAL_MEDIA_START
  | typeof UPDATE_USER_SOCIAL_MEDIA_SUCCESS
  | typeof UPDATE_USER_SOCIAL_MEDIA_FAIL
  | typeof CHECK_IS_USERNAME_TAKEN_START
  | typeof CHECK_IS_USERNAME_TAKEN_SUCCESS
  | typeof CHECK_IS_USERNAME_TAKEN_FAIL
  | typeof REFRESH_CURRENT_USER_PROFILE_START
  | typeof REFRESH_CURRENT_USER_PROFILE_SUCCESS
  | typeof REFRESH_CURRENT_USER_PROFILE_FAIL
  | typeof TOGGLE_USER_LIKED_PRODUCT_START
  | typeof TOGGLE_USER_LIKED_PRODUCT_SUCCESS
  | typeof TOGGLE_USER_LIKED_PRODUCT_FAIL
  | typeof FETCH_INVITED_USERS_START
  | typeof FETCH_INVITED_USERS_SUCCESS
  | typeof FETCH_INVITED_USERS_FAIL
  | typeof FETCH_PUBLIC_USER_PROFILE_START
  | typeof FETCH_PUBLIC_USER_PROFILE_SUCCESS
  | typeof FETCH_PUBLIC_USER_PROFILE_FAIL
  | typeof FETCH_PUBLIC_USER_SOCIAL_STATS_START
  | typeof FETCH_PUBLIC_USER_SOCIAL_STATS_SUCCESS
  | typeof FETCH_PUBLIC_USER_SOCIAL_STATS_FAIL
  | typeof RESET_PUBLIC_USER_PROFILE_STORE
  | typeof FETCH_INVITED_USERS_SALES_COUNT_START
  | typeof FETCH_INVITED_USERS_SALES_COUNT_SUCCESS
  | typeof FETCH_INVITED_USERS_SALES_COUNT_FAIL
  | typeof ADD_TO_CART
  | typeof REMOVE_FROM_CART
  | typeof RESET_CART_STORE
  | typeof ADD_SET_TO_USER_COLLECTION_START
  | typeof ADD_SET_TO_USER_COLLECTION_SUCCESS
  | typeof ADD_SET_TO_USER_COLLECTION_FAIL
  | typeof REMOVE_SELF_ADDED_SET_START
  | typeof REMOVE_SELF_ADDED_SET_SUCCESS
  | typeof REMOVE_SELF_ADDED_SET_FAIL
  | typeof FETCH_USER_COLLECTION_PARTS_START
  | typeof FETCH_USER_COLLECTION_PARTS_SUCCESS
  | typeof FETCH_USER_COLLECTION_PARTS_FAIL
  | typeof RESET_ADD_SET_TO_USER_COLLECTION_STORE
  | typeof RESET_USER_INFO_STORE
  | typeof RESET_USER_STORE
  | typeof LOGOUT;
