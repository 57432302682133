import * as actionTypes from './actionTypes';
import { UserLevelActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserLevel } from '../../domain/UserLevel';

export type UserLevelStateType = {
  allUserLevelsLoading: boolean;
  allUserLevels: UserLevel[];
  allUserLevelsError: HttpError;
};

export type UserLevelActionType = UserLevelStateType & {
  type: UserLevelActionTypes;
};

export const initialState: UserLevelStateType = {
  allUserLevelsLoading: false,
  allUserLevels: [],
  allUserLevelsError: null,
};

const fetchAllUserLevelsStart = (
  state: UserLevelStateType,
): UserLevelStateType => ({
  ...state,
  allUserLevelsLoading: true,
});

const fetchAllUserLevelsSuccess = (
  state: UserLevelStateType,
  action: UserLevelActionType,
): UserLevelStateType => ({
  ...state,
  allUserLevelsLoading: false,
  allUserLevels: action.allUserLevels,
  allUserLevelsError: null,
});

const fetchAllUserLevelsFail = (
  state: UserLevelStateType,
  action: UserLevelActionType,
): UserLevelStateType => ({
  ...state,
  allUserLevelsLoading: false,
  allUserLevelsError: action.allUserLevelsError,
});

const resetUserLevelStore = (
  state: UserLevelStateType,
): UserLevelStateType => ({
  ...initialState,
});

const completelyResetUserLevelStore = (): UserLevelStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserLevelActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_USER_LEVELS_START:
      return fetchAllUserLevelsStart(state);
    case actionTypes.FETCH_ALL_USER_LEVELS_SUCCESS:
      return fetchAllUserLevelsSuccess(state, action);
    case actionTypes.FETCH_ALL_USER_LEVELS_FAIL:
      return fetchAllUserLevelsFail(state, action);
    case actionTypes.RESET_USER_LEVEL_STORE:
      return resetUserLevelStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserLevelStore();
    default:
      return state;
  }
};

export default reducer;
