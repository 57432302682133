import { Avatar, Tooltip } from '@mui/material';
import styles from './ProfileSettings.module.scss';
import walletIcon from '../../../../assets/icons/wallet.svg';
import categoryIcon from '../../../../assets/icons/category.svg';
import cupIcon from '../../../../assets/icons/cup.svg';
import creationIcon from '../../../../assets/icons/creation.svg';
import analyticsIcon from '../../../../assets/icons/chart.svg';
import affiliateIcon from '../../../../assets/icons/ticket.svg';
import downloadIcon from '../../../../assets/icons/download.svg';
import logoutIcon from '../../../../assets/icons/logout.svg';
import profileIcon from '../../../../assets/icons/profile.svg';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import * as authService from '../../../../store/auth/service';
import { User } from '../../../../domain/User';
import { UserSettingsSections } from '../ProfileSection/ProfileSection';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';
import HamburgerButton from '../../../../common/PublicNavigation/HamburgerButton/HamburgerButton';
import { ReactNode, useEffect, useState } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import infoIcon from '../../../../assets/icons/info.svg';
import Modal from '../../../../common/Modal/Modal';

type Props = {
  currentUser: User | null;
  onLogout: () => void;
  activeSettings: UserSettingsSections;
};

type SettingsItem = {
  label: string;
  icon: string;
  type: UserSettingsSections;
  onClick: () => void;
};

type SettingsGroup = {
  label: string;
  labelInfo: string | ReactNode;
  settings: Array<SettingsItem>;
};

export enum VideoType {
  // eslint-disable-next-line no-unused-vars
  USER_AREA = 'USER_AREA',
  // eslint-disable-next-line no-unused-vars
  DESIGNER_AREA = 'DESIGNER_AREA',
  // eslint-disable-next-line no-unused-vars
  AFFILIATE_AREA = 'AFFILIATE_AREA',
}

const MOBILE_BREAK_POINT = 1000;

const ProfileSettings = ({ currentUser, onLogout, activeSettings }: Props) => {
  const intl = useIntl();
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoType, setVidepType] = useState<VideoType | undefined>(undefined);

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(true);
    }
  }, [width]);

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const videoStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '0.5rem',
  };

  const getModalTitle = () => {
    switch (videoType) {
      case VideoType.USER_AREA:
        return translate(intl, 'VIDEO_MODAL_TITLE.LABEL_USER_AREA');
      case VideoType.DESIGNER_AREA:
        return translate(intl, 'VIDEO_MODAL_TITLE.LABEL_DESIGNER_AREA');
      case VideoType.AFFILIATE_AREA:
        return translate(intl, 'VIDEO_MODAL_TITLE.LABEL_AFFILIATE_AREA');
      default:
        '';
    }
  };

  const getVideo = () => {
    switch (videoType) {
      case VideoType.USER_AREA:
        return 'https://brick-whiz.ams3.digitaloceanspaces.com/public/user_area_walkthrough.mp4';
      case VideoType.DESIGNER_AREA:
        return 'https://brick-whiz.ams3.digitaloceanspaces.com/public/designer_area.mp4';
      case VideoType.AFFILIATE_AREA:
        return 'https://brick-whiz.ams3.digitaloceanspaces.com/public/affiliate_area.mp4';
      default:
        '';
    }
  };

  const settingsGroups: Array<SettingsGroup> = [
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_MY_STUFF'),
      labelInfo: (
        <span>
          {translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_MY_STUFF_INFO')}
          <span
            style={videoStyle}
            onClick={() => {
              setVidepType(VideoType.USER_AREA);
              setIsVideoModalOpen(true);
            }}
          >
            {translate(intl, 'PROFILE_SETTINGS.EXPLANATION_VIDEO')}
          </span>
        </span>
      ),
      settings: [
        {
          type: UserSettingsSections.GENERAL_INFORMATION,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_GENERAL_INFORMATION'),
          icon: profileIcon,
          onClick: () => navigate(routes.profile.generalInformation),
        },
        {
          type: UserSettingsSections.MY_WALLET,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_WALLET'),
          icon: walletIcon,
          onClick: () => navigate(routes.profile.myWallet),
        },
        {
          type: UserSettingsSections.MY_COLLECTION,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_COLLECTION'),
          icon: categoryIcon,
          onClick: () => navigate(routes.profile.myCollection),
        },
        {
          type: UserSettingsSections.MY_LEVEL,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_LEVEL'),
          icon: cupIcon,
          onClick: () => navigate(routes.profile.myLevel),
        },
      ],
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_DESIGNER_STUFF'),
      labelInfo: (
        <span>
          {translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_DESIGNER_STUFF_INFO')}
          <span
            style={videoStyle}
            onClick={() => {
              setVidepType(VideoType.DESIGNER_AREA);
              setIsVideoModalOpen(true);
            }}
          >
            {translate(intl, 'PROFILE_SETTINGS.EXPLANATION_VIDEO')}
          </span>
        </span>
      ),
      settings: [
        {
          type: UserSettingsSections.MY_CREATIONS,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_MY_CREATIONS'),
          icon: creationIcon,
          onClick: () => navigate(routes.profile.myCreations.list),
        },
        {
          type: UserSettingsSections.ANALYTICS,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_ANALYTICS'),
          icon: analyticsIcon,
          onClick: () => navigate(routes.profile.analyticsTab),
        },
      ],
    },
    {
      label: translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_AFFILIATE_STUFF'),
      labelInfo: (
        <span>
          {translate(intl, 'PROFILE_SETTINGS_GROUP.LABEL_AFFILIATE_STUFF_INFO')}
          <span
            style={videoStyle}
            onClick={() => {
              setVidepType(VideoType.AFFILIATE_AREA);
              setIsVideoModalOpen(true);
            }}
          >
            {translate(intl, 'PROFILE_SETTINGS.EXPLANATION_VIDEO')}
          </span>
        </span>
      ),
      settings: [
        {
          type: UserSettingsSections.AFFILIATE,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_AFFILIATE'),
          icon: affiliateIcon,
          onClick: () => navigate(routes.profile.affiliateTab),
        },
        {
          type: UserSettingsSections.CONTENT_DOWNLOAD,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_CONTENT_DOWNLOAD'),
          icon: downloadIcon,
          onClick: () => navigate(routes.profile.contentDownload),
        },
        {
          type: UserSettingsSections.LOGOUT,
          label: translate(intl, 'PROFILE_SETTINGS.LABEL_LOG_OUT'),
          icon: logoutIcon,
          onClick: () => onLogout(),
        },
      ],
    },
  ];

  return (
    <>
      <aside className={styles.profileSettings}>
        <div
          className={cx(styles.profileIconContainer, {
            [styles.isActive]: width && width < MOBILE_BREAK_POINT,
          })}
        >
          <Avatar
            sx={{ width: '4.5rem', height: '4.5rem' }}
            src={currentUser?.profile?.profileImage?.thumbLocation ?? ''}
          />
          <h3 className={styles.profileLabel}>
            {translate(intl, 'PROFILE_SETTINGS.SETTINGS')}
          </h3>
          {width && width < MOBILE_BREAK_POINT && (
            <div className={styles.hamburgerMenu}>
              <HamburgerButton
                clickHandler={() => handleMobileMenuOpen()}
                isMobileMenuOpen={false}
              />
            </div>
          )}
        </div>
        <nav className={styles.profileNavigation}>
          {settingsGroups.map(({ label, labelInfo, settings }, index) => (
            <div
              key={label}
              className={cx(styles.profileNavigationItem, {
                [styles.isActive]: isMobileMenuOpen,
              })}
            >
              <p className={styles.settingsGroupLabel}>
                {label}{' '}
                <Tooltip title={labelInfo}>
                  <img src={infoIcon} />
                </Tooltip>
              </p>

              {settings.map(({ label, icon, onClick, type }) => (
                <div
                  key={label}
                  className={styles.settingsGroup}
                  role="button"
                  onClick={() => {
                    onClick();

                    if (width && width < MOBILE_BREAK_POINT) {
                      handleMobileMenuOpen();
                    }
                  }}
                >
                  <div
                    className={cx(styles.settingsIcon, {
                      [styles.isActive]: type === activeSettings,
                    })}
                  >
                    <ReactSVG src={icon} />
                  </div>
                  <p
                    className={cx(styles.settingsGroupText, {
                      [styles.isActive]: type === activeSettings,
                    })}
                  >
                    {label}
                  </p>
                </div>
              ))}
              {settingsGroups.length - 1 !== index && (
                <hr className={styles.settingsGroupDivider} />
              )}
            </div>
          ))}
        </nav>
      </aside>
      <Modal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        isLoading={false}
        isFull
        title={getModalTitle()}
      >
        <video controls>
          <source src={getVideo()} type="video/mp4" />
          {translate(intl, 'VIDEO_PLAYER.VIDEO_BROWSER_NOT_SUPPORTED')}
        </video>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
