import * as actionTypes from './actionTypes';
import { SearchActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { PublicSearchResults } from '../../domain/Search';

export type SearchStateType = {
  publicSearchResultsLoading: boolean;
  publicSearchResults: PublicSearchResults | null;
  publicSearchResultsError: HttpError;
};

export type SearchActionType = SearchStateType & {
  type: SearchActionTypes;
};

export const initialState: SearchStateType = {
  publicSearchResultsLoading: false,
  publicSearchResults: null,
  publicSearchResultsError: null,
};

const fetchPublicSearchResultsStart = (
  state: SearchStateType,
): SearchStateType => ({
  ...state,
  publicSearchResultsLoading: true,
});

const fetchPublicSearchResultsSuccess = (
  state: SearchStateType,
  action: SearchActionType,
): SearchStateType => ({
  ...state,
  publicSearchResultsLoading: false,
  publicSearchResults: action.publicSearchResults,
});

const fetchPublicSearchResultsFail = (
  state: SearchStateType,
  action: SearchActionType,
): SearchStateType => ({
  ...state,
  publicSearchResultsLoading: false,
  publicSearchResultsError: action.publicSearchResultsError,
});

const resetStaticPageStore = (): SearchStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SearchActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_START:
      return fetchPublicSearchResultsStart(state);
    case actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_SUCCESS:
      return fetchPublicSearchResultsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SEARCH_RESULTS_FAIL:
      return fetchPublicSearchResultsFail(state, action);
    case actionTypes.RESET_SEARCH_STORE:
      return resetStaticPageStore();
    default:
      return state;
  }
};

export default reducer;
