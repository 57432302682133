import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Package } from '../../domain/Package';

export const fetchPackageListStart = () => ({
  type: actionTypes.FETCH_PACKAGE_LIST_START,
});

export const fetchPackageListSuccess = (packageList: ListResults<Package>) => ({
  type: actionTypes.FETCH_PACKAGE_LIST_SUCCESS,
  packageList,
});

export const fetchPackageListFail = (packageListError: HttpError) => ({
  type: actionTypes.FETCH_PACKAGE_LIST_FAIL,
  packageListError,
});

export const fetchPackageStart = () => ({
  type: actionTypes.FETCH_PACKAGE_START,
});

export const fetchPackageSuccess = (creditPackage: Package) => ({
  type: actionTypes.FETCH_PACKAGE_SUCCESS,
  creditPackage,
});

export const fetchPackageFail = (packageError: HttpError) => ({
  type: actionTypes.FETCH_PACKAGE_FAIL,
  packageError,
});

export const createPackageStart = () => ({
  type: actionTypes.CREATE_PACKAGE_START,
});

export const createPackageSuccess = (createdPackage: Package) => ({
  type: actionTypes.CREATE_PACKAGE_SUCCESS,
  createdPackage,
});

export const createPackageFail = (packageCreateError: HttpError) => ({
  type: actionTypes.CREATE_PACKAGE_FAIL,
  packageCreateError,
});

export const updatePackageStart = () => ({
  type: actionTypes.UPDATE_PACKAGE_START,
});

export const updatePackageSuccess = () => ({
  type: actionTypes.UPDATE_PACKAGE_SUCCESS,
});

export const updatePackageFail = (packageUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_PACKAGE_FAIL,
  packageUpdateError,
});

export const deletePackageStart = () => ({
  type: actionTypes.DELETE_PACKAGE_START,
});

export const deletePackageSuccess = () => ({
  type: actionTypes.DELETE_PACKAGE_SUCCESS,
});

export const deletePackageFail = (packageDeleteError: HttpError) => ({
  type: actionTypes.DELETE_PACKAGE_FAIL,
  packageDeleteError,
});

export const fetchAllPackagesStart = () => ({
  type: actionTypes.FETCH_ALL_PACKAGES_FAIL,
});

export const fetchAllPackagesSuccess = (allPackages: Package[]) => ({
  type: actionTypes.FETCH_ALL_PACKAGES_SUCCESS,
  allPackages,
});

export const fetchAllPackagesFail = (allPackagesError: HttpError) => ({
  type: actionTypes.FETCH_ALL_PACKAGES_FAIL,
  allPackagesError,
});

export const resetCreatedPackageStore = () => ({
  type: actionTypes.RESET_CREATED_PACKAGE_STORE,
});

export const resetPackageStore = () => ({
  type: actionTypes.RESET_PACKAGE_STORE,
});

export const completelyResetPackageStore = () => ({
  type: actionTypes.LOGOUT,
});
