export const FETCH_FAQ_LIST_START = 'FETCH_FAQ_LIST_START';
export const FETCH_FAQ_LIST_SUCCESS = 'FETCH_FAQ_LIST_SUCCESS';
export const FETCH_FAQ_LIST_FAIL = 'FETCH_FAQ_LIST_FAIL';

export const FETCH_FAQ_START = 'FETCH_FAQ_START';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAIL = 'FETCH_FAQ_FAIL';

export const FETCH_PUBLIC_HOMEPAGE_FAQ_START =
  'FETCH_PUBLIC_HOMEPAGE_FAQ_START';
export const FETCH_PUBLIC_HOMEPAGE_FAQ_SUCCESS =
  'FETCH__PUBLIC_HOMEPAGE_FAQ_SUCCESS';
export const FETCH_PUBLIC_HOMEPAGE_FAQ_FAIL = 'FETCH_PUBLIC_HOMEPAGE_FAQ_FAIL';

export const CREATE_FAQ_START = 'CREATE_FAQ_START';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAIL = 'CREATE_FAQ_FAIL';

export const UPDATE_FAQ_START = 'UPDATE_FAQ_START';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAIL = 'UPDATE_FAQ_FAIL';

export const DELETE_FAQ_START = 'DELETE_FAQ_START';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';

export const FETCH_PUBLIC_FAQS_START = 'FETCH_PUBLIC_FAQS_START';
export const FETCH_PUBLIC_FAQS_SUCCESS = 'FETCH_PUBLIC_FAQS_SUCCESS';
export const FETCH_PUBLIC_FAQS_FAIL = 'FETCH_PUBLIC_FAQS_FAIL';

export const RESET_CREATED_FAQ_STORE = 'RESET_CREATED_FAQ_STORE';
export const RESET_FAQ_STORE = 'RESET_FAQ_STORE';

export const LOGOUT = 'LOGOUT';

export type FaqActionTypes =
  | typeof FETCH_FAQ_LIST_START
  | typeof FETCH_FAQ_LIST_SUCCESS
  | typeof FETCH_FAQ_LIST_FAIL
  | typeof FETCH_FAQ_START
  | typeof FETCH_FAQ_SUCCESS
  | typeof FETCH_FAQ_FAIL
  | typeof FETCH_PUBLIC_HOMEPAGE_FAQ_START
  | typeof FETCH_PUBLIC_HOMEPAGE_FAQ_SUCCESS
  | typeof FETCH_PUBLIC_HOMEPAGE_FAQ_FAIL
  | typeof CREATE_FAQ_START
  | typeof CREATE_FAQ_SUCCESS
  | typeof CREATE_FAQ_FAIL
  | typeof UPDATE_FAQ_START
  | typeof UPDATE_FAQ_SUCCESS
  | typeof UPDATE_FAQ_FAIL
  | typeof DELETE_FAQ_START
  | typeof DELETE_FAQ_SUCCESS
  | typeof DELETE_FAQ_FAIL
  | typeof FETCH_PUBLIC_FAQS_START
  | typeof FETCH_PUBLIC_FAQS_SUCCESS
  | typeof FETCH_PUBLIC_FAQS_FAIL
  | typeof RESET_CREATED_FAQ_STORE
  | typeof RESET_FAQ_STORE
  | typeof LOGOUT;
