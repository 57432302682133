import * as actionTypes from './actionTypes';
import { ThemeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Theme } from '../../domain/Theme';

export type ThemeStateType = {
  themesList: ListResults<Theme> | null;
  themesListLoading: boolean;
  themesListError: HttpError;
  themesListUpdateNeeded: boolean;
  filterThemes: Theme[];
  filterThemesLoading: boolean;
  filterThemesError: HttpError;
  initiateThemesFetchingLoading: boolean;
  initiateThemesFetchingError: HttpError;
  allThemes: Theme[];
  allThemesLoading: boolean;
  allThemesError: HttpError;
  themeCreateLoading: boolean;
  themeCreateError: HttpError;
  themeCreateSuccess: boolean;
  createdTheme: Theme | null;
  themeUpdateLoading: boolean;
  themeUpdateError: HttpError;
  themeUpdateSuccess: boolean;
  themeDeleteLoading: boolean;
  themeDeleteError: HttpError;
  themeDeleteSuccess: boolean;
};

export type ThemeActionType = ThemeStateType & {
  type: ThemeActionTypes;
};

export const initialState: ThemeStateType = {
  themesList: null,
  themesListLoading: true,
  themesListError: null,
  themesListUpdateNeeded: false,
  filterThemes: [],
  filterThemesLoading: true,
  filterThemesError: null,
  initiateThemesFetchingLoading: false,
  initiateThemesFetchingError: null,
  allThemes: [],
  allThemesLoading: true,
  allThemesError: null,
  themeCreateLoading: false,
  themeCreateError: null,
  themeCreateSuccess: false,
  createdTheme: null,
  themeUpdateLoading: false,
  themeUpdateError: null,
  themeUpdateSuccess: false,
  themeDeleteLoading: false,
  themeDeleteError: null,
  themeDeleteSuccess: false,
};

const fetchThemesListStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themesListLoading: true,
});

const fetchThemesListSuccess = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themesListLoading: false,
  themesList: action.themesList,
  themesListUpdateNeeded: false,
});

const fetchThemesListFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themesListLoading: false,
  themesListError: action.themesListError,
});

const initiateThemesFetchingStart = (
  state: ThemeStateType,
): ThemeStateType => ({
  ...state,
  initiateThemesFetchingLoading: true,
});

const initiateThemesFetchingSuccess = (
  state: ThemeStateType,
): ThemeStateType => ({
  ...state,
  initiateThemesFetchingLoading: false,
});

const initiateThemesFetchingFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  initiateThemesFetchingLoading: false,
  initiateThemesFetchingError: action.initiateThemesFetchingError,
});

const fetchAllThemesStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  allThemesLoading: true,
});

const fetchAllThemesSuccess = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  allThemesLoading: false,
  allThemes: action.allThemes,
});

const fetchAllThemesFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  allThemesLoading: false,
  allThemesError: action.allThemesError,
});

const fetchAllFilterThemesStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  filterThemesLoading: true,
});

const fetchAllFilterThemesSuccess = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  filterThemesLoading: false,
  filterThemes: action.filterThemes,
});

const fetchAllFilterThemesFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  filterThemesLoading: false,
  filterThemesError: action.filterThemesError,
});

const createThemeStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themeCreateLoading: true,
});

const createThemeSuccess = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themeCreateLoading: false,
  themeCreateError: null,
  themeCreateSuccess: true,
  themesListUpdateNeeded: true,
});

const createThemeFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themeCreateLoading: false,
  themeCreateError: action.themeCreateError,
});

const updateThemeStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themeUpdateLoading: true,
});

const updateThemeSuccess = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themeUpdateLoading: false,
  themeUpdateSuccess: true,
  themesListUpdateNeeded: true,
  themeUpdateError: null,
});

const updateThemeFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themeUpdateLoading: false,
  themeUpdateError: action.themeUpdateError,
});

const deleteThemeStart = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themeDeleteLoading: true,
});

const deleteThemeSuccess = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  themeDeleteLoading: false,
  themeDeleteSuccess: true,
  themesListUpdateNeeded: true,
});

const deleteThemeFail = (
  state: ThemeStateType,
  action: ThemeActionType,
): ThemeStateType => ({
  ...state,
  themeDeleteLoading: false,
  themeDeleteError: action.themeDeleteError,
});

const resetCreatedThemeStore = (state: ThemeStateType): ThemeStateType => ({
  ...state,
  createdTheme: null,
  themeCreateError: null,
  themeCreateSuccess: false,
  themeCreateLoading: false,
});

const resetThemeStore = (): ThemeStateType => ({
  ...initialState,
});

const completelyResetThemeStore = (): ThemeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ThemeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_THEMES_LIST_START:
      return fetchThemesListStart(state);
    case actionTypes.FETCH_THEMES_LIST_SUCCESS:
      return fetchThemesListSuccess(state, action);
    case actionTypes.FETCH_THEMES_LIST_FAIL:
      return fetchThemesListFail(state, action);
    case actionTypes.FETCH_ALL_FILTER_THEMES_START:
      return fetchAllFilterThemesStart(state);
    case actionTypes.FETCH_ALL_FILTER_THEMES_SUCCESS:
      return fetchAllFilterThemesSuccess(state, action);
    case actionTypes.FETCH_ALL_FILTER_THEMES_FAIL:
      return fetchAllFilterThemesFail(state, action);
    case actionTypes.INITIATE_THEMES_FETCH_START:
      return initiateThemesFetchingStart(state);
    case actionTypes.INITIATE_THEMES_FETCH_SUCCESS:
      return initiateThemesFetchingSuccess(state);
    case actionTypes.INITIATE_THEMES_FETCH_FAIL:
      return initiateThemesFetchingFail(state, action);
    case actionTypes.FETCH_ALL_THEMES_START:
      return fetchAllThemesStart(state);
    case actionTypes.FETCH_ALL_THEMES_SUCCESS:
      return fetchAllThemesSuccess(state, action);
    case actionTypes.FETCH_ALL_THEMES_FAIL:
      return fetchAllThemesFail(state, action);
    case actionTypes.CREATE_THEME_START:
      return createThemeStart(state);
    case actionTypes.CREATE_THEME_SUCCESS:
      return createThemeSuccess(state, action);
    case actionTypes.CREATE_THEME_FAIL:
      return createThemeFail(state, action);
    case actionTypes.UPDATE_THEME_START:
      return updateThemeStart(state);
    case actionTypes.UPDATE_THEME_SUCCESS:
      return updateThemeSuccess(state);
    case actionTypes.UPDATE_THEME_FAIL:
      return updateThemeFail(state, action);
    case actionTypes.DELETE_THEME_START:
      return deleteThemeStart(state);
    case actionTypes.DELETE_THEME_SUCCESS:
      return deleteThemeSuccess(state);
    case actionTypes.DELETE_THEME_FAIL:
      return deleteThemeFail(state, action);
    case actionTypes.RESET_CREATED_THEME_STORE:
      return resetCreatedThemeStore(state);
    case actionTypes.RESET_THEME_STORE:
      return resetThemeStore();
    case actionTypes.LOGOUT:
      return completelyResetThemeStore();
    default:
      return state;
  }
};

export default reducer;
