export const FETCH_WITHDRAWAL_LIST_START = 'FETCH_WITHDRAWAL_LIST_START';
export const FETCH_WITHDRAWAL_LIST_SUCCESS = 'FETCH_WITHDRAWAL_LIST_SUCCESS';
export const FETCH_WITHDRAWAL_LIST_FAIL = 'FETCH_WITHDRAWAL_LIST_FAIL';

export const REQUEST_WITHDRAWAL_START = 'REQUEST_WITHDRAWAL_START';
export const REQUEST_WITHDRAWAL_SUCCESS = 'REQUEST_WITHDRAWAL_SUCCESS';
export const REQUEST_WITHDRAWAL_FAIL = 'REQUEST_WITHDRAWAL_FAIL';
export const RESET_WITHDRAWAL_REQUEST = 'RESET_WITHDRAWAL_REQUEST';

export const UPDATE_WITHDRAWAL_STATUS_START = 'UPDATE_WITHDRAWAL_STATUS_START';
export const UPDATE_WITHDRAWAL_STATUS_SUCCESS =
  'UPDATE_WITHDRAWAL_STATUS_SUCCESS';
export const UPDATE_WITHDRAWAL_STATUS_FAIL = 'UPDATE_WITHDRAWAL_STATUS_FAIL';

export const RESET_WITHDRAWAL_STORE = 'RESET_WITHDRAWAL_STORE';

export const LOGOUT = 'LOGOUT';

export type WithdrawalActionTypes =
  | typeof FETCH_WITHDRAWAL_LIST_START
  | typeof FETCH_WITHDRAWAL_LIST_SUCCESS
  | typeof FETCH_WITHDRAWAL_LIST_FAIL
  | typeof REQUEST_WITHDRAWAL_START
  | typeof REQUEST_WITHDRAWAL_SUCCESS
  | typeof REQUEST_WITHDRAWAL_FAIL
  | typeof UPDATE_WITHDRAWAL_STATUS_START
  | typeof UPDATE_WITHDRAWAL_STATUS_SUCCESS
  | typeof UPDATE_WITHDRAWAL_STATUS_FAIL
  | typeof RESET_WITHDRAWAL_REQUEST
  | typeof RESET_WITHDRAWAL_STORE
  | typeof LOGOUT;
