import { ReactNode } from 'react';
import styles from './InfoPopover.module.scss';
import { Tooltip } from '@mui/material';

export type Props = {
  label?: string;
  children?: ReactNode;
  isOpen?: boolean;
  childrenContainerClassName?: string;
  labelChildren?: ReactNode;
};

export const InfoPopover = ({
  label,
  children,
  isOpen,
  childrenContainerClassName,
  labelChildren,
}: Props) => {
  const getLabel = () => (
    <div className={styles.label}>
      {label}
      {labelChildren}
    </div>
  );

  return (
    <Tooltip
      title={getLabel()}
      open={isOpen}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            color: '#4F868E',
            padding: '0.62rem',
            fontSize: '1.125rem',
            border: `.0625rem solid #8c2adc;`,
            borderRadius: '0.375rem',
            fontFamily: 'Poppins',
            fontWeight: '500',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              border: `.0625rem solid rgba(55, 0, 101, 1)`,
              backgroundColor: 'white',
            },
          },
        },
      }}
    >
      <div className={childrenContainerClassName}>{children}</div>
    </Tooltip>
  );
};
