import React, { useEffect, ReactNode } from 'react';
import { routes } from '../../../config/Router/routes';
import PublicNavigation from '../../PublicNavigation/PublicNavigation';
import { Footer } from '../../../component/Public/Footer/Footer';
import TagManager from 'react-gtm-module';

type Props = {
  children: ReactNode;
};

const IGNORED_ROUTES = [routes.comingSoon];

const tagManagerArgs = {
  gtmId: 'GTM-T2NRSGT7',
};

const PublicLayout = ({ children }: Props) => {
  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  if (isRouteIgnored) {
    return <>{children}</>;
  }

  return (
    <>
      <PublicNavigation />
      {children}
      <Footer />
    </>
  );
};

export default PublicLayout;
