import React, { useEffect, useState } from 'react';
import AuthContainer from '../../../component/Public/Auth/AuthContainer/AuthContainer';
import SignUpSection from '../../../component/Public/Auth/SignUpSection/SignUpSection';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setRefCode } from '../../../store/auth/actions';
import { useParams } from 'react-router-dom';

export enum SignUpSectionType {
  // eslint-disable-next-line no-unused-vars
  LOGIN = 'LOGIN',
  // eslint-disable-next-line no-unused-vars
  REGISTER = 'REGISTER',
  // eslint-disable-next-line no-unused-vars
  PASSWORD_REMIND = 'PASSWORD_REMIND',
}

type Props = {
  sectionType?: SignUpSectionType;
  onSetRefCode: (refCode: string | undefined) => void;
};

const SignUpPage = ({ sectionType, onSetRefCode }: Props) => {
  const intl = useIntl();
  const [section, setSection] = useState(
    sectionType ? sectionType : SignUpSectionType.LOGIN,
  );

  const { refCode } = useParams<{
    refCode: string | undefined;
  }>();

  useEffect(() => {
    if (refCode) {
      onSetRefCode(refCode);
    }
  }, []);

  const getTitle = () => {
    if (section === SignUpSectionType.REGISTER) {
      return translate(intl, 'SIGN_UP_PAGE.REGISTRATION_TITLE_MAIN');
    }

    return translate(intl, 'SIGN_UP_PAGE.TITLE_MAIN');
  };

  return (
    <AuthContainer
      title={getTitle()}
      onChangeSection={setSection}
      section={section}
    >
      <SignUpSection section={section} onChangeSection={setSection} />
    </AuthContainer>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetRefCode: (refCode: string | undefined) => dispatch(setRefCode(refCode)),
});

export default connect(undefined, mapDispatchToProps)(SignUpPage);
