import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './RegisterForm.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { FormInput, useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { RegistrationRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { resetRegistration } from '../../../../store/auth/actions';
import { Locale } from '../../../../domain/Translation';
import { Link, useParams } from 'react-router-dom';
import { User } from '../../../../domain/User';
import Loader from '../../../../common/Loader/Loader';
import RegisterSuccess from './RegisterSuccess/RegisterSuccess';
import Modal from '../../../../common/Modal/Modal';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';
import { routes } from '../../../../config/Router/routes';
import checkedIncon from '../../../../assets/icons/checked.svg';
import uncheckedIncon from '../../../../assets/icons/unchecked.svg';

type Props = {
  onRegister: (inputs: RegistrationRequest) => void;
  isLoading: boolean;
  error: HttpError;
  registrationSuccess: boolean;
  onResetRegistration: () => void;
  selectedLocale: Locale;
  validatedUser: null | User;
  onChangeSection: (section: SignUpSectionType) => void;
  setIsAgreedCheckbox: (value: boolean) => void;
  refCode?: string;
  showNotAgreedError?: boolean;
  setShowNotAgreedError: (state: boolean) => void;
  setIsAgreedMarketing: (state: boolean) => void;
};

type FormInputs = {
  email: string;
  password: string;
  passwordRepeat: string;
  marketing: string;
};

export const RegisterForm = ({
  onRegister,
  isLoading,
  error,
  registrationSuccess,
  onResetRegistration,
  selectedLocale,
  validatedUser,
  onChangeSection,
  setIsAgreedCheckbox,
  refCode,
  showNotAgreedError,
  setShowNotAgreedError,
  setIsAgreedMarketing,
}: Props) => {
  const intl = useIntl();
  const { code } = useParams<{ code: string | undefined }>();
  const [isLoaded, setIsLoaded] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    return () => onResetRegistration();
  }, []);

  useEffect(() => {
    if (!isLoading && (!code || (validatedUser && code))) {
      setIsLoaded(true);
    }
  }, [isLoading, validatedUser, code]);

  useEffect(() => {
    if (registrationSuccess) {
      setIsModalOpen(true);
      // @ts-ignore
      window?.dataLayer?.push({
        event: 'registrationComplete',
        event_category: 'Registration',
        event_action: 'Complete',
        event_label: 'Registration Form',
        registration_method: 'Standard',
      });
    }
  }, [registrationSuccess]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    onChangeSection(SignUpSectionType.LOGIN);
  };

  useEffect(() => {
    return setShowNotAgreedError(false);
  }, []);

  const INPUTS = useMemo(() => {
    if (code) {
      return [
        {
          name: 'password',
          label: translate(intl, 'REGISTRATION_FORM.LABEL_PASSWORD'),
          placeholder: translate(
            intl,
            'REGISTRATION_FORM.PLACEHOLDER_PASSWORD',
          ),
          type: 'password',
          validation: [
            {
              type: 'required',
            },
            {
              type: 'minLength',
              parameter: 8,
            },
            {
              type: 'maxLength',
              parameter: 30,
            },
          ],
        },
      ];
    }

    return [
      {
        name: 'email',
        label: translate(intl, 'REGISTRATION_FORM.LABEL_EMAIL'),
        placeholder: translate(intl, 'REGISTRATION_FORM.PLACEHOLDER_EMAIL'),
        type: 'text',
        validation: [
          {
            type: 'required',
          },
          {
            type: 'email',
          },
          {
            type: 'minLength',
            parameter: 4,
          },
          {
            type: 'maxLength',
            parameter: 60,
          },
        ],
      },
      {
        name: 'password',
        label: translate(intl, 'REGISTRATION_FORM.LABEL_PASSWORD'),
        placeholder: translate(intl, 'REGISTRATION_FORM.PLACEHOLDER_PASSWORD'),
        type: 'password',
        validation: [
          {
            type: 'required',
          },
          {
            type: 'minLength',
            parameter: 8,
          },
          {
            type: 'maxLength',
            parameter: 30,
          },
        ],
      },
      {
        name: 'passwordRepeat',
        label: translate(intl, 'REGISTRATION_FORM.LABEL_PASSWORD_REPEAT'),
        placeholder: translate(
          intl,
          'REGISTRATION_FORM.PLACEHOLDER_PASSWORD_REPEAT',
        ),
        type: 'password',
        validation: [
          {
            type: 'required',
          },
          {
            type: 'minLength',
            parameter: 8,
          },
          {
            type: 'maxLength',
            parameter: 30,
          },
        ],
      },
      {
        name: 'termsAndConditions',
        label: (
          <p>
            <Link
              to={routes.public.termsOfService}
              target="_blank"
              className={styles.agreeLink}
            >
              {translate(intl, 'REGISTRATION_FORM.AGREE_TERMS_AND_CONDITIONS')}
            </Link>
          </p>
        ),
        type: 'checkbox',
        value: '0',
        validation: [
          {
            type: 'requiredCheckbox',
          },
        ],
      },
      {
        name: 'privacyPolicy',
        label: (
          <p>
            <Link
              to={routes.public.privacyPolicy}
              target="_blank"
              className={styles.agreeLink}
            >
              {translate(intl, 'REGISTRATION_FORM.AGREE_PRIVACY_POLICY')}
            </Link>
          </p>
        ),
        type: 'checkbox',
        value: '0',
        validation: [
          {
            type: 'requiredCheckbox',
          },
        ],
      },
      {
        name: 'marketing',
        label: translate(intl, 'REGISTRATION_FORM.AGREE_ADS'),
        type: 'checkbox',
        value: '0',
        validation: [],
      },
    ];
  }, [code]);

  const handleSubmit = async (submitInputs: FormInputs) => {
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'formSubmission',
      event_category: 'Registration',
      event_action: 'Submit',
      event_label: 'Registration Form',
    });

    onRegister({
      email: submitInputs.email,
      password: submitInputs.password,
      passwordRepeat: submitInputs.passwordRepeat,
      refCode: refCode,
      agreedMarketing: submitInputs.marketing === '1',
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
    onCheckboxChange,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  const checkboxValue = useMemo(() => {
    const termsAndConditions = inputs.find(
      (input) => input.name === 'termsAndConditions',
    )?.value;

    const privacyPolicy = inputs.find(
      (input) => input.name === 'privacyPolicy',
    )?.value;

    return termsAndConditions === '0' || privacyPolicy === '0' ? '0' : '1';
  }, [inputs]);

  const marketingCheckboxValue = useMemo(() => {
    return inputs.find((input) => input.name === 'marketing')?.value;
  }, [inputs]);

  useEffect(() => {
    setIsAgreedCheckbox(checkboxValue === '1');
  }, [checkboxValue]);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  useEffect(() => {
    setIsAgreedMarketing(marketingCheckboxValue === '1');
  }, [marketingCheckboxValue]);

  const globalError = getGlobalError(error, intl);

  if (!isLoaded && (isLoading || !validatedUser || !code) && !error) {
    return <Loader isLoading={isLoading} height="300" />;
  }

  const getAgreeError = (input: FormInput) => {
    if (showNotAgreedError) {
      return [translate(intl, 'REGISTRATION_FORM.REQUIRED')];
    }

    return input.validationErrors ?? [];
  };

  return (
    <div className={styles.registrationForm}>
      <Form
        className={styles.form}
        error={error}
        onSubmit={onSubmit}
        scrollIntoView={false}
      >
        {registrationSuccess && (
          <Alert variant="success" capitalize={false}>
            {translate(intl, 'REGISTRATION_FORM.SUCCESS_MESSAGE')}
          </Alert>
        )}
        {globalError && (
          <Alert variant="danger" capitalize={false}>
            {translate(intl, globalError)}
          </Alert>
        )}
        {inputs
          .filter((input) =>
            ['email', 'password', 'passwordRepeat'].includes(input.name),
          )
          .map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              onInputBlur={onInputBlur}
              placeholder={input.placeholder}
            />
          ))}
        <div className={styles.selectTypeBox}>
          {!code &&
            inputs
              .filter((input) =>
                ['termsAndConditions', 'privacyPolicy'].includes(input.name),
              )
              .map((input) => (
                <TextField
                  key={input.name}
                  onChange={onInputChange}
                  value={input.value?.toString()}
                  label={input.label ?? ''}
                  errors={getAgreeError(input)}
                  onBlur={onLoseInputFocus}
                  name={input.name}
                  type={input.type}
                  onCheckboxChange={onCheckboxChange}
                  checkedIcon={checkedIncon}
                  uncheckedIcon={uncheckedIncon}
                  className={styles.selectTypeBox}
                />
              ))}
          {!code &&
            inputs
              .filter((input) => ['marketing'].includes(input.name))
              .map((input) => (
                <TextField
                  key={input.name}
                  onChange={onInputChange}
                  value={input.value?.toString()}
                  label={input.label ?? ''}
                  onBlur={onLoseInputFocus}
                  name={input.name}
                  type={input.type}
                  onCheckboxChange={onCheckboxChange}
                  checkedIcon={checkedIncon}
                  uncheckedIcon={uncheckedIncon}
                  className={styles.selectTypeBox}
                />
              ))}
        </div>
        <Button
          isLoadingButton
          onClick={onSubmit}
          buttonVariant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {translate(intl, 'REGISTRATION_FORM.SUBMIT')}
        </Button>
      </Form>
      <Modal isLoading={false} isOpen={isModalOpen} onClose={handleModalClose}>
        <RegisterSuccess onClose={handleModalClose} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.registrationError,
  isLoading: state.auth.registrationLoading,
  registrationSuccess: state.auth.registrationSuccess,
  selectedLocale: state.auth.selectedLocale,
  validatedUser: state.auth.validatedUser,
  refCode: state.auth.refCode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onRegister: (inputs: RegistrationRequest) =>
    dispatch(authService.register(inputs)),
  onResetRegistration: () => dispatch(resetRegistration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
