export const FETCH_TOP_BAR_HIGHLIGHTS_LIST_START =
  'FETCH_TOP_BAR_HIGHLIGHTS_LIST_START';
export const FETCH_TOP_BAR_HIGHLIGHTS_LIST_SUCCESS =
  'FETCH_TOP_BAR_HIGHLIGHTS_LIST_SUCCESS';
export const FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL =
  'FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL';

export const FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_START =
  'FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_START';
export const FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_SUCCESS =
  'FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_SUCCESS';
export const FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_FAIL =
  'FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_FAIL';

export const CREATE_TOP_BAR_HIGHLIGHT_START = 'CREATE_TOP_BAR_HIGHLIGHT_START';
export const CREATE_TOP_BAR_HIGHLIGHT_SUCCESS =
  'CREATE_TOP_BAR_HIGHLIGHT_SUCCESS';
export const CREATE_TOP_BAR_HIGHLIGHT_FAIL = 'CREATE_TOP_BAR_HIGHLIGHT_FAIL';

export const VIEW_TOP_BAR_HIGHLIGHT_START = 'VIEW_TOP_BAR_HIGHLIGHT_START';
export const VIEW_TOP_BAR_HIGHLIGHT_SUCCESS = 'VIEW_TOP_BAR_HIGHLIGHT_SUCCESS';
export const VIEW_TOP_BAR_HIGHLIGHT_FAIL = 'VIEW_TOP_BAR_HIGHLIGHT_FAIL';

export const UPDATE_TOP_BAR_HIGHLIGHT_START = 'UPDATE_TOP_BAR_HIGHLIGHT_START';
export const UPDATE_TOP_BAR_HIGHLIGHT_SUCCESS =
  'UPDATE_TOP_BAR_HIGHLIGHT_SUCCESS';
export const UPDATE_TOP_BAR_HIGHLIGHT_FAIL = 'UPDATE_TOP_BAR_HIGHLIGHT_FAIL';

export const DELETE_TOP_BAR_HIGHLIGHT_START = 'DELETE_TOP_BAR_HIGHLIGHT_START';
export const DELETE_TOP_BAR_HIGHLIGHT_SUCCESS =
  'DELETE_TOP_BAR_HIGHLIGHT_SUCCESS';
export const DELETE_TOP_BAR_HIGHLIGHT_FAIL = 'DELETE_TOP_BAR_HIGHLIGHT_FAIL';

export const RESET_CREATED_TOP_BAR_HIGHLIGHT_STORE =
  'RESET_CREATED_TOP_BAR_HIGHLIGHT_STORE';
export const RESET_TOP_BAR_HIGHLIGHT_STORE = 'RESET_TOP_BAR_HIGHLIGHT_STORE';

export const LOGOUT = 'LOGOUT';

export type TopBarHighlightActionTypes =
  | typeof FETCH_TOP_BAR_HIGHLIGHTS_LIST_START
  | typeof FETCH_TOP_BAR_HIGHLIGHTS_LIST_SUCCESS
  | typeof FETCH_TOP_BAR_HIGHLIGHTS_LIST_FAIL
  | typeof FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_START
  | typeof FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_SUCCESS
  | typeof FETCH_ENABLED_TOP_BAR_HIGHLIGHTS_FAIL
  | typeof CREATE_TOP_BAR_HIGHLIGHT_START
  | typeof CREATE_TOP_BAR_HIGHLIGHT_SUCCESS
  | typeof CREATE_TOP_BAR_HIGHLIGHT_FAIL
  | typeof VIEW_TOP_BAR_HIGHLIGHT_START
  | typeof VIEW_TOP_BAR_HIGHLIGHT_SUCCESS
  | typeof VIEW_TOP_BAR_HIGHLIGHT_FAIL
  | typeof UPDATE_TOP_BAR_HIGHLIGHT_START
  | typeof UPDATE_TOP_BAR_HIGHLIGHT_SUCCESS
  | typeof UPDATE_TOP_BAR_HIGHLIGHT_FAIL
  | typeof DELETE_TOP_BAR_HIGHLIGHT_START
  | typeof DELETE_TOP_BAR_HIGHLIGHT_SUCCESS
  | typeof DELETE_TOP_BAR_HIGHLIGHT_FAIL
  | typeof RESET_CREATED_TOP_BAR_HIGHLIGHT_STORE
  | typeof RESET_TOP_BAR_HIGHLIGHT_STORE
  | typeof LOGOUT;
