export const FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_START =
  'FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_START';
export const FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_SUCCESS =
  'FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_SUCCESS';

export const FETCH_STRIPE_SUPPORTED_CURRENCIES_START =
  'FETCH_STRIPE_SUPPORTED_CURRENCIES_START';
export const FETCH_STRIPE_SUPPORTED_CURRENCIES_SUCCESS =
  'FETCH_STRIPE_SUPPORTED_CURRENCIES_SUCCESS';
export const FETCH_STRIPE_SUPPORTED_CURRENCIES_FAIL =
  'FETCH_STRIPE_SUPPORTED_CURRENCIES_FAIL';

export const FETCH_CURRENCY_RATE_START = 'FETCH_CURRENCY_RATE_START';
export const FETCH_CURRENCY_RATE_SUCCESS = 'FETCH_CURRENCY_RATE_SUCCESS';
export const FETCH_CURRENCY_RATE_FAIL = 'FETCH_CURRENCY_RATE_FAIL';

export type CurrencyActionTypes =
  | typeof FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_START
  | typeof FETCH_USER_CURRENCY_ISO_CODE_FROM_LOCATION_SUCCESS
  | typeof FETCH_STRIPE_SUPPORTED_CURRENCIES_START
  | typeof FETCH_STRIPE_SUPPORTED_CURRENCIES_SUCCESS
  | typeof FETCH_STRIPE_SUPPORTED_CURRENCIES_FAIL
  | typeof FETCH_CURRENCY_RATE_START
  | typeof FETCH_CURRENCY_RATE_SUCCESS
  | typeof FETCH_CURRENCY_RATE_FAIL;
