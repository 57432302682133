import React, { ReactNode } from 'react';
import { Button as MuiButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export type Props = {
  children?: ReactNode;
  className?: string;
  onClick?: (event: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'primary' | 'success' | 'warning' | 'danger';
  buttonVariant?: 'contained' | 'text' | 'outlined';
  isLoading?: boolean;
  isLoadingButton?: boolean;
  isSaveButton?: boolean;
  color?: 'primary' | 'secondary';
  startIcon?: string | ReactNode;
  endIcon?: string | ReactNode;
  isDisabled?: boolean;
  isActive?: boolean;
  size?: 'large' | 'medium' | 'small';
  isRippleDisabled?: boolean;
};

const Button = ({
  children,
  className,
  onClick,
  type,
  isLoading,
  isLoadingButton,
  color,
  buttonVariant,
  endIcon,
  startIcon,
  isSaveButton,
  isDisabled,
  isActive,
  size,
  isRippleDisabled,
}: Props) => {
  if (isLoadingButton) {
    return (
      <LoadingButton
        className={className}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        startIcon={startIcon}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
        size={size || 'large'}
        disableRipple={isRippleDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  if (isSaveButton) {
    return (
      <LoadingButton
        className={className}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
        size={size || 'large'}
        disableRipple={isRippleDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  return (
    <MuiButton
      className={className}
      onClick={onClick}
      variant={buttonVariant ?? 'contained'}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size || 'large'}
      disableRipple={isRippleDisabled}
      disabled={isDisabled}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: 'primary',
  onClick: () => {},
  isLoading: false,
};

export default Button;
