import { Radio } from '@mui/material';
import styles from './BalanceTopUpOption.module.scss';
import { PaymentTypes } from '../BalanceTopUp';
import cx from 'classnames';

type Props = {
  label: string;
  icon: string;
  selectedPaymentType: PaymentTypes;
  type: PaymentTypes;
  setSelectedPaymentType: (paymentType: PaymentTypes) => void;
};

const BalanceTopUpOption = ({
  label,
  icon,
  selectedPaymentType,
  type,
  setSelectedPaymentType,
}: Props) => {
  return (
    <div
      className={styles.balanceTopUpOption}
      onClick={() => setSelectedPaymentType(type)}
    >
      <Radio
        checked={type === selectedPaymentType}
        className={styles.radioSelect}
        disableRipple
      />
      <div
        className={cx(styles.balanceTopUpOptionItem, {
          [styles.isSelected]: type === selectedPaymentType,
        })}
      >
        <label className={styles.label}>{label}</label>
        <div className={styles.iconWrapper}>
          <img src={icon} />
        </div>
      </div>
    </div>
  );
};

export default BalanceTopUpOption;
