import React, { useMemo, useState } from 'react';
import {
  TopBarHighlight,
  TopBarHighlightCustomType,
  TopBarHighlightType,
} from '../../../domain/TopBarHighlight';
import styles from './TopBarHighlightView.module.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import ReactPlayer from 'react-player';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as topBarHighlightService from '../../../store/top-bar-highlight/service';
import { TopBarHighlightViewRequest } from '../../../store/top-bar-highlight/service';
import { connect } from 'react-redux';

type Props = {
  topBarHighlight: TopBarHighlight;
  onView: (inputs: TopBarHighlightViewRequest) => void;
};

const MOBILE_BREAK_POINT = 900;

export const TopBarHighlightView = ({ topBarHighlight, onView }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    if (topBarHighlight.isNewTab) {
      window.open(topBarHighlight.ctaLink);
    } else {
      window.location.href = topBarHighlight.ctaLink;
    }
  };

  const handleEndedVideo = () => {
    if (topBarHighlight.customType === TopBarHighlightCustomType.INTRO_VIDEO) {
      onView({ id: topBarHighlight.id });
    }
  };

  const { width } = useWindowSize();

  const content = useMemo(() => {
    if (topBarHighlight.type === TopBarHighlightType.TEXT) {
      return (
        <div className={styles.textTopBar}>
          <div className={styles.title}>{topBarHighlight.promotionName}</div>
          <div dangerouslySetInnerHTML={{ __html: topBarHighlight.content }} />
          <Button
            className={styles.actionButton}
            onClick={() => handleButtonClick()}
          >
            {topBarHighlight.ctaText}
          </Button>
        </div>
      );
    }

    if (topBarHighlight.type === TopBarHighlightType.IMAGE) {
      return (
        <div className={styles.imageTopBar}>
          <div className={styles.image}>
            <img src={topBarHighlight.image?.location} />
          </div>
          <div className={styles.textPart}>
            <div className={styles.title}>{topBarHighlight.promotionName}</div>
            <div
              dangerouslySetInnerHTML={{ __html: topBarHighlight.content }}
            />
            <Button
              className={styles.actionButton}
              onClick={() => handleButtonClick()}
            >
              {topBarHighlight.ctaText}
            </Button>
          </div>
        </div>
      );
    }

    if (topBarHighlight.type === TopBarHighlightType.VIDEO) {
      return (
        <div className={styles.videoTopBar}>
          <ReactPlayer
            width={'100%'}
            url={topBarHighlight.videoUrl}
            style={{
              borderRadius: '1.875rem',
              overflow: 'hidden',
            }}
            height={'30rem'}
            onEnded={() => handleEndedVideo()}
          />
          <div className={styles.actionContainer}>
            <div
              dangerouslySetInnerHTML={{ __html: topBarHighlight.content }}
            ></div>
            <Button
              className={styles.actionButton}
              onClick={() => handleButtonClick()}
            >
              {topBarHighlight.ctaText}
            </Button>
          </div>
        </div>
      );
    }
  }, [topBarHighlight]);

  return (
    <>
      <div
        className={styles.topBarHighlightContainer}
        dangerouslySetInnerHTML={{
          __html:
            width && width < MOBILE_BREAK_POINT
              ? topBarHighlight.shortTitle
              : topBarHighlight.title,
        }}
        onClick={() => setIsModalOpen(true)}
      ></div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        withClose
        closeClassName={styles.closeModal}
        isMedium
        className={styles.modal}
      >
        {content}
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onView: (inputs: TopBarHighlightViewRequest) =>
    dispatch(topBarHighlightService.viewTopBarHighlight(inputs)),
});

export default connect(null, mapDispatchToProps)(TopBarHighlightView);
