import React from 'react';
import styles from './Footer.module.scss';
import { ReactSVG } from 'react-svg';
import brickWhizlogo from '../../../assets/brickwhiz-logo.svg';
import facebookIcon from '../../../assets/icons/facebook-icon.svg';
import youtubeIcon from '../../../assets/icons/youtube-icon.svg';
import tiktokIcon from '../../../assets/icons/tiktok-icon.svg';
import instagramIcon from '../../../assets/icons/instagram-icon.svg';
import ababaLogo from '../../../assets/ababa-tech-logo.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';

type SectionItem = {
  title: string;
  link: string;
};

export const Footer = () => {
  const intl = useIntl();

  const sectionAboutItems: SectionItem[] = [
    {
      title: translate(intl, 'FOOTER.AFFILIATE'),
      link: routes.public.affiliate,
    },
    {
      title: translate(intl, 'FOOTER.EARN_MONEY'),
      link: routes.public.earnMoney,
    },
    {
      title: translate(intl, 'FOOTER.ABOUT_US'),
      link: routes.public.aboutUs,
    },
  ];

  const sectionHelpItems: SectionItem[] = [
    {
      title: translate(intl, 'FOOTER.GENERAL_INFO'),
      link: routes.public.generalInfo,
    },
    {
      title: translate(intl, 'FOOTER.PRIVACY_POLICY'),
      link: routes.public.privacyPolicy,
    },
    {
      title: translate(intl, 'FOOTER.TERMS_OF_SERVICE'),
      link: routes.public.termsOfService,
    },
    {
      title: translate(intl, 'FOOTER.REFUND_POLICY'),
      link: routes.public.refundPolicy,
    },
    { title: translate(intl, 'FOOTER.FAQ'), link: routes.publicFaq },
    {
      title: translate(intl, 'FOOTER.SHIPPING_POLICY'),
      link: routes.public.shippingPolicy,
    },
  ];

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.logoSectionContainer}>
            <div className={styles.logoSection}>
              <Link to={routes.homepage}>
                <ReactSVG src={brickWhizlogo} />
              </Link>
              <div className={styles.logoTitle}>
                {translate(intl, 'FOOTER.LOGO_TITLE')}
              </div>
            </div>
          </div>
          <div className={styles.footerItemContainer}>
            <div className={styles.footerItemTitle}>
              {translate(intl, 'FOOTER.ABOUT')}
            </div>
            {sectionAboutItems.map((item, index) => (
              <Link key={index} to={item.link} className={styles.footerItem}>
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles.footerItemContainer}>
            <div className={styles.footerItemTitle}>
              {translate(intl, 'FOOTER.HELP')}
            </div>
            {sectionHelpItems.map((item, index) => (
              <Link key={index} to={item.link} className={styles.footerItem}>
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles.footerItemContainer}>
            <div className={styles.footerItemTitle}>
              {translate(intl, 'FOOTER.TALK_TO_US')}
            </div>
            <div className={styles.footerItem}>
              <a href={`mailto:${translate(intl, 'FOOTER.EMAIL')}`}>
                {translate(intl, 'FOOTER.EMAIL')}
              </a>
            </div>
            <div className={styles.footerItem}>
              <a href={`tel:${translate(intl, 'FOOTER.PHONE_NUMBER')}`}>
                {translate(intl, 'FOOTER.PHONE_NUMBER')}
              </a>
            </div>
            <Link to={routes.public.contactUs} className={styles.footerItem}>
              {translate(intl, 'FOOTER.CONTACT_US')}
            </Link>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.containerCopyright}>
          <div className={styles.copyright}>
            {translate(intl, 'FOOTER.COPYRIGHT')}
          </div>
          <div className={styles.trademarkInfo}>
            {translate(intl, 'FOOTER.LEGO_TRADEMARK_INFO')}
          </div>
          <div className={styles.iconContainer}>
            <Link
              to={'https://www.tiktok.com/@brickwhiz3'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={tiktokIcon} className={styles.socialIcon} />
            </Link>
            <Link
              to={'https://www.instagram.com/brickwhiz/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG
                src={instagramIcon}
                className={styles.socialIcon}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Link>
            <Link
              to={'https://www.youtube.com/@BrickWhizWorldWide/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={youtubeIcon} className={styles.socialIcon} />
            </Link>
            <Link
              to={'https://www.facebook.com/people/BrickWhiz/61556389574388/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ReactSVG src={facebookIcon} className={styles.socialIcon} />
            </Link>
          </div>
        </div>
        <div className={styles.ababa}>
          <Link
            to="https://ababa.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ReactSVG src={ababaLogo} />
          </Link>
        </div>
      </div>
    </div>
  );
};
