import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  resetBricksStatusFail,
  resetBricksStatusStart,
  resetBricksStatusSuccess,
} from './actions';

const API_URL = '/profile';

export const resetBricksStatus = () => (dispatch: Dispatch) => {
  dispatch(resetBricksStatusStart());
  return axios
    .patch(API_URL)
    .then(() => {
      dispatch(resetBricksStatusSuccess());
    })
    .catch((err) => {
      dispatch(resetBricksStatusFail(err?.response?.data?.message));
    });
};
